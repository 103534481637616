import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/message';

export default {
  getUnSeenCount() {
    return axios.get(`${RESOURCE_NAME}/getUnSeenCount`);
  },
  getMessages(chatid, id, type) {
    return axios.get(`${RESOURCE_NAME}/getList`, {
      params: { Id: id, ChatId: chatid, Type: type },
    });
  },
  getChats(type, status, Page, PageSize, id) {
    return axios.get(`chat/listChats`, {
      params: {
        Id: id,
        Type: type,
        Status: status,
        page: Page,
        pageSize: PageSize,
      },
    });
  },
  sendMessage(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },
  setArchive(body) {
    return axios.put(`chat/updateChat`, body);
  },

  /**
   * Get chat object by id.
   * @param {string} id
   * @returns
   */
  getById(id) {
    return axios.get(`chat/getByOrderId`, { params: { OrderId: id } });
  },

  /**
   * Creates a chat with OfferId.
   * @param {*} body
   * @returns {Promise}
   */
  createChat(body) {
    return axios.post(`chat/createChat`, body);
  },
};
