<template>
  <v-card elevation="1" class="mt-0" style="margin: 0 !important">
    <v-card-title style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="3" cols="12">
          <h5>{{ $t('navigationDrawerMenu.timeOut') }}</h5>
        </v-col>
      </v-row>
      <RefreshButton :refreshFunction="getWarnings"></RefreshButton>
    </v-card-title>
    <v-divider />
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(updateCompany)"
        style="padding: 25px; padding-top: 30px"
      >
        <v-row v-for="(warning, index) in warnings" :key="warning.Id">
          <FormElement
            v-model="warnings[index]['Hours']"
            :vertical="true"
            :validationLabel="$t(`labels.timeOut${index}`)"
            :topLabel="true"
            :labelShown="false"
            :lg="12"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t(`labels.timeOut${index}`)"
          />
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn
              color="primary"
              variant="tonal"
              class="rounded-xl ml-2 mb-2"
              style="
                color: white;
                text-transform: none;
                padding: 30px;
                font-size: large;
                font-weight: bold;
              "
              @click="handleSubmit(updateCompany)"
            >
              {{ $t('buttons.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </v-card>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import AdminService from '../services/AdminService';
export default {
  data() {
    return {
      formItems: [],
      warnings: [],
    };
  },
  components: {
    FormElement,
    RefreshButton,
  },
  mounted() {
    this.getWarnings();
  },

  methods: {
    getWarnings() {
      AdminService.getWarnings().then((response) => {
        this.warnings = null;
        this.warnings = response.data.Result;
      });
    },

    updateCompany() {
      let body = [];

      this.warnings.map((warning) => {
        let obj = {
          Id: warning.Id,
          Hours: warning.Hours,
        };

        body.push(obj);
      });

      console.log(body);
      let data = {
        WarningSettings: body,
      };

      AdminService.updateWarnings(data).then((response) => {
        this.getWarnings();
      });
    },
  },
};
</script>
