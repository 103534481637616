<template>
  <v-card elevation="1" class="mt-0">
    <v-dialog v-model="addModal" max-width="800">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ editingItemId ? $t('labels.editBox') : $t('labels.addBox') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver ref="addPackageObserver" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitForm)">
            <v-card-text>
              <v-row>
                <FormElement
                  v-for="(element, index) in formItems"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :cols="element.cols"
                  :disabled="element.disabled"
                  :hidden="element.hidden"
                  :willBeEliminated="false"
                  :labelShown="element.labelShown"
                  :active="element.active"
                  :topLabel="element.topLabel"
                  :placeholder="$t(element.placeholder)"
                />
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;

                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                >{{ $t('buttons.save') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="resetModal"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card-title style="color: var(--darkBlue)">
      <v-row align="center">
        <v-col class="text-lg-left text-center" lg="3" cols="12">
          <h5>{{ $t('navigationDrawerMenu.adminPackageManagment') }}</h5>
        </v-col>
        <v-spacer />
      </v-row>
      <div class="d-flex ">
          <v-autocomplete
            class="custom-border mr-2"
            v-model="fiterCompanyCategory"
            :value="fiterCompanyCategory"
            :items="companyCategories"
            :label="$t('labels.companyCategory')"
            item-text="Name"
            item-value="Id"
            @change="getPackages"
            clearable
            dense
            hide-details
            variant="solo"
            outlined
          >
          </v-autocomplete>
        </v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-3"
              style="font-size: 32px"
              v-bind="attrs"
              v-on="on"
              @click="addModal = true"
            >
              mdi-plus-box-outline
            </v-icon>
          </template>
          <span>{{ $t('buttons.add') }}</span>
        </v-tooltip>
        <RefreshButton :refreshFunction="getPackages"></RefreshButton>
      </div>
    </v-card-title>
    <v-divider />
    <v-data-table
      :headers="tableHeaders"
      :items="tableData"
      hide-default-footer
      style="overflow-x: hidden !important"
      :options="{ itemsPerPage: itemsPerPage }"
      :loading="$store.state.loadingStatus"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:item.CompanyCategory="{ item }">
        <v-chip
          v-if="item.CompanyCategory == 1"
          style="font-weight: 600"
          class="ma-2"
          small
          color="#c5f4db"
          text-color="#2b3838"
        >
          {{ $t('labels.customer') }}
        </v-chip>
        <v-chip
          v-else
          style="font-weight: 600"
          class="ma-2"
          small
          color="#f4c5c5"
          text-color="#2b3838"
        >
          {{ $t('labels.supplier') }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex text-start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="#29B7B7"
                class="mr-1"
                style="font-size: 20px"
                v-bind="attrs"
                v-on="on"
                @click="editPackage(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('labels.tooltipEdit') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="red"
                style="font-size: 20px"
                v-bind="attrs"
                v-on="on"
                @click="deletePackage(item)"
              >
                mdi-trash-can
              </v-icon>
            </template>
            <span>{{ $t('labels.deleteKey') }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-center mt-8 pb-lg-0 pb-8">
          <v-row justify="space-between">
            <v-col md="2"></v-col>
            <v-col md="8" class="ml-md-auto">
              <v-pagination
                v-if="pageLength > 0"
                class="my-4"
                @input="onPageChange"
                :value="page"
                :length="pageLength"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import { getCurrencyTypes } from '../utils/constants';
import CompanyPackageService from '../services/CompanyPackageService';

export default {
  data: () => ({
    addModal: false,
    tableHeaders: [],
    tableData: [],
    form: {
      Name: '',
      CompanyCategory: '',
      Description: '',
      Price: '',
      CurrencyId: '',
      selectedModules: [],
    },
    companyCategories: [],
    currencies: [],
    priceTypes: [],
    modules: [],

    editingItemId: null,

    fiterCompanyCategory: 1,
    page: 1,
    totalItems: 0,
    itemsPerPage: 15,
    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
  }),
  mounted() {
    this.tableHeaders = [
      {
        text: this.$t('labels.name'),
        value: 'Name',
        align: 'center',
      },
      {
        text: this.$t('labels.description'),
        value: 'Description',
        align: 'center',
      },
      {
        text: this.$t('labels.companyCategory'),
        value: 'CompanyCategory',
        align: 'center',
      },
      {
        text: this.$t('labels.monthlyPrice'),
        value: 'MonthlyPriceWithCurrency',
        align: 'center',
      },
      {
        text: this.$t('labels.yearlyPrice'),
        value: 'AnnualPriceWithCurrency',
        align: 'center',
      },
      {
        text: this.$t('labels.actions'),
        value: 'actions',
        sort: false,
      },
    ];
    this.companyCategories = [
      {
        Id: 1,
        Name: this.$t('labels.customer'),
      },
      {
        Id: 3,
        Name: this.$t('labels.supplier'),
      },
    ];
    this.priceTypes = [
      {
        Id: 1,
        Name: this.$t('labels.fixedPrice'),
      },
      {
        Id: 2,
        Name: this.$t('labels.percentage'),
      },
    ];

    this.setup();
  },
  components: {
    RefreshButton,
    FormElement,
  },
  computed: {
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    formItems() {
      return [
        {
          key: {
            parent: 'form',
            child: 'Name',
          },
          rules: 'required',

          validationLabel: 'labels.name',
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.name',
        },
        {
          key: {
            parent: 'form',
            child: 'CompanyCategory',
          },
          validationLabel: 'labels.companyCategory',
          rules: 'required',
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.companyCategories,
              text: 'Name',
              value: 'Id',
            },
          },
          onChange: this.getModules,
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.companyCategory',
        },
        {
          key: {
            parent: 'form',
            child: 'Description',
          },
          rules: 'required',
          validationLabel: 'labels.description',
          formType: {
            name: 'textarea',
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.description',
        },
        {
          key: {
            parent: 'form',
            child: 'MonthlyPrice',
          },
          rules: 'required|double',
          validationLabel: 'labels.monthlyPrice',
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 5,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.monthlyPrice',
        },
        {
          key: {
            parent: 'form',
            child: 'AnnualPrice',
          },
          rules: 'required|double',
          validationLabel: 'labels.yearlyPrice',
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 5,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.yearlyPrice',
        },
        {
          key: {
            parent: 'form',
            child: 'CurrencyId',
          },
          validationLabel: 'labels.currency',
          rules: 'required',
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.currencies,
              text: 'Code',
              value: 'Id',
            },
          },
          cols: 12,
          lg: 2,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.currency',
        },
        {
          key: {
            parent: 'form',
            child: 'MonthlyQueryCount',
          },
          rules: 'required|double',
          active: this.form.CompanyCategory === 1,
          validationLabel: 'labels.monthlyQueryCount',
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.monthlyQueryCount',
        },
        {
          key: {
            parent: 'form',
            child: 'OrderCount',
          },
          rules: 'required|double',
          validationLabel: 'labels.orderCount',
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.orderCount',
        },
        {
          key: {
            parent: 'form',
            child: 'IntegrationOrderUnitCost',
          },
          rules: 'required|double',
          validationLabel: 'labels.integrationUnitPrice',
          active: this.form.CompanyCategory === 3,
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.integrationUnitPrice',
        },
        {
          key: {
            parent: 'form',
            child: 'AirlineCommissionType',
          },
          rules: 'required',
          validationLabel: 'labels.airlineCommissionType',
          active: this.form.CompanyCategory === 3,
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.priceTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          cols: 12,
          lg: 6,
          placeholder: 'labels.airlineCommissionType',
        },
        {
          key: {
            parent: 'form',
            child: 'AirlineCommission',
          },
          rules: 'required|double',
          validationLabel: 'labels.airlineCommission',
          active: this.form.CompanyCategory === 3,
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.airlineCommission',
        },
        {
          key: {
            parent: 'form',
            child: 'SeawayCommissionType',
          },
          rules: 'required',
          validationLabel: 'labels.seawayCommissionType',
          active: this.form.CompanyCategory === 3,
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.priceTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          cols: 12,
          lg: 6,
          placeholder: 'labels.seawayCommissionType',
        },
        {
          key: {
            parent: 'form',
            child: 'SeawayCommission',
          },
          rules: 'required|double',
          validationLabel: 'labels.seawayCommission',
          active: this.form.CompanyCategory === 3,
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.seawayCommission',
        },
        {
          key: {
            parent: 'form',
            child: 'ExpressCommissionType',
          },
          rules: 'required',
          validationLabel: 'labels.expressCommissionType',
          active: this.form.CompanyCategory === 3,
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.priceTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          cols: 12,
          lg: 6,
          placeholder: 'labels.expressCommissionType',
        },
        {
          key: {
            parent: 'form',
            child: 'ExpressCommission',
          },
          rules: 'required|double',
          validationLabel: 'labels.expressCommission',
          active: this.form.CompanyCategory === 3,
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.expressCommission',
        },
        {
          key: {
            parent: 'form',
            child: 'RoadwayCommissionType',
          },
          rules: 'required',
          validationLabel: 'labels.roadwayCommissionType',
          active: this.form.CompanyCategory === 3,
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.priceTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          cols: 12,
          lg: 6,
          placeholder: 'labels.roadwayCommissionType',
        },
        {
          key: {
            parent: 'form',
            child: 'RoadwayCommission',
          },
          rules: 'required|double',
          validationLabel: 'labels.roadwayCommission',
          active: this.form.CompanyCategory === 3,
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.roadwayCommission',
        },
        {
          key: {
            parent: 'form',
            child: 'RailwayCommissionType',
          },
          rules: 'required',
          validationLabel: 'labels.railwayCommissionType',
          active: this.form.CompanyCategory === 3,
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.priceTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          cols: 12,
          lg: 6,
          placeholder: 'labels.railwayCommissionType',
        },
        {
          key: {
            parent: 'form',
            child: 'RailwayCommission',
          },
          rules: 'required|double',
          validationLabel: 'labels.railwayCommission',
          active: this.form.CompanyCategory === 3,
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.railwayCommission',
        },
        {
          key: {
            parent: 'form',
            child: 'MaxPortalCount',
          },
          rules: 'required|double',
          validationLabel: 'labels.maxPortalCount',
          active: this.form.CompanyCategory === 3,
          formType: {
            name: 'text',
          },
          cols: 12,
          lg: 6,
          labelShown: false,
          topLabel: true,
          placeholder: 'labels.maxPortalCount',
        },
        {
          key: {
            parent: 'form',
            child: 'selectedModules',
          },
          rules: 'required',
          validationLabel: 'labels.moduleList',
          topLabel: true,
          labelShown: false,
          disabled: this.modules.length === 0,
          formType: {
            name: 'multipleSelect',
            multiple: true,
            items: {
              data: this.modules,
              value: 'Value',
              text: 'Name',
            },
          },
          cols: 12,
          lg: this.form.CompanyCategory === 1 ? 12 : 6,
          placeholder: 'labels.moduleList',
        },
      ];
    },
  },
  methods: {
    async setup() {
      this.currencies = await getCurrencyTypes();
      this.getPackages();
    },
    getPackages() {
      this.$store.state.loadingStatus = true;
      CompanyPackageService.getPackages(
        this.page,
        this.itemsPerPage,
        null,
        this.fiterCompanyCategory
      )
        .then((response) => {
          this.tableData = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    async getModules(CompanyCategory) {
      this.$store.state.loadingStatus = true;
      try {
        const response = await CompanyPackageService.getModules(
          CompanyCategory
        );
        this.modules = response.data.map((module) => ({
          Value: module,
          Name: this.$t(`navigationDrawerMenu.${module}`),
        }));
      } catch (error) {
        window.showError(error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },
    async submitForm() {
      const isValid = await this.$refs.addPackageObserver.validate();
      if (isValid) {
        const body = {
          Name: this.form.Name,
          Description: this.form.Description,
          PriceCurrencyId: this.form.CurrencyId,
          ModuleList: this.form.selectedModules,
          MonthlyPrice: parseInt(this.form.MonthlyPrice),
          AnnualPrice: parseInt(this.form.AnnualPrice),
          CompanyCategory: parseInt(this.form.CompanyCategory),
          OrderCount: parseInt(this.form.OrderCount),
        };

        if (this.form.CompanyCategory === 1) {
          body.SearchCount = parseInt(this.form.MonthlyQueryCount);
        } else if (this.form.CompanyCategory === 3) {
          body.IntegrationOrderUnitCost = parseInt(
            this.form.IntegrationOrderUnitCost
          );
          body.AirlineCommissionType = parseInt(
            this.form.AirlineCommissionType
          );
          body.AirlineCommission = parseInt(this.form.AirlineCommission);
          body.SeawayCommissionType = parseInt(this.form.SeawayCommissionType);
          body.SeawayCommission = parseInt(this.form.SeawayCommission);
          body.ExpressCommissionType = parseInt(
            this.form.ExpressCommissionType
          );
          body.ExpressCommission = parseInt(this.form.ExpressCommission);
          body.RoadwayCommissionType = parseInt(
            this.form.RoadwayCommissionType
          );
          body.RoadwayCommission = parseInt(this.form.RoadwayCommission);
          body.RailwayCommissionType = parseInt(
            this.form.RailwayCommissionType
          );
          body.RailwayCommission = parseInt(this.form.RailwayCommission);
          body.MaxPortalCount = parseInt(this.form.MaxPortalCount);
        }

        this.$store.state.loadingStatus = true;

        if (this.editingItemId) {
          body.Id = this.editingItemId;
          CompanyPackageService.updatePackage(body)
            .then(() => {
              this.$store.state.loadingStatus = false;
              this.getPackages();
            })
            .catch((error) => {
              window.showError(error);
            })
            .finally(() => {
              this.resetModal();
              this.$store.state.loadingStatus = false;
            });
        } else {
          CompanyPackageService.addPackage(body)
            .then(() => {
              this.$store.state.loadingStatus = false;
              this.getPackages();
            })
            .catch((error) => {
              window.showError(error);
            })
            .finally(() => {
              this.resetModal();
              this.$store.state.loadingStatus = false;
            });
        }
      }
    },
    editPackage(item) {
      this.editingItemId = item.Id;
      this.form = {
        Name: item.Name,
        CompanyCategory: item.CompanyCategory,
        Description: item.Description,
        Price: item.Price,
        CurrencyId: item.PriceCurrencyId,
        selectedModules: item.ModuleList || [],
        OrderCount: item.OrderCount,
        MonthlyPrice: item.MonthlyPrice,
        AnnualPrice: item.AnnualPrice,
      };
      if (item.CompanyCategory === 1) {
        this.form.MonthlyQueryCount = item.SearchCount;
      } else if (item.CompanyCategory === 3) {
        this.form.IntegrationOrderUnitCost = item.IntegrationOrderUnitCost;
        this.form.AirlineCommissionType = item.AirlineCommissionType;
        this.form.AirlineCommission = item.AirlineCommission;
        this.form.SeawayCommissionType = item.SeawayCommissionType;
        this.form.SeawayCommission = item.SeawayCommission;
        this.form.ExpressCommissionType = item.ExpressCommissionType;
        this.form.ExpressCommission = item.ExpressCommission;
        this.form.RoadwayCommissionType = item.RoadwayCommissionType;
        this.form.RoadwayCommission = item.RoadwayCommission;
        this.form.RailwayCommissionType = item.RailwayCommissionType;
        this.form.RailwayCommission = item.RailwayCommission;
        this.form.MaxPortalCount = item.MaxPortalCount;

      }

      this.getModules(item.CompanyCategory);

      this.addModal = true;
    },
    deletePackage(item) {
      this.$store.state.loadingStatus = true;
      CompanyPackageService.deletePackage(item.Id)
        .then(() => {
          this.$store.state.loadingStatus = false;
          this.getPackages();
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    resetModal() {
      this.$refs.addPackageObserver.reset();
      this.addModal = false;
      this.form = {
        Name: '',
        CompanyCategory: '',
        Description: '',
        Price: '',
        CurrencyId: '',
        selectedModules: [],
        MonthlyQueryCount: null,
        OrderCount: null,
        IntegrationOrderUnitCost: null,
        AirlineCommissionType: null,
        AirlineCommission: null,
        SeawayCommissionType: null,
        SeawayCommission: null,
        ExpressCommissionType: null,
        ExpressCommission: null,
        RoadwayCommissionType: null,
        RoadwayCommission: null,
        RailwayCommissionType: null,
        RailwayCommission: null,
      };
      this.editingItemId = null;
    },
    onPageChange(newPage) {
      this.page = newPage;
      this.getPackages();
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getPackages();
    },
  },
};
</script>
