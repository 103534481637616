<template>
  <v-card elevation="1">
    <v-card-title class="d-flex flex-row" style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="12" cols="12">
         <div class="d-flex">
          <v-icon
            @click.stop="$router.push({ name: 'dynamicReports' })"
            style="color: #0177d4; font-size: 36px"
            class="mr-2"
          >
            mdi-arrow-left
          </v-icon>
          <h3>{{ $t(`title.${isEditMode ? 'update' : 'add'}`, { value: $t('navigationDrawerMenu.dynamicReports') }) }}</h3>
        
         </div>
          <h5 v-if="isEditMode" style="color: #CCC;">{{ dynamicReportsForm.ReportName ? dynamicReportsForm.ReportName : '' }}</h5>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-card-text v-if="!$store.state.loadingStatus">
      <ValidationObserver ref="actionsOnExhangeRateObserver" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(submitForm)"
        >
          <v-card-text>
            <v-row>
              <FormElement
                  v-for="(element, index) in formItems"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :cols="element.cols"
                  :disabled="element.disabled"
                  :hidden="element.hidden"
                  :labelShown="element.labelShown"
                  :active="element.active"
                  :topLabel="element.topLabel"
                  :placeholder="$t(element.placeholder)"
                  :classes="element.classes"
                />
                <FormElement
                  v-for="(element, index) in formItemsForHeaders"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="element.validationLabel"
                  :rules="element.rules"
                  :formLabel="element.formLabel"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :cols="element.cols"
                  :disabled="element.disabled"
                  :hidden="element.hidden"
                  :labelShown="element.labelShown"
                  :active="element.active"
                  :topLabel="element.topLabel"
                  :placeholder="element.placeholder"
                />
                <v-col cols="12">
                  <h2 style="color: var(--pink)" class="mb-4">{{ $t('titles.preview') }}</h2>
                  <v-data-table
                    v-if="sampleData.length > 0"
                    :headers="sampleDataHeaders"
                    :items="sampleData"
                    hide-default-footer
                    :loading="$store.state.loadingStatus"
                    :item-key="'Id'"
                    :no-data-text="$t('messages.noData')"
                  >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td v-for="element in sampleDataHeaders">
                        {{ getValidValue(item, element) }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
            :disabled="sampleData.length === 0"
              style="
                color: #fff;
                text-transform: unset !important;
                background-color: #0077d5;

                padding: 1vh 1vh;
                border-radius: 30px !important;
                font-weight: bolder;
                font-size: medium;
              "
              elevation="0"
              class="darken-1"
              type="submit"
              :loading="$store.state.loadingStatus"
              >{{ $t('buttons.save') }}</v-btn
            >
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  ITEMS_PER_PAGE_OPTIONS,
  getCountries,
  removeNullValues
} from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import RefreshButton from '../components/RefreshButton.vue';
import TabScreen from '../components/TabScreen.vue';
import ReportService from '../services/ReportService';
import PortalService from '../services/PortalService';

export default {
  data() {
    return {
      countries: [],

      // States
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      isEditMode: false,

      // Dynamic Route
      sampleData: [],
      filterHeaders: [],
      dynamicReportsForm: {
        Columns: [],
        SendMail: false
      },
      isSupplier: false,
      reportId: "",
      services: [],
      frequencies: []
    };
  },
  components: { FormElement, RefreshButton, TabScreen },
  mounted() {
    // Checking Params
    const reportId = this.$route.params.id;
    if(reportId){
      this.reportId = reportId;
      this.getDataById();
      this.isEditMode = true;
    }


    this.frequencies = [ { text: this.$t('labels.daily'), value: 0 }, { text: this.$t('labels.weekly'), value: 1 },{ text: this.$t('labels.monthly'), value: 2 } ];

    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();

    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';
  },
  computed: {
    reportHeadersData(){
      return [{
          name: "ulasim_tipi",
          data: [
            "Karayolu",
            "Denizyolu",
            "Havayolu",
            "Demiryolu"
          ],
          multiple: true
        },
        {
          name: "faturasi_kesildi",
          data: [
            "EVET",
            "HAYIR"
          ],
          multiple: false,
          active: this.isSupplier
        },
        {
          name: "planlandi",
          data: [
            "EVET",
            "HAYIR"
          ],
          multiple: false,
          active: this.isSupplier
        },
        {
          name: "dosya_durumu",
          data: [
            "Planlanmadı",
            "Planlandı",
            "Depoda",
            "Yükleniyor",
            "Faturası Kesildi",
            "Varış Yerinde",
            "Teslim Edildi"
          ],
          multiple: true,
          active: this.isSupplier
        },
        {
          name: "transfer_tipi",
          data: [
            "Kargo",
            "Express"
          ],
          multiple: true
        },
        {
          name: "odeme_durumu",
          data: [
            "Ödendi",
            "Ödenmedi",
            "Ödeme Onayı Bekleniyor",
            "Ek Ödeme Mevcut"
          ],
          multiple: true
        },
        {
          name: "yukleme_durumu",
          data: [
            "Taslak Halinde",
            "Yükleme Bekleniyor",
            "Yükleme Yapıldı",
            "Yolda",
            "Varış Yerinde",
            "Teslim Edildi",
            "İptal Edildi"
          ],
          multiple: true
        },
        {
          name: "siparis_tipi",
          data: [
            "Manuel",
            "Etsy",
            "Amazon"
          ],
          multiple: true
        },
        {
          name: "portal_musteri_tipi",
          data: [
            "Kurumsal",
            "Entegrasyon"
          ],
          multiple: false,
          isSupplier: this.isSupplier
        },
        {
          name: "tehlikeli_madde_iceriyor",
          data: [
            "EVET",
            "HAYIR"
          ],
          multiple: false
        },
        {
          name: "gonderici_ulke",
          data: this.countries,
          multiple: false,
          itemData: {
            value: item => item.ISOCode2,
            text: item => item.Name
          }
        },
        {
          name: "alici_ulke",
          data: this.countries,
          multiple: false,
          itemData: {
            value: item => item.ISOCode2,
            text: item => item.Name
          }
        },
        {
          name: "eve_teslimat",
          data: [
            "EVET",
            "HAYIR"
          ],
          multiple: false
        },
        {
          name: "vergi_odeme",
          data: [
            "DAP",
            "DDP"
          ],
          multiple: false
        },
        {
          name: "odeme_tipi",
          data: [
            "Peşin",
            "Vadeli"
          ],
          multiple: true
        },
        {
          name: "sigorta",
          data: [
            "Müşteri Sigortası",
            "Tedarikçi Sigortası",
            "Servis Sağlayıcı Sigortası"
          ],
          multiple: true
        },
        {
          name: "alt_tedarikci",
          data: [
            "Fedex",
            "Ups"
          ],
          multiple: false,
          onChange: () => {
            if(this.dynamicReportsForm.alt_tedarikci){
              const subSupplier = this.dynamicReportsForm.alt_tedarikci === 'Fedex' ? 1 : 2;
              this.getServices(subSupplier);
            }
            else{
              this.services = []
            }
          }
        },
        {
          name: "servis_adi",
          data: this.services,
          multiple: false,
          itemData: {
            value: item => item.key,
            text: item => item.value
          },
          active: this.services.length > 0
        },
        {
          name: "imzali_teslimat",
          data: [
            "EVET",
            "HAYIR"
          ],
          multiple: false
        }]
    },
    sampleDataHeaders(){
      if(this.dynamicReportsForm.Columns.length === 0)
        return this.dynamicReportsForm.Columns
      return this.dynamicReportsForm.Columns.map(item => {
        return {
          text: item,
          value: item,
          sortable: false,
        };
      })
    },
    formItems(){
      return [
        {
          key: {
            parent: 'dynamicReportsForm',
            child: 'ReportName',
          },
          validationLabel: 'labels.name',
          rules: 'required',
          formType: {
            name: 'text',
          },
          lg: 6,
          labelShown: false,
          topLabel: true,
          cols: 6,
          placeholder: 'labels.name',
        },
        {
          key: {
            parent: 'dynamicReportsForm',
            child: 'SendMail',
          },
          validationLabel: 'labels.isMailSent',
          formType: {
            name: 'checkbox',
          },
          lg: 6,
          labelShown: false,
          topLabel: true,
          cols: 6,
          placeholder: 'labels.isMailSent',
          classes: 'mt-1'
        },
        {
          key: {
            parent: 'dynamicReportsForm',
            child: 'MailAddress',
          },
          validationLabel: 'labels.email',
          rules: 'required|email',
          formType: {
            name: 'text',
          },
          lg: 6,
          labelShown: false,
          topLabel: true,
          cols: 6,
          placeholder: 'labels.email',
          active: !!this.dynamicReportsForm.SendMail
        },
        {
          key: {
            parent: 'dynamicReportsForm',
            child: 'MailFrequency',
          },
          validationLabel: 'labels.mailFrequency',
          rules: 'required',
          formType: {
            name: 'select',
            items: {
              data: this.frequencies,
              text: 'text',
              value: 'value'
            }
          },
          lg: 6,
          labelShown: false,
          topLabel: true,
          cols: 6,
          placeholder: 'labels.mailFrequency',
          active: !!this.dynamicReportsForm.SendMail
        },
        {
          key: {
            parent: 'dynamicReportsForm',
            child: 'Columns',
          },
          validationLabel: 'labels.showColumns',
          rules: 'required',
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.filterHeaders,
              text: item => item,
              value: item => item,
            },
            multiple: true,
            returnObject: true
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.showColumns',
        }
      ]
    },
    formItemsForHeaders(){
      let array = [];
      this.reportHeadersData.forEach(element => {
        array.push({
          key: {
            parent: 'dynamicReportsForm',
            child: element.name,
          },
          validationLabel: element.name,
          formType: {
            name: 'multipleSelect',
            items: {
              data: element.data,
              text: element.itemData? element.itemData.text : item => item,
              value: element.itemData? element.itemData.value : item => item,
            },
            multiple: element.multiple
          },
          onChange: element.onChange,
          lg: 4,
          labelShown: false,
          topLabel: true,
          cols: 4,
          placeholder: element.name,
          active: element.active
        });
      });

      return array;
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    getValidValue(item, element){
      const value = item[element.value];

      if(typeof value === "number") return value;
      
      // Check If Date
      const dateValue = new Date(value)
      if(!isNaN(dateValue.getTime())){
        return convertToLocal(dateValue) 
      }

      return typeof  value === "object" ? '-' : item[element.value]
    },
    getServices(id){
      this.$store.state.loadingStatus = true;
      PortalService.getIntegrationServices(id)
      .then(response => {
        const data = response.data;
        if(data && data.length > 0)
          this.services = data
      })
      .catch(window.showError)
      .finally(() => {
        this.$store.state.loadingStatus = false;
      })
    },
    getDataById(){
      this.$store.state.loadingStatus = true;
      ReportService.getDetailDynamicReports(this.reportId)
      .then(response => {
        const data = response.data.Result;
        if(data){
          this.dynamicReportsForm = Object.assign({}, data);
        }
      })
      .catch(error => {
        this.$router.push({ name: 'dynamicReports' })
        window.showError(error)
      })
      .finally(() => {
        this.$store.state.loadingStatus = false;
      })
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    getFilterHeaders(){
      this.$store.state.loadingStatus = true;
      ReportService.getSampleDynamicReports()
      .then(response => {
        const data = response.data.Result;
        if(data){
          this.sampleData = data.SampleData;
          this.filterHeaders = data.Headers;
          if(!this.reportId) this.dynamicReportsForm.Columns = data.Headers;
        }
      })
      .catch(window.showError)
      .finally(() => {
        this.$store.state.loadingStatus = false;
      })
    },

    async setup() {
      this.countries = await getCountries()
      this.getFilterHeaders();
    },

    submitForm(){
      this.$store.state.loadingStatus = true;
      let body = removeNullValues(this.dynamicReportsForm);
      
      delete body.SqlQuery
      if(!body.Columns.includes('siparis_tarihi'))
        body.Columns.push('siparis_tarihi');

      try {
        ReportService.actionsOnDynamicReports(body, { isSupplier: this.isSupplier, isUpdate: this.isEditMode})
        .then(response => {
          window.showSuccess(this.$t(`messages.${this.isEditMode ?'successfulUpdateGeneral' : 'successfulAddGeneral'}`))
          if(this.reportId) this.getDataById();
          else{
            this.$router.push({ name: 'dynamicReports' });
          }
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        })

      } catch (error) {
        window.showError(error);
      }
    },
  },
};
</script>
<style></style>
