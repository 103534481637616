<template>
  <v-card elevation="1" class="mt-0">
    <v-card-title style="color: var(--darkBlue)">
      <v-row align="center">
        <v-col class="text-lg-left text-center" lg="3" cols="12">
          <h5>{{ $t('navigationDrawerMenu.reports') }}</h5>
        </v-col>
        <v-spacer />
        <v-col
          :cols="6"
          :sm="3"
          :md="4"
          :lg="3"
          :xl="3"
          class="d-flex align-center"
        >
          <v-autocomplete
            class="custom-border mr-2"
            v-model="selectedFilter"
            :value="selectedFilter"
            :items="filters"
            :label="$t('labels.reportType')"
            item-text="name"
            item-value="value"
            @change="getStatisticsList()"
            clearable
            dense
            hide-details
            variant="solo"
            outlined
          >
          </v-autocomplete>
        </v-col>
        <v-col :cols="6" :sm="3" :md="3" :lg="2" :xl="2" class="py-1 py-lg-0">
          <!-- begin start date picker -->
          <v-dialog
            ref="startDateDialog"
            v-model="startDateModalDialog"
            :return-value.sync="dateModal.startDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDateFormat"
                readonly
                v-bind="attrs"
                v-on="on"
                :label="$t('labels.startDate')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateModal.startDate"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="startDateModalDialog = false">
                {{ $t('buttons.close') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.startDateDialog.save(dateModal.startDate);
                  getStatisticsList();
                "
              >
                {{ $t('buttons.select') }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <!-- end start date picker -->
        </v-col>
        <v-col :cols="6" :sm="3" :md="3" :lg="2" :xl="2" class="py-1 py-lg-0">
          <!-- begin end date picker -->
          <v-dialog
            ref="endDateDialog"
            v-model="endDateModalDialog"
            :return-value.sync="dateModal.endDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDateFormat"
                readonly
                v-bind="attrs"
                v-on="on"
                :label="$t('labels.endDate')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateModal.endDate"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="endDateModalDialog = false">
                {{ $t('buttons.close') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.endDateDialog.save(dateModal.endDate);
                  getStatisticsList();
                "
              >
                {{ $t('buttons.select') }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <!-- end end date picker -->
        </v-col>
        
          <RefreshButton :refreshFunction="getStatisticsList"></RefreshButton>
         
        </v-col>
<v-col :cols="1" class="py-1 py-lg-0">
         <v-btn
            @click="resetFilters"
            :style="'color: white; background-color:#e44655'"
            class="rounded-circle"
            style="min-width: 1px; min-height: 1px; width: 36px; height: 36px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small color="white" style="font-size: 24px" v-on="on">
                  mdi-vacuum
                </v-icon>
              </template>
              <span>{{ $t('buttons.resetFilters') }}</span>
            </v-tooltip>
          </v-btn>
          </v-col>
      </v-row>
    </v-card-title>
    <v-divider />

    <v-data-table
      :headers="tableHeaders"
      :items="formattedTableData"
      hide-default-footer
      style="overflow-x: hidden !important"
      :options="{ itemsPerPage: itemsPerPage }"
      :loading="$store.state.loadingStatus"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:footer>
        <div class="d-flex justify-center mt-8 pb-lg-0 pb-8">
          <v-row justify="space-between">
            <v-col md="2"></v-col>
            <v-col md="8" class="ml-md-auto">
              <v-pagination
                v-if="pageLength > 0"
                class="my-4"
                @input="onPageChange"
                :value="page"
                :length="pageLength"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import ReportService from '../services/ReportService';
import { shipmentTypes } from '../utils/constants';

export default {
  data: () => ({
    dateModal: {
      startDate: '',
      endDate: '',
    },
    startDateModalDialog: false,
    endDateModalDialog: false,
    reportType: 0,
    tableHeaders: [],
    tableData: [],
    filters: [],
    selectedFilter: null,

    page: 1,
    totalItems: 0,
    itemsPerPage: 15,
    shipmentTypes,
    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
  }),
  computed: {
    startDateFormat() {
      return this.dateModal.startDate
        ? convertToLocal(this.dateModal.startDate)
        : null;
    },
    endDateFormat() {
      return this.dateModal.endDate
        ? convertToLocal(this.dateModal.endDate)
        : null;
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    formattedTableData() {
      return this.tableData.map((item) => ({
        ...item,
        ShipmentType: this.getShipmentTypeName(item.ShipmentType),
        ExportImportType: this.getExportImportTypeName(item.ExportImportType),
      }));
    },
  },
  mounted() {
    this.filters = [
      {
        name: this.$t('adminFilters.supplier'),
        value: 0,
      },
      {
        name: this.$t('adminFilters.supplierDetail'),
        value: 1,
      },
      {
        name: this.$t('adminFilters.customer'),
        value: 2,
      },
      {
        name: this.$t('adminFilters.customerDetail'),
        value: 3,
      },
      {
        name: this.$t('adminFilters.carriageType'),
        value: 4,
      },
    ];
    this.setup();
  },
  components: {
    RefreshButton,
  },
  methods: {
    setup() {
      this.getStatisticsList();
    },

    resetFilters() {
      this.selectedFilter = null;
      this.dateModal.startDate = '';
      this.dateModal.endDate = '';
      this.getStatisticsList();
    },
    getStatisticsList() {
      this.$store.state.loadingStatus = true;
      ReportService.getReportsList(
        this.dateModal.startDate,
        this.dateModal.endDate,
        this.selectedFilter,
        this.page,
        this.itemsPerPage
      )
        .then((res) => {
          if (res.data.Result.length > 0) {
            this.tableHeaders = this.extractHeaders(res.data.Result[0]);
            this.tableData = res.data.Result;
          } else {
            this.tableHeaders = [];
            this.tableData = [];
          }
          this.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
            this.tableHeaders = [];
            this.tableData = [];
          window.showError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    extractHeaders(item) {
      console.log(item);
      return Object.keys(item).map((key) => ({
        text: this.$t(`adminHeaders.${key}`),
        value: key,
      }));
    },
    getShipmentTypeName(shipmentTypeId) {
      const shipmentType = this.shipmentTypes.find(
        (type) => type.Id === shipmentTypeId
      );
      return shipmentType ? shipmentType.Name : this.$t('labels.unknown');
    },
    getExportImportTypeName(exportImportTypeId) {
      return exportImportTypeId === 0
        ? this.$t('labels.import')
        : this.$t('labels.export');
    },
    onPageChange(newPage) {
      this.page = newPage;
      this.getStatisticsList();
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getStatisticsList();
    },
  },
};
</script>
