import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'rating';

export default {
  add(rate, note, orderId) {
    return axios.post(`${RESOURCE_NAME}/add`, {
      Rate: rate,
      Note: note,
      OrderId: orderId,
    });
  },
  getAll(page = 1, pageSize = 1) {
    return axios.get(`${RESOURCE_NAME}/getList`, {
      params: { page, pageSize },
    });
  },
  getRateOrderDetail(OrderId) {
    return axios.get(`${RESOURCE_NAME}/getByOrderId`, {
      params: { OrderId },
    });
  },
};
