// Örnek servis codiagno login
import { update } from 'lodash';
import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/shipment_boxes';

export default {
  getList(id) {
    return axios.get(`${RESOURCE_NAME}/GetList`, {
      params: { ShipmentId: id },
    });
  },
  update(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  /**
   * Get package types for package details.
   * @returns {Promise}
   */
  getPackageTypes() {
    return axios.get(`/package-type/get`);
  },
};
