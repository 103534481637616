<template>
  <v-card elevation="1" class="mt-0">

    <div class="flex w-full rounded-tl-sm rounded-tr-sm">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col class="text-lg-left text-center" lg="5" cols="12">
            <h3>{{ $t('titles.blacklistedByUser') }}</h3>
          </v-col>
        </v-row>
        <div>
          <RefreshButton :refreshFunction="getBlacklistedUsers"></RefreshButton>
        </div>
      </v-card-title>
    </div>
    <v-divider />

    <v-data-table
      key="BLACKLISTED_USERS"
      :headers="blacklistedUsersHeaders"
      hide-default-footer
      style="overflow-x: hidden !important"
      :options="{ itemsPerPage: itemsPerPage }"
      :loading="$store.state.loadingStatus"
      :items="blacklistedUsers"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:footer>
        <div class="d-flex justify-center mt-8 pb-lg-0 pb-8 px-4">
          <v-row justify="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                v-if="pageLength > 0"
                class="my-4"
                @input="onPageChange"
                :value="page"
                :length="pageLength"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto d-flex pt-8">
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>{{ $t('messages.noBlacklistedUser') }}</h4>
        </div>
      </template>
      <template v-slot:item.FileUrl="{ item }">
        <v-btn
          v-if="item.FileUrl"
          elevation="0"
          color="primary"
          class="button"
          dark
          :href="item.FileUrl"
          depressed
          icon
          target="_blank"
          download
          ><v-icon> mdi-eye </v-icon></v-btn
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- eslint-enable -->
        <v-tooltip top v-if="item.IsGlobal">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="green"
              class="mr-1"
              style="font-size: 24px"
              v-bind="attrs"
              v-on="on"
              @click="updateCustomerStatus(item.Customer.Id, false)"
            >
              mdi-account-reactivate
            </v-icon>
          </template>

          <span>{{ $t('buttons.unbanUser') }}</span>
        </v-tooltip>
        <v-tooltip top v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="red"
              class="mr-1"
              style="font-size: 24px"
              v-bind="attrs"
              v-on="on"
              @click="updateCustomerStatus(item.Customer.Id, true)"
            >
              mdi-cancel
            </v-icon>
          </template>

          <span>{{ $t('buttons.banUser') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ITEMS_PER_PAGE_OPTIONS } from '../utils/constants';
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';
import FormElement from '../components/FormElement.vue';
import RefreshButton from '../components/RefreshButton.vue';
import BlacklistServices from '../services/BlacklistServices';

export default {
  data() {
    return {
      blacklistedUsersHeaders: [
        {
          text: this.$t('labels.companyName'),
          value: 'Customer.CompanyName',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.blockedBy'),
          value: 'BlockedBy[0].CompanyName',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.reason'),
          value: 'Reason',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.file'),
          value: 'FileUrl',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          groupable: false,
        },
      ],
      blacklistedUsers: [],

      // States
      pageLoading: false,
      itemsPerPageOptions: [],
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
    };
  },
  components: { CreateOfferForm, FormElement, RefreshButton },
  async mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();
  },
  computed: {
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  watch: {},
  methods: {
    updateCustomerStatus(id, isBanned) {
      let service = null;
      if (isBanned) {
        service = BlacklistServices.block;
      } else {
        service = BlacklistServices.unblock;
      }

      if (service) {
        this.$store.state.loadingStatus = true;
        service({
          CustomerId: id,
        })
          .then((response) => {
            window.showSuccess(
              this.$t(
                'messages.' +
                  (isBanned ? 'banUserSuccessfully' : 'unbanUserSuccessfully')
              )
            );
            this.getBlacklistedUsers();
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getBlacklistedUsers();
    },

    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {
      this.page = newPage;
      this.getBlacklistedUsers();
    },

    setup() {
      this.getBlacklistedUsers();
    },

    /**
     * Get all offer prices from service by filters.
     */
    getBlacklistedUsers() {
      this.$store.state.loadingStatus = true;
      BlacklistServices.getForAdmins(this.page, this.itemsPerPage)
        .then((response) => {
          this.blacklistedUsers = response.data.Result || [];
          this.totalItems = response.data.TotalItems;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.item-header {
  color: var(--turquoise) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}

.filter-label {
  font-size: 14px;
  color: gray;
}
.filter-select-item {
  font-size: 12px;
}
.column-select-item {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.column-select-item:first-child {
  margin-left: 0 !important;
}
.column-select-item:last-child {
  margin-right: 0 !important;
}
</style>
