import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/notification';

export default {
  getNotifications(Page = 1, PageSize = 15) {
    return axios.get(`${RESOURCE_NAME}/listNotifications`, {
      params: { page: Page, pageSize: PageSize },
    });
  },
  readNotification(body) {
    return axios.put(`${RESOURCE_NAME}/readById`, body);
  },
  readAllNotifications() {
    return axios.put(`${RESOURCE_NAME}/readAll`);
  },
};
