import MyUploadAdapter from "./MyUploadAdapter";
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository';
export default class MyUploadAdapterPlugin {
    static get pluginName() {
      return 'MyUploadAdapterPlugin';
    }
  
    static get requires() {
      return [ 'FileRepository' ];
    }
  
    static get defaultConfig() {
      return {
        uploadUrl: 'https://example.com/image/upload',
      };
    }
  
    constructor(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader, this);
      };
    }
  }