// Dil ayarlarını yapan modül
import VueI18n from 'vue-i18n';

// çevirilerin bulunduğu dosyalar
import tr from '../assets/i18n/languages/tr';
import en from '../assets/i18n/languages/en';

const messages = { en, tr };
export default {
  setLanguageSettings(Vue) {
    Vue.use(VueI18n);

    let i18n = new VueI18n({
      locale: localStorage.getItem('language') || 'tr', // set locale
      messages, // local mesajlar
    });

    return i18n;
  },
};
