<template>
  <v-card elevation="1">
    <v-dialog
      content-class="short-content"
      v-model="actionsModal"
      max-width="400"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('buttons.' + (isEditMode ? 'update' : 'add')) }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver ref="actionsOnExhangeRateObserver" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(submitForm)"
          >
            <v-card-text>
              <v-row>
                <FormElement
                    v-for="(element, index) in formItems"
                    :key="
                      element.key.parent + '_' + element.key.child + '_' + index
                    "
                    v-model="$data[element.key.parent][element.key.child]"
                    :validationLabel="$t(element.validationLabel)"
                    :rules="element.rules"
                    :formLabel="$t(element.formLabel)"
                    :onChange="element.onChange"
                    :formType="element.formType"
                    :lg="element.lg"
                    :cols="element.cols"
                    :disabled="element.disabled"
                    :hidden="element.hidden"
                    :labelShown="element.labelShown"
                    :active="element.active"
                    :topLabel="element.topLabel"
                    :placeholder="$t(element.placeholder)"
                  />
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;

                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="$store.state.loadingStatus"
                >{{ $t('buttons.save') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="actionsModal = false"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card-title class="d-flex flex-row" style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="12" cols="12">
          <h3>{{ $t('navigationDrawerMenu.currencyManagement') }}</h3>
        </v-col>
      </v-row>
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-3"
              style="font-size: 32px"
              v-bind="attrs"
              v-on="on"
              @click="actionsModal = true"
            >
              mdi-plus-box-outline
            </v-icon>
          </template>
          <span>{{ $t('buttons.add') }}</span>
        </v-tooltip>
        <RefreshButton :refreshFunction="getItems"></RefreshButton>
      </div>
    </v-card-title>
    <v-divider />
    <TabScreen class="mt-4" :slots="slots" :changeIndexFunction="onTabChange"
      style="
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
      ">
      <template v-slot:myExchangeRateList>
        <v-row class="mt-4 px-4 d-flex justify-start">
          <FormElement
            key="CURRENCY_CODE_FILTER"
            v-model="currencyCode"
            :vertical="true"
            hide-details
            :validationLabel="$t('labels.code')"
            :topLabel="true"
            :labelShown="false"
            :cols="6"
            :sm="4"
            :lg="3"
            :onChange="getItems"
            :formType="{
              name: 'multipleSelect',
              items: {
                data: currencies,
                text: 'Code',
                value: 'Code',
              },
            }"
            :placeholder="$t('labels.code')"
          />
          <FormElement
            key="VALIDITY_DATE_FILTER"
            :vertical="true"
            v-model="validityDate"
            hide-details
            :validationLabel="$t('labels.expiryDate')"
            :topLabel="true"
            :labelShown="false"
            :cols="6"
            :sm="4"
            :lg="3"
            @input="getItems"
            :formType="{
              name: 'date',
              dateMode: 'date'
            }"
            :placeholder="$t('labels.expiryDate')"
          />
        </v-row>
        <v-divider />
    
        <v-data-table
          :headers="commonHeaders"
          :items="items"
          hide-default-footer
          :loading="$store.state.loadingStatus"
          :options="{
            itemsPerPage: itemsPerPage,
          }"
          :item-key="'Id'"
          :no-data-text="$t('messages.noData')"
        >
          <template v-slot:footer>
            <div class="d-flex justify-center mt-8">
              <v-row align="center" justify="center">
                <v-col
                  v-if="
                    $vuetify.breakpoint.md ||
                    $vuetify.breakpoint.lg ||
                    $vuetify.breakpoint.xl
                  "
                  md="2"
                ></v-col>
                <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                  <v-pagination
                    v-if="pageLength > 0"
                    class="my-4"
                    @input="onPageChange"
                    v-model="page"
                    :length="pageLength"
                    :total-visible="10"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col
                  cols="11"
                  sm="11"
                  md="3"
                  lg="3"
                  xl="3"
                  class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                >
                  <v-select
                    v-model="itemsPerPage"
                    :items="perPageValues"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    outlined
                    dense
                    @change="changeItemsPerPage"
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="my-10 d-flex flex-column align-center">
              <h4>{{ $t('messages.noData') }}</h4>
            </div>
          </template>
          <!-- eslint-disable -->
          <template v-slot:item.Date="{ item }">
            {{ convertToLocal(item.ValidityDate ? item.ValidityDate : item.CreatedOnUtc) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-start">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="actionsModal = true
                    isEditMode = true
                    form = Object.assign({}, item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('buttons.search') }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </template>
      <template v-slot:centralBankExchangeRateList>
        <v-row class="mt-4 px-4 d-flex justify-start">
          <FormElement
            key="CURRENCY_CODE_FILTER_CENTRAL_BANK"
            v-model="currencyCode"
            :vertical="true"
            hide-details
            :validationLabel="$t('labels.code')"
            :topLabel="true"
            :labelShown="false"
            :cols="6"
            :sm="4"
            :lg="3"
            :onChange="getItems"
            :formType="{
              name: 'multipleSelect',
              items: {
                data: currencies,
                text: 'Code',
                value: 'Code',
              },
            }"
            :placeholder="$t('labels.code')"
          />
          <FormElement
            key="VALIDITY_DATE_FILTER"
            :vertical="true"
            v-model="validityDate"
            hide-details
            :validationLabel="$t('labels.expiryDate')"
            :topLabel="true"
            :labelShown="false"
            :cols="6"
            :sm="4"
            :lg="3"
            @input="getItems"
            :formType="{
              name: 'date',
              dateMode: 'date'
            }"
            :placeholder="$t('labels.expiryDate')"
          />
        </v-row>
        <v-divider />
    
        <v-data-table
          :headers="commonHeaders"
          :items="items"
          hide-default-footer
          :loading="$store.state.loadingStatus"
          :options="{
            itemsPerPage: itemsPerPage,
          }"
          :item-key="'Id'"
          :no-data-text="$t('messages.noData')"
        >
          <template v-slot:footer>
            <div class="d-flex justify-center mt-8">
              <v-row align="center" justify="center">
                <v-col
                  v-if="
                    $vuetify.breakpoint.md ||
                    $vuetify.breakpoint.lg ||
                    $vuetify.breakpoint.xl
                  "
                  md="2"
                ></v-col>
                <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                  <v-pagination
                    v-if="pageLength > 0"
                    class="my-4"
                    @input="onPageChange"
                    v-model="page"
                    :length="pageLength"
                    :total-visible="10"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col
                  cols="11"
                  sm="11"
                  md="3"
                  lg="3"
                  xl="3"
                  class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                >
                  <v-select
                    v-model="itemsPerPage"
                    :items="perPageValues"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    outlined
                    dense
                    @change="changeItemsPerPage"
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="my-10 d-flex flex-column align-center">
              <h4>{{ $t('messages.noData') }}</h4>
            </div>
          </template>
          <!-- eslint-disable -->
          <template v-slot:item.Date="{ item }">
            {{ convertToLocal(item.ValidityDate ? item.ValidityDate : item.CreatedOnUtc) }}
          </template>
        </v-data-table>
      </template>
    </TabScreen>
    
  </v-card>
</template>
<script>
import CurrencyManagementServices from '../services/CurrencyManagementServices';
import {
  ITEMS_PER_PAGE_OPTIONS,
  getCurrencyTypes,
removeNullValues,
} from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import RefreshButton from '../components/RefreshButton.vue';
import TabScreen from '../components/TabScreen.vue';

export default {
  data() {
    return {
      currencies: [],
      countries: [],
      marketplaces: [],
      headers: [],
      items: [],
      statuses: [],

      // Filters
      currencyCode: '',
      validityDate: new Date(),

      // States
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      isEditMode: false,

      actionsModal: false,
      form: {
        ValidityDate: ""
      },

      slots: [],
      centralBankExchangeRateList: [],
      selectedTab: 0,
      dateFilter: new Date()
    };
  },
  components: { FormElement, RefreshButton, TabScreen },
  mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();
    
    this.slots = [
      {
        name: 'myExchangeRateList',
        title: this.$t('titles.myExchangeRateList'),
      },
      {
        name: 'centralBankExchangeRateList',
        title: this.$t('titles.centralBankExchangeRateList'),
      },
    ];
  },
  watch: {
    actionsModal(newValue) {
      if (!newValue) {
        this.$refs.actionsOnExhangeRateObserver.reset();
        this.form = {
          ValidityDate: ""
        };
        this.isEditMode = false;
      }
    },
  },
  computed: {
    commonHeaders(){
      let temporaryHeaders = [...this.headers];
      if(this.selectedTab === 0){
        temporaryHeaders.push({
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
        });
      }

      return temporaryHeaders;
    },
    formItems(){
      return [
        {
          key: {
            parent: 'form',
            child: 'CurrencyId',
          },
          validationLabel: 'labels.code',
          rules: 'required',
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.currencies,
              text: 'Code',
              value: 'Id',
            },
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.code',
          active: !this.isEditMode && this.actionsModal
        },
        {
          key: {
            parent: 'form',
            child: 'Rate',
          },
          rules: 'required|double',
          validationLabel: 'labels.price',
          formType: {
            name: 'text',
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.price',
        },
        {
          key: {
            parent: 'form',
            child: 'ValidityDate',
          },
          rules: 'required',
          validationLabel: 'labels.expiryDate',
          formType: {
            name: 'date',
            dateMode: 'date'
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.expiryDate',
          active: !this.isEditMode && this.actionsModal
        },
      ]
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    onTabChange(index){
      this.selectedTab = index;
      this.currencyCode = "";
      this.validityDate = new Date();
      this.getItems();
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    changeItemsPerPage() {
      this.page = 1;
      this.getItems();
    },

    /**
     * Trigger when page changed.
     */
    onPageChange() {
      this.getItems();
    },

    async setup() {
      this.currencies = await getCurrencyTypes();

      this.getItems();
      this.headers = [
        {
          text: this.$t('labels.code'),
          value: 'Code',
          sortable: false,
          groupable: false,
          cellClass: 'regular-cell',
          align: 'center',
        },
        {
          text: this.$t('labels.price'),
          value: 'Rate',
          sortable: false,
          groupable: false,
          cellClass: 'regular-cell',
          align: 'center',
        },
        {
          text: this.$t('labels.expiryDate'),
          value: 'Date',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
      ];
    },

    /**
     * Get search history from service.
     */
    getItems() {
      this.$store.state.loadingStatus = true;
      let getFunction = null, parameters = {};
      const formattedDate = this.validityDate.split("T")[0];

      if(this.selectedTab === 0){
        getFunction = CurrencyManagementServices.getAll;
        parameters.ValidityDate = formattedDate;
      }
      else{
        getFunction = CurrencyManagementServices.getExchangeRateList;
        parameters.CreatedOn = formattedDate;
      }

        getFunction({
          Code: this.currencyCode,
          ...parameters
        })
        .then((response) => {
          this.items = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    submitForm(){
      this.$store.state.loadingStatus = true;
      let body = removeNullValues(this.form), requestFunction = null;

      try {
        if(this.isEditMode){
          requestFunction = CurrencyManagementServices.update;
          body = {
            Id: body.Id,
            Rate: body.Rate
          }
        }
        else{
          requestFunction = CurrencyManagementServices.add;
          body = {
            CurrencyId: body.CurrencyId,
            Rate: body.Rate,
            ValidityDate: body.ValidityDate
          }
        }

        if(requestFunction){
          requestFunction(body)
          .then(response => {
            window.showSuccess(this.$t(`messages.${this.isEditMode ?'successfulUpdateGeneral' : 'successfulAddGeneral'}`))
            this.actionsModal = false;
            this.getItems();
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          })

        }
      } catch (error) {
        window.showError(error);
      }
    },
  },
};
</script>
<style></style>
