<template>
  <v-card elevation="1" class="mt-0 py-lg-5" style="margin: 0 !important; padding-right: 4%; padding-left: 4%">
    <v-card-title style="color: #375081" class="justify-lg-start justify-center">
      <h5 v-if="!order.Rate">
        {{ $t('titles.rateSupplier') + ' / ' + order.RefNo }}
      </h5>
      <h5 v-if="order.Rate">{{ $t('titles.alreadyRateSupplier') }}</h5>
      <v-btn style="
          color: white;
          background-color: #004780;
          border-radius: 12px !important;
        " class="button my-2 ml-auto" @click="
          $router.push({
            name: 'OrderDetail',
            query: { orderId: order.OrderId },
            params: { orderId: order.OrderId },
          })
          ">
        {{ $t('title.orderDetails') }}
      </v-btn>
    </v-card-title>
    <div>
      <div class="d-lg-flex">
        <v-card class="d-flex" style="
            width: 100%;
            border-width: 1px;
            border-color: #f0f0f4;
            border-style: solid;
            border-radius: 20px;
            margin-right: 5px;
          ">
          <v-card-text class="text-center">
            <p class="font-weight-bold" style="font-size: 18px">
              {{ $t('labels.orderDate') }}
            </p>
            <p class="font-weight-bold" style="font-size: 20px">
              {{ createdDate }}
            </p>
          </v-card-text>
        </v-card>

        <v-card class="d-flex" style="
            width: 100%;
            border-width: 1px;
            border-color: #f0f0f4;
            border-style: solid;
            border-radius: 20px;
            margin-right: 5px;
          ">
          <v-card-text class="text-center">
            <p class="font-weight-bold" style="font-size: 18px">
              {{ $t('labels.supplier') }}
            </p>
            <p class="font-weight-bold" style="font-size: 20px">
              {{ order.Supplier }}
            </p>
          </v-card-text>
        </v-card>

        <v-card class="d-flex" style="
            width: 100%;
            border-width: 1px;
            border-color: #f0f0f4;
            border-style: solid;
            border-radius: 20px;
            margin-right: 5px;
          ">
          <v-card-text class="text-center">
            <p class="font-weight-bold" style="font-size: 18px">
              {{ $t('labels.orderFrom') + '/' + $t('labels.orderTo') + ': ' }}
            </p>
            <p class="font-weight-bold" style="font-size: 20px">
              {{ order.FromToWhere }}
            </p>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-sheet class="py-5">
      <h3 style="color: #767676">{{ $t('messages.rateSupplier') }}</h3>
      <v-row>
        <v-col cols="6" class="d-flex align-center justify-center pb-8">
          <div class="d-flex flex-column">
            <v-rating empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full" hover dense
              half-increments length="5" color="#FFCD06" background-color="#FFCD06" size="48" v-model="rate"
              style="width: fit-content" class="align-self-center" :readonly="!!order.Rate">
            </v-rating>
          </div>
        </v-col>
        <v-col cols="6">
          <ValidationObserver ref="rateObserver">
            <form autocomplete="off">
              <v-row class="my-4">
                <FormElement v-for="(element, index) in formItems" :key="element.key + '_' + index"
                  :vertical="element.vertical" v-model="$data[element.key]" :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules" :formLabel="$t(element.formLabel)" :onChange="element.onChange"
                  :formType="element.formType" :lg="element.lg" :cols="element.cols" :hidden="element.hidden"
                  :active="element.active" :topLabel="true" :labelShown="false" :placeholder="$t(element.placeholder)"
                  :disabled="!!order.Note" />
              </v-row>
            </form>
          </ValidationObserver>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn elevation="0" :style="!!order.Note ? '' : 'color: white !important'" color="#FB773D" class="button"
            type="submit" style="border-radius: 12px !important" :disabled="!!order.Note"
            :loading="$store.state.loadingStatus" @click="rateSupplier">
            {{ $t('buttons.rate') }}
          </v-btn>
        </v-col></v-row>
    </v-sheet>
  </v-card>
</template>
<script>
import FormElement from '../components/FormElement.vue';
import OrderService from '../services/OrderService';
import { triggerLoading } from '../utils/constants';
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';
import RateServices from '../services/RateServices';
import formatDate from '../utils/formatDate';

export default {
  data() {
    return {
      // States
      pageLoading: false,
      rate: 0,
      note: '',
      order: {},
    };
  },
  components: { CreateOfferForm, FormElement },
  mounted() {
    this.order = this.$route.params.order || {};
    this.getRateOrderDetail();
  },
  computed: {
    createdDate() {
      if (this.order?.CreatedOnUtc) {
        return formatDate(new Date(this.order.CreatedOnUtc), '/');
      }
    },
    formItems() {
      return [
        {
          key: 'note',
          rules: {
            min: 20,
            max: 250,
          },
          validationLabel: 'labels.note',
          formLabel: 'labels.note',
          formType: {
            counter: 250,
            name: 'textarea',
          },
          lg: 12,
          placeholder: 'labels.note',
        },
      ];
    },
  },
  watch: {},
  methods: {
    getRateOrderDetail() {
      if (!this.order.Id) {
        window.showInfo(this.$t('description.noOrderId'), 'standart', 'info');
        setTimeout(() => {
          this.$router.push({ name: 'orders' });
        }, 3000);
      } else {
        this.$store.state.loadingStatus = true;
        RateServices.getRateOrderDetail(this.order.Id)
          .then((response) => {
            this.order = response.data.Result;
            this.rate = this.order.Rate;
            this.note = this.order.Note;
          })
          .catch((error) => this.onServiceError(error))
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    rateSupplier() {
      this.$refs.rateObserver.validate().then(async (valid) => {
        if (!valid) {
          return;
        }
        this.$store.state.loadingStatus = true;
        RateServices.add(this.rate, this.note, this.order.OrderId)
          .then((response) => {
            window.showSuccess(this.$t('messages.ratedSuccessfully'));
          })
          .catch((error) => this.onServiceError(error))
          .finally(() => {
            this.$store.state.loadingStatus = false;
            this.$router.push({ name: 'orders' });
          });
      });
    },
    /**
     * Get the active order.
     */
    getOrder() {
      triggerLoading();
      OrderService.getOrderById(this.orderId)
        .then((response) => { })
        .catch((error) => this.onServiceError(error))
        .finally(() => triggerLoading());
    },
    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      this.resetForm();
    },
  },
};
</script>
<style>
.detail-item {
  font-size: 18px !important;
}

.detail-item:first-child {
  margin-left: 0 !important;
}
</style>
