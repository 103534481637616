<template>
  <fragment v-if="active">
    <v-col
      class="d-flex"
      cols="12"
      lg="12"
      :style="!isLast ? 'border-bottom: 1px solid #e1dcdc;' : ''"
    >
      <div
        style="width: 80px; height: 80px; border: 1px solid #ece7e7; padding: 10px;"
      >
        <v-img
          :lazy-src="item.photoUrl"
          contain
          :src="item.photoUrl || require('../assets/no-image.png')"
        ></v-img>
      </div>
      <div style="color: #666666" class="ml-5">
        <h3>{{ item.title }}</h3>
        <p>{{ item.subtitle }}</p>
      </div>
      <v-spacer />
      <div class="d-flex flex-column">
        <h3 style="font-size:20px; color: rgb(56, 157, 16);">
          {{ item.price }}$
        </h3>
        <v-btn
          @click="deleteItem(item.id)"
          v-if="deleteItem"
          class="mt-auto align-self-center"
          icon
          color="pink"
        >
          <v-icon>mdi-delete-circle</v-icon>
        </v-btn>
      </div>
    </v-col>
  </fragment>
</template>
<script>
export default {
  constructor: () => {},
  name: "CartItem",
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    deleteItem: {
      type: Function,
      default: null,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
        return {
          title: "Cart Title",
          subtitle: "Cart Subtitle",
          photoUrl: require("../assets/no-image.png"),
          price: 0,
        };
      },
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
