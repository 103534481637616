import FileService from '../services/FileService';

/**
 * Upload File
 * @param {*} file
 * @param {function} successFunction
 * @returns {Promise<any>}
 */
export default (file, successFunction = () => {}, onlyData = false) => {
  return new Promise((resolve, reject) => {
    try {
      if (file) {
        const media = file;

        let formData = new FormData();
        if (media.type == 'image/jpeg' || media.type == 'image/png') {
          formData.append('Files', media);
          formData.append('Type', 'Media');
        }

        FileService.uploadFile(formData)
          .then((uploadFileResponse) => {
            if (successFunction) {
              if (typeof successFunction === 'function') {
                successFunction(onlyData ? uploadFileResponse.data : uploadFileResponse.data.Result[0].Url);
              } else {
                throw new Error('Please send a valid function.');
              }
            }

            resolve(uploadFileResponse.data.Result[0].Url);
          })
          .catch((error) => {
            reject(error);
            console.log('Upload File Error => ', error);
          });
      }
    } catch (error) {
      reject(error);
    }
  });
};
