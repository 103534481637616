import axios from '../javascript/custom-axios';

const RESOURCE_NAME = '/frequently-asked-questions';

export default {
  addFAQ(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },

  updateFAQ(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  deleteFAQ(id) {
    return axios.delete(`${RESOURCE_NAME}/delete`, { params: { Id: id } });
  },

  getFAQs() {
    return axios.get(`${RESOURCE_NAME}/get`);
  },

  getFAQById(id) {
    return axios.get(`${RESOURCE_NAME}/getById`, {
      params: { id: id },
    });
  },
};
