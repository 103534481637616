// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'blacklist';

export default {
  /**
   * Get all blacklisted customers for supplier.
   * @returns {Promise}
   */
  getForSuppliers() {
    return axios.get(`${RESOURCE_NAME}/get`);
  },

  /**
   * Add customer to blacklist.
   * @param {any} body
   * @returns {Promise}
   */
  add(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },

  /**
   * Remove customer from blacklist.
   * @param {any} body
   * @returns {Promise}
   */
  delete(body) {
    return axios.delete(`${RESOURCE_NAME}/delete`, {
      data: body
    });
  },

  /**
   * Get all blacklisted customers for admins.
   * @returns {Promise}
   */
  getForAdmins() {
    return axios.get(`${RESOURCE_NAME}/admin-get`);
  },

  /**
   * Block user globally.
   * @param {any} body
   * @returns {Promise}
   */
  block(body) {
    return axios.post(`${RESOURCE_NAME}/admin-global-block`, body);
  },

   /**
   * Unblock user globally.
   * @param {any} body
   * @returns {Promise}
   */
   unblock(body) {
    return axios.post(`${RESOURCE_NAME}/admin-global-ublock`, body);
  },
};
