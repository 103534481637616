export const phone = {
  required: true,
  numeric: true,
  max: 15,
};

export default {
    phone
};

