import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/memebershipConfirmations';

export default {
  getKVKK() {
    return axios.get(`${RESOURCE_NAME}/kvkk`);
  },

  getRegisterContract() {
    return axios.get(`${RESOURCE_NAME}/registerContract`);
  },
  
  getConsent() {
    return axios.get(`${RESOURCE_NAME}/consentText`);
  },
};
