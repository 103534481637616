<template>
  <div>
    <v-card style="border-radius: 10px">
      <v-card-title class="popupHeadline">
        <v-row>
          <v-col class="py-2">{{ $t('title.exportTitle') }}</v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-row align="start" class="d-flex mb-6">
          <v-col cols="2" class="pa-2 mr-auto">
            <v-select
              v-model="selectedMarketplace"
              :value="selectedMarketplace"
              :items="marketplaces"
              :label="$t('labels.marketplaceLabel')"
              item-text="MarketplaceName"
              item-value="MarketplaceId"
              outlined
              dense
              rounded
            >
            </v-select>
          </v-col>
          <v-col cols="2" class="pa-2 mr-auto">
            <v-select
              v-model="selectedCategory"
              :value="selectedCategory"
              :items="categories.concat({ CategoryName: 'Tümü', Id: null })"
              :label="$t('labels.category')"
              item-text="CategoryName"
              item-value="Id"
              outlined
              dense
              rounded
            >
            </v-select>
          </v-col>
          <v-col cols="2" class="pa-2 mr-auto">
            <v-select
              v-model="selectedStatus"
              :value="selectedStatus"
              :items="statuses.concat({ Name: 'Tümü', Value: null })"
              :label="$t('labels.status')"
              item-text="Name"
              item-value="Value"
              outlined
              dense
              rounded
            >
            </v-select>
          </v-col>
          <v-col cols="2" class="pa-2 mr-auto"> </v-col>
          <v-col cols="2" class="pa-2mr-auto">
            <div style="gap: 1rem">
              <span
                class="align-self-center mr-2"
                style="font-size: 12px; font-weight: normal; color: #598db5"
                >{{ $t('buttons.createFile') }}</span
              >
              <v-icon
                @click="createFile()"
                class="icon-button mr-4 align-self-center"
              >
                mdi-plus
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :options.sync="options"
          :items-per-page="itemsPerPage"
          :hide-default-footer="true"
          :no-data-text="$t('description.noList')"
          ref="wordList"
        >
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.FileStatus="{ item }">
            <v-chip
              v-if="item.FileStatus == 1"
              class="ma-2"
              small
              color="#a7fdba"
              text-color="black"
            >
              {{ $t('labels.ready') }}
            </v-chip>

            <v-chip
              v-if="item.FileStatus == 0"
              class="ma-2 text--center"
              small
              color="#F57C00"
              text-color="white"
            >
              {{ $t('labels.preparing') }}
            </v-chip>
            <v-chip
              v-if="item.FileStatus == 2"
              class="ma-2 text--center"
              small
              color="#B71C1C"
              text-color="white"
            >
              {{ $t('labels.getError') }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line  -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip v-if="item.FileStatus == 1" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  class="mr-2 green-icon"
                  @click="downloadFile(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-download
                </v-icon>
              </template>
              <span>{{ $t('buttons.download') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="pagination.page"
                :length="pages"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                @change="changeItemsPerPage()"
                outlined
                dense
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="red accent-2"
        rounded="pill"
      >
        {{ $t('description.marketplaceNotSelected') }}
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import OperationServices from '../../services/OperationService';
import CategoriesService from '../../services/CategoriesService';
import CompanyMarketService from '../../services/CompanyMarketplaceService';

export default {
  data: () => ({
    selectedMarketplace: null,
    marketplaces: [],
    selectedStatus: null,
    statuses: [],
    categories: [],
    selectedCategory: null,
    headers: [],
    tableData: [],

    // List options
    options: {
      itemsPerPage: 10,
    },
    pagination: {},
    itemsPerPage: 15,
    perPageValues: [
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
    snackbar: false,
    timeout: 3000,
  }),

  watch: {},
  mounted() {
    this.setup();
    this.getCompamyCategories();
    this.getMarketplaces();
    this.getTableData();
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return this.totalRecords / this.itemsPerPage;
    },
  },
  components: {},

  methods: {
    setup() {
      this.headers = [
        {
          text: 'Dosya Adı',
          value: 'FileName',
          sortable: false,
        },
        {
          text: 'Durum',
          value: 'FileStatus',
          sortable: false,
        },
        {
          text: 'İndirme',
          value: 'actions',
          sortable: false,
        },
        (this.pagination = {
          totalItems: 200,
          rowsPerPage: this.itemsPerPage,
          page: 1,
        }),
      ];
      this.pagination = {
        totalItems: 100,
        rowsPerPage: 15,
        page: 1,
      };
      this.statuses = [
        {
          Name: 'Aktif',
          Value: true,
        },
        {
          Name: 'Pasif',
          Value: false,
        },
      ];
    },
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getTableData();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getTableData();
    },
    downloadFile(item) {
      let body = {
        s3Uri: item.s3Uri,
      };
      this.$store.state.loadingStatus = true;
      OperationServices.downloadImportExampleFile(body, item.FileName)
        .then((res) => {})
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getCompamyCategories() {
      this.$store.state.loadingStatus = true;

      CategoriesService.getCategories(
        '',
        this.pagination.page,
        this.pagination.rowsPerPage
      )
        .then((res) => {
          this.categories = res.data.Result;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getMarketplaces() {
      this.$store.state.loadingStatus = true;
      CompanyMarketService.getMarkets()
        .then((res) => {
          this.marketplaces = res.data.Result;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    createFile() {
      if (this.selectedMarketplace == null) {
        this.snackbar = true;
      } else {
        this.$store.state.loadingStatus = true;
        let body = {
          MarketplaceId: this.selectedMarketplace,
          CategoryId: this.selectedCategory,
          Status: this.selectedStatus,
        };
        OperationServices.bulkExport(body)
          .then((res) => {
            window.showSuccess(
              'Dosyanız oluşturuluyor. İşlem bittiğinde dosyanızı indirebilirsiniz.'
            );
            this.getTableData();
          })
          .catch((error) => {
            window.showError(error);
          })
          .finally(() => {
            this.clearModal();
            this.$store.state.loadingStatus = false;
          });
      }
    },
    getTableData() {
      this.$store.state.loadingStatus = true;
      this.tableData = [];
      OperationServices.getExcelFiles(
        this.pagination.rowsPerPage,
        this.pagination.page
      )
        .then((res) => {
          this.tableData = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    clearModal() {
      this.selectedMarketplace = null;
      this.selectedCategory = null;
      this.selectedStatus = null;
    },
  },
};
</script>
