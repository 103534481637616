// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/reports';

export default {
  getReportsList(startDate, endDate, reportType, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/summary-reports`, {
      params: {
        PageSize: pageSize,
        Page: page,
        StartDate: startDate,
        EndDate: endDate,
        ReportType: reportType,
      },
    });
  },
  OrderReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-order-report`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  MarketplaceProfitReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-profit-report`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  OrderProfitReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-order-report`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  MarketplaceOrderReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-order-pie-graph`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  MarketplaceSalesReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-sales-bar-graph`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  GiroExpenseReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-giro-and-expense-line-graph`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  MarketplaceExpenseReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-expense-pie-graph`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
        IsShipmentCost: true,
      },
    });
  },
  GeneralSummaryReport() {
    return axios.get(`${RESOURCE_NAME}/general-summary-for-admin`);
  },

  // Dynamic Reports


  /**
   * Create dynamic reports action for user.
   * @param {*} body 
   * @param {{ isSupplier: boolean, isUpdate: boolean }}
   * @returns {Promise}
   */
  actionsOnDynamicReports(body, { isSupplier = false, isUpdate = false }){
    return axios[isUpdate ? 'put' : 'post'](`${RESOURCE_NAME}/${isSupplier ? 'supplier' : 'customer'}-${isUpdate ? 'update' : 'create'}-dynamic`, body);
  },

  /**
   * Get Data Of Dynamic Reports
   * @param {*} parameters
   * @returns {Promise}
   */
  getDataDynamicReports(params){
    return axios.get(`${RESOURCE_NAME}/supplier-dynamic-getdata`, {
      params
    });
  },

  /**
   * Get Details Of Dynamic Reports By Id
   * @param {string} Id
   * @returns {Promise}
   */
  getDetailDynamicReports(Id){
    return axios.get(`${RESOURCE_NAME}/supplier-dynamic-get-detail`, {
      params: {
        Id
      }
    });
  },

  /**
   * Get List Of Dynamic Reports
   * @param {*} params
   * @returns {Promise}
   */
  getListDynamicReports(params){
    return axios.get(`${RESOURCE_NAME}/dynamic-reports-get-list`, {
      params: params
    });
  },

  /**
   * Get Sample Of Dynamic Reports
   * @param {*} params
   * @returns {Promise}
   */
  getSampleDynamicReports(params){
    return axios.get(`${RESOURCE_NAME}/dynamic-reports-get-sample`, {
      params: params
    });
  },

  /**
   * Download A Dynamic Reports
   * @param {{ ReportId: string, StartDate?: string, EndDate?: string }} params
   * @returns {Promise}
   */
  downloadDynamicReports(params){
    return axios.get(`${RESOURCE_NAME}/download-dynamic-excels`, {
      params: params,
      responseType: 'blob'
    });
  },
};
