import axios from '../javascript/custom-axios';

export default {
  async getCompanyLogistics() {
    return axios.get(`company-logistic/get`);
  },

  async getLogistics() {
    return axios.get(`logistic/get`, {
      params: { Id: '' },
    });
  },

  async getAccounts(logisticId) {
    return axios.get(`company-logistic/get-for-price`, {
      params: { IntegrationLogistic: logisticId },
    });
  },

  async addCompanyLogistic(body) {
    return axios.post(`company-logistic/add`, body);
  },

  async updateCompanyLogistic(body) {
    return axios.put(`company-logistic/update`, body);
  },

  async deleteCompanyLogistic(Id) {
    return axios.delete(`company-logistic/delete/`, {
      params: { Id },
    });
  },

  /**
   * Create a shipment for UPS.
   * @param {{ OrderId: string }} body
   * @returns {Promise}
   */
  async createShipmentForUPS(body) {
    return axios.post(`ups/create-shipment`, body);
  },

  /**
   * Authorization for UPS.
   * @param {{ Id: string, Code: string, State: string }} body
   * @returns {Promise}
   */
  async authUPS(body) {
    return axios.post(`ups/get-authorization-url`, body);
  },

  /**
   * Get Integration Shipment By Order Id
   * @param {string} orderId
   * @returns {Promise}
   */
  async getIntegrationShipment(orderId) {
    return axios.get(`integration-ship/get-by-order`, {
      params: {
        OrderId: orderId,
      },
    });
  },
};
