// Örnek servis codiagno login
import { add } from 'lodash';
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'public-api';

export default {
  getAPIs() {
    return axios.get(`${RESOURCE_NAME}/get-informations`);
  },
  addAPI(body) {
    return axios.post(`${RESOURCE_NAME}/create-user`, body);
  },

  /**
   * Update an API's information.
   * @param {*} body 
   * @returns {Promise}
   */
  updateAPI(body) {
    return axios.put(`${RESOURCE_NAME}/update-user`, body);
  },
};
