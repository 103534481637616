export default {
  Token: '',
  RefreshToken: '',
  Avatar: '',
  Email: '',
  FirstName: '',
  FullName: '',
  Id: '',
  IsBlocked: '',
  LastName: '',
  Type: '',
  Username: '',
  PortalUserId: null,
  isPrevAdmin: false,
  i18n: {},
  router: '',
  validator: {},
  alertObject: {
    color: 'info',
    message: '',
    position: 'top',
  },
  Companies: [],
  CompanyId: '',
  GroupPermessionIds: [],
  menuItems: [],
  unseenCounts: {},
  perPageValues: [
    {
      name: '10',
      value: 10,
    },
    {
      name: '15',
      value: 15,
    },
    {
      name: '30',
      value: 30,
    },
    {
      name: '50',
      value: 50,
    },
    {
      name: '100',
      value: 100,
    },
  ],
  loadingStatus: false,
  forceLogout: false,
  globalOperation: false,
  Password: '',
  CompanyName: '',
  PhoneNumber: '',
  IsChangePassword: true,
  RequestCount: 5,
};
