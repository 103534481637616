<template>
  <div class="wrapper">
    <NavMenu :fromLogin="true" />
    <v-dialog v-model="passWordInfoDialog" max-width="500">
      <v-card>
        <v-card-title>
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('description.titleInformation') }}
          </div>
        </v-card-title>

        <v-card-text class="py-3">
          <div style="width: 100%">
            <ul>
              <li>{{ $t('description.passwordLength.min') }}</li>
              <li>{{ $t('description.passwordLength.max') }}</li>
              <li>{{ $t('description.passwordRequirements.uppercase') }}</li>
              <li>{{ $t('description.passwordRequirements.lowercase') }}</li>
              <li>{{ $t('description.passwordRequirements.digit') }}</li>
              <li>{{ $t('description.passwordRequirements.specialChar') }}</li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions
          style="
            background-color: white;
            position: sticky;
            bottom: 0px;
            border-top: solid grey 2px;
          "
        >
          <v-spacer></v-spacer>

          <v-btn
            class="btn-save rounded-lg"
            @click="passWordInfoDialog = false"
          >
            {{ $t('buttons.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      :class="
        operation === 'login'
          ? 'loginPage'
          : operation === 'register'
          ? 'registerPage'
          : 'forgotPasswordPage'
      "
    >
      <v-layout wrap align-center justify-center fill-height>
        <v-container>
          <v-layout
            v-if="operation === 'forgotPassword'"
            wrap
            align-center
            justify-center
            fill-height
          >
            <v-card
              style="padding: 2vh; border-radius: 20px"
              :style="{
                minWidth: $vuetify.breakpoint.xl
                  ? '40%'
                  : $vuetify.breakpoint.lg
                  ? '50%'
                  : $vuetify.breakpoint.md
                  ? '70%'
                  : $vuetify.breakpoint.sm
                  ? '80%'
                  : $vuetify.breakpoint.xs
                  ? '95%'
                  : '100%',
              }"
            >
              <v-row>
                <v-col md="12" xs="12">
                  <div>
                    <div
                      class="d-flex justify-center bold-title text-h6 text-sm-h6 text-md-h6 text-lg-h5 text-xl-h5 text-xxl-h5 font-weight-bold"
                    >
                      {{ $t('description.forgotPassword') }}
                    </div>
                    <ValidationObserver
                      ref="observer"
                      v-slot="{ handleSubmit }"
                    >
                      <form
                        @submit.prevent="handleSubmit(sendResetPasswordLink)"
                      >
                        <v-row class="mt-4">
                          <FormElement
                            v-model="forgot.email"
                            :validationLabel="$t('labels.email')"
                            :formLabel="$t('labels.email')"
                            :rules="'required|email'"
                            :formType="{
                              name: 'text',
                            }"
                            :labelShown="false"
                            :topLabel="true"
                            :placeholder="$t('labels.email')"
                          />
                        </v-row>
                        <v-row>
                          <v-col cols="6" class="d-flex justify-end">
                            <v-btn
                              style="
                                width: 75%;
                                color: #fff;
                                text-transform: unset !important;
                                background-color: var(--red) !important;
                                padding: 2vh 3vh;
                                border-radius: 30px !important;
                                font-weight: bolder;
                              "
                              elevation="0"
                              class="darken-1"
                              type="button"
                              :disabled="loadings.editedButton"
                              @click="operation = 'login'"
                            >
                              {{ $t('buttons.cancel') }}
                            </v-btn>
                          </v-col>
                          <v-col cols="6">
                            <v-btn
                              style="
                                width: 75%;
                                color: #fff;
                                text-transform: unset !important;
                                background-color: #0077d5;
                                padding: 2vh 3vh;
                                border-radius: 30px !important;
                                font-weight: bolder;
                              "
                              elevation="0"
                              class="darken-1"
                              type="submit"
                              :loading="loadings.editedButton"
                              :disabled="loadings.editedButton"
                            >
                              {{ $t('buttons.send') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </form>
                    </ValidationObserver>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
          <v-layout
            v-if="operation === 'register'"
            wrap
            align-center
            justify-center
            fill-height
          >
            <v-card
              style="padding: 2vh; border-radius: 20px"
              :style="{
                maxWidth: $vuetify.breakpoint.xl
                  ? '55%'
                  : $vuetify.breakpoint.lg
                  ? '60%'
                  : $vuetify.breakpoint.md
                  ? '70%'
                  : $vuetify.breakpoint.sm
                  ? '80%'
                  : $vuetify.breakpoint.xs
                  ? '95%'
                  : '100%',
              }"
            >
              <v-row>
                <v-col cols="12" md="12" class="d-flex mb-4">
                  <v-icon
                    large
                    style="color: #0177d4 !important"
                    id="menu-activator"
                    @click="
                      () => {
                        operation = 'login';
                        setup();
                      }
                    "
                  >
                    mdi-arrow-left
                  </v-icon>
                  <div class="mx-auto bold-title my-2">
                    {{ $t('labels.appTitle') }}
                  </div>
                </v-col>
                <v-col cols="12" md="12" xs="12">
                  <div>
                    <ValidationObserver
                      ref="observer"
                      v-slot="{ handleSubmit }"
                    >
                      <form
                        autocomplete="off"
                        @submit.prevent="handleSubmit(register)"
                      >
                        <v-row>
                          <FormElement
                            v-for="element in formItems"
                            :key="element.key.parent + '_' + element.key.child"
                            :vertical="element.vertical"
                            v-model="
                              $data[element.key.parent][element.key.child]
                            "
                            :validationLabel="$t(element.validationLabel)"
                            :rules="element.rules"
                            :formLabel="$t(element.formLabel)"
                            :onChange="element.onChange"
                            :formType="element.formType"
                            :lg="element.lg"
                            :cols="element.cols"
                            :hideDetails="element.hideDetails"
                            :topLabel="true"
                            :labelShown="false"
                            :placeholder="$t(element.placeholder)"
                          />

                          <v-col
                          cols="12"
                          class="d-flex flex-row align-center pb-0"
                        >
                          <v-checkbox
                            v-model="contactPermissionAccepted"
                          ></v-checkbox>
                          <span style="font-size: 12px">
                            {{ $t('labels.contactPermissionText') }}
                          </span>
                        </v-col>
                          <v-col
                            cols="12"
                            class="d-flex flex-row align-center pt-0"
                          >
                            <v-checkbox
                              v-model="termsAndConditionsAccepted"
                            ></v-checkbox>
                            <span style="font-size: 12px">
                              <router-link
                                to="/legal-info/terms"
                                target="_blank"
                                class="link"
                                >{{
                                  $t('labels.termsAndConditions')
                                }}</router-link
                              >,
                              <router-link
                                to="/legal-info/privacy"
                                target="_blank"
                                class="link"
                                >{{ $t('labels.privacyPolicy') }}</router-link
                              >
                              ve
                              <router-link
                                to="/legal-info/consent"
                                target="_blank"
                                class="link"
                                >{{ $t('labels.consent') }}</router-link
                              >
                              {{ $t('labels.read') }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-col
                          cols="12"
                          lg="12"
                          class="align-self-center text-center"
                        >
                          <v-btn
                            style="
                              text-transform: unset !important;
                              background-color: #0077d5;
                              color: #ffffff;
                              font-size: 16px;
                              padding: 2vh 6vh;
                              border-radius: 30px !important;
                              font-weight: bolder;
                            "
                            elevation="0"
                            class="darken-1 mt-md-0 mt-2"
                            type="submit"
                            :loading="pageLoading"
                            :disabled="!termsAndConditionsAccepted"
                          >
                            {{ $t('buttons.register') }}
                          </v-btn>
                        </v-col>
                      </form>
                    </ValidationObserver>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
          <v-layout
            v-if="operation === 'login'"
            wrap
            align-center
            justify-center
            fill-height
          >
            <v-card
              style="padding: 2vh; border-radius: 20px"
              :style="{
                minWidth: $vuetify.breakpoint.xl
                  ? '40%'
                  : $vuetify.breakpoint.lg
                  ? '50%'
                  : $vuetify.breakpoint.md
                  ? '70%'
                  : $vuetify.breakpoint.sm
                  ? '80%'
                  : $vuetify.breakpoint.xs
                  ? '95%'
                  : '100%',
              }"
            >
              <v-row>
                <v-col md="12" xs="12">
                  <div>
                    <div
                      class="bold-title d-flex justify-center text-h6 text-sm-h6 text-md-h6 text-lg-h5 text-xl-h5 text-xxl-h5 font-weight-bold"
                    >
                      {{ $t('labels.appLoginTitle') }}
                    </div>
                    <ValidationObserver
                      ref="observer"
                      v-slot="{ handleSubmit }"
                    >
                      <form @submit.prevent="handleSubmit(sendLogin)">
                        <FormElement
                          v-model="username"
                          :validationLabel="$t('labels.userEmail')"
                          :formLabel="$t('labels.userEmail')"
                          :rules="'required|email'"
                          :formType="{
                            name: 'text',
                          }"
                          :labelShown="false"
                          :topLabel="true"
                          :placeholder="$t('labels.userEmail')"
                        />

                        <FormElement
                          v-model="item.password"
                          :validationLabel="$t('labels.password')"
                          :formLabel="$t('labels.password')"
                          :rules="'required'"
                          :hideDetails="true"
                          :formType="{
                            name: showPassword ? 'text' : 're-password',
                            counter: '24',
                            appendIcon: showPassword
                              ? 'mdi-eye'
                              : 'mdi-eye-off',
                            appendClick: () => {
                              showPassword = !showPassword;
                            },
                          }"
                          :lg="12"
                          :placeholder="$t('labels.password')"
                          :labelShown="false"
                          :topLabel="true"
                        />

                        <a
                          style="color: #0077d5; font-size: 12px"
                          text
                          class="ml-4 align-self-center text-decoration-underline"
                          rounded
                          @click="operation = 'forgotPassword'"
                          >{{ $t('buttons.forgotPass') }}</a
                        >
                        <v-flex class="d-flex flex-row flex-column mt-4">
                          <v-btn
                            style="
                              text-transform: unset !important;
                              background-color: #0077d5;

                              padding: 2vh 6vh;
                              border-radius: 30px !important;
                              font-weight: bolder;
                            "
                            elevation="0"
                            class="darken-1 mt-md-0 mt-2 mx-auto"
                            dark
                            type="submit"
                            :loading="pageLoading"
                          >
                            {{ $t('buttons.login') }}
                          </v-btn>

                          <span
                            class="align-self-center mt-4"
                            style="font-size: 12px"
                          >
                            {{ $t('labels.noAccount') }}
                            <a
                              style="color: #0077d5; font-weight: bold"
                              text
                              class="align-self-center text-decoration-underline"
                              rounded
                              @click="operation = 'register'"
                              >{{ $t('buttons.registerNow') }}</a
                            >
                          </span>
                        </v-flex>

                        <div
                          class="d-flex justify-center align-end"
                          style="
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                          "
                        >
                          <p
                            v-if="remainingLogin > 0"
                            class="font-weight-black"
                            style="color: #0177d4 !important"
                          >
                            {{ remainingLogin }}
                            {{ $t('labels.userRemainingLogin') }}
                          </p>

                          <p
                            v-if="remainingLogin == 0"
                            class="font-weight-black"
                            style="color: #0177d4 !important"
                          >
                            {{ $t('labels.userBlocked') }}
                          </p>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
          <!-- Login form end -->

          <!-- SMS form start -->
          <v-layout
            v-if="operation === 'sms'"
            wrap
            align-center
            justify-center
            fill-height
          >
            <v-card
              style="padding: 2vh; border-radius: 20px"
              :style="{
                minWidth: $vuetify.breakpoint.xl
                  ? '40%'
                  : $vuetify.breakpoint.lg
                  ? '50%'
                  : $vuetify.breakpoint.md
                  ? '70%'
                  : $vuetify.breakpoint.sm
                  ? '80%'
                  : $vuetify.breakpoint.xs
                  ? '95%'
                  : '100%',
              }"
            >
              <v-row>
                <v-col md="12" xs="12">
                  <div>
                    <div
                      class="d-flex justify-center"
                      style="
                        color: #fb3d6b;
                        font-weight: 700;
                        font-size: 24px;
                        margin-bottom: 14px;
                      "
                    >
                      {{ $t('labels.smsVerificationCode') }}
                    </div>
                    <div class="text-center mb-3">
                      <span style="font-weight: bold; font-size: 40px">{{
                        time
                      }}</span
                      ><br />
                      saniye
                    </div>
                    <div
                      class="mb-3 d-flex justify-center align-center text-center mb-4"
                    >
                      <span style="font-size: 14px">{{
                        $t('messages.verificationCodeSent', {
                          phone: temporaryPhone,
                        })
                      }}</span>
                    </div>
                    <ValidationObserver
                      ref="observer"
                      v-slot="{ handleSubmit }"
                    >
                      <form @submit.prevent="handleSubmit(completeLogin)">
                        <v-row class="d-flex justify-center align-center mt-4">
                          <FormElement
                            v-model="verificationCode"
                            :validationLabel="$t('labels.smsVerificationCode')"
                            :formLabel="$t('labels.smsVerificationCode')"
                            :rules="'required|integer|max:6'"
                            :formType="{
                              name: 'text',
                            }"
                            :labelShown="false"
                            :topLabel="true"
                            :cols="10"
                            :lg="10"
                            :hideDetails="true"
                            :placeholder="$t('labels.smsVerificationCode')"
                          />
                          <v-col
                            :cols="computedCols"
                            class="d-flex justify-center items-center pl-2"
                          >
                            <v-btn
                              style="
                                color: #fff;
                                text-transform: unset !important;
                                background-color: #0077d5;
                                width: 40px;
                                height: 40px;
                                min-width: 0px;
                                border-radius: 100% !important;
                              "
                              elevation="0"
                              rounded
                              class="darken-1"
                              :disabled="time !== 0"
                              :loading="loadings.refresh"
                              type="button"
                              @click="refreshVerificationCode()"
                            >
                              <v-icon dark> mdi-refresh </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            lg="12"
                            class="d-flex justify-center items-center align-center"
                          >
                            <v-btn
                              style="
                                color: #fff;
                                text-transform: unset !important;
                                background-color: #0077d5;
                                padding: 2vh 4vh;
                                border-radius: 30px !important;
                                font-weight: bolder;
                              "
                              elevation="0"
                              class="darken-1"
                              :disabled="verificationCode === '' || time === 0"
                              type="submit"
                              :loading="pageLoading"
                            >
                              {{ $t('buttons.login') }}
                            </v-btn>
                          </v-col>

                          <p
                            v-if="remainingLogin > 0"
                            class="font-weight-black"
                            style="color: #0177d4 !important"
                          >
                            {{ remainingLogin }}
                            {{ $t('labels.userRemainingLogin') }}
                          </p>

                          <p
                            v-if="remainingLogin == 0"
                            class="font-weight-black"
                            style="color: #0177d4 !important"
                          >
                            {{ $t('labels.userBlocked') }}
                          </p>
                        </v-row>
                      </form>
                    </ValidationObserver>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
          <!-- SMS form end -->
        </v-container>
      </v-layout>
    </div>
  </div>
</template>
<script>
import AccountService from '../services/AccountService';
import { mapActions } from 'vuex';
import NavMenu from '../components/NavMenu.vue';
import FormElement from '../components/FormElement.vue';
import { clearText } from '../utils/constants';
import rules from '../utils/validationRules';

export default {
  data() {
    return {
      // Arrays
      genderItems: [],
      platforms: [
        {
          Id: 1,
          Name: 'Google',
        },
        {
          Id: 2,
          Name: 'Instagram',
        },
        {
          Id: 3,
          Name: 'LinkedIn',
        },
        {
          Id: 4,
          Name: 'Youtube',
        },
        {
          Id: 5,
          Name: 'Facebook',
        },
        {
          Id: 6,
          Name: this.$t('labels.exhibition'),
        },
        {
          Id: 7,
          Name: this.$t('labels.reference'),
        },
        {
          Id: 8,
          Name: this.$t('labels.None'),
        },
      ],

      // Register Model
      item: {
        firstName: '',
        lastName: '',
        email: '',
        selectedGender: 'Bay',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        temporaryPhone: '00',
        companyName: '',
        howDidYouHearAboutUs: 8,
      },

      // States
      passWordInfoDialog: false,
      showPassword: false,
      showConfirmPassword: false,
      pageLoading: false,
      ForgotPassword: false,
      forgotPassword: false,
      smsSended: false,
      isLogin: false,
      isRegister: true,

      // Login
      username: '',
      verificationCode: '',
      time: 180,
      smsTimer: null,
      userId: '',

      bayiEmail: '',
      loadings: {
        refresh: false,
        editedButton: false,
      },
      forgot: {
        email: '',
        id: '',
      },
      remainingLogin: null,
      operation: 'login',
      termsAndConditionsAccepted: false,
      promotionalTextAccepted: false,
      contactPermissionAccepted: false,
    };
  },
  components: { NavMenu, FormElement },
  mounted() {
    this.operation = this.$attrs.operation;
    this.setup();
  },
  computed: {
    computedCols() {
      const { xl, lg, md, sm, xs } = this.$vuetify.breakpoint;
      if (xl) return 1;
      if (lg) return 1;
      if (md) return 2;
      if (sm) return 2;
      if (xs) return 2;
      return 2;
    },
    formItems() {
      return [
        {
          key: {
            parent: 'item',
            child: 'selectedGender',
          },
          rules: 'required',
          validationLabel: 'labels.appeal',
          formLabel: 'labels.appeal',
          formType: {
            name: 'select',
            items: {
              data: this.genderItems,
              value: 'Name',
              text: 'Name',
            },
          },
          lg: 2,
          placeholder: 'labels.appeal',
        },
        {
          key: {
            parent: 'item',
            child: 'firstName',
          },
          rules: {
            required: true,
            min: 3,
            onlyStringText: true,
          },
          validationLabel: 'labels.name',
          formLabel: 'labels.name',
          formType: {
            name: 'text',
          },
          lg: 5,
          placeholder: 'labels.name',
        },
        {
          key: {
            parent: 'item',
            child: 'lastName',
          },
          rules: {
            required: true,
            min: 2,
            onlyStringText: true,
          },
          validationLabel: 'labels.user.lastName',
          formLabel: 'labels.user.lastName',
          formType: {
            name: 'text',
          },
          lg: 5,
          placeholder: 'labels.user.lastName',
        },
        {
          key: {
            parent: 'item',
            child: 'email',
          },
          rules: 'required|email',
          validationLabel: 'labels.userEmail',
          formLabel: 'labels.userEmail',
          formType: {
            name: 'text',
          },
          lg: 7,
          placeholder: 'labels.userEmail',
        },
        {
          key: {
            parent: 'item',
            child: 'phoneNumber',
          },
          rules: rules.phone,
          validationLabel: 'labels.userPhone',
          formLabel: 'labels.userPhone',
          formType: {
            name: 'text',
            label: this.$t('labels.userPhone')
          },
          lg: 5,
          cols: 12,
          placeholder: 'labels.userPhonePlaceHolder',
        },
        {
          key: {
            parent: 'item',
            child: 'companyName',
          },
          rules: {
            required: true,
          },
          validationLabel: 'labels.companyName',
          formLabel: 'labels.companyName',
          formType: {
            name: 'text',
            autocomplete: 'new-password',
          },
          lg: 12,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'item',
            child: 'password',
          },
          rules: 'required|max:24',
          rules: {
            required: true,
            max: 24,
            min: 6,
            regex:
              /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
          },
          validationLabel: 'labels.password',
          formLabel: 'labels.password',
          formType: {
            name: this.showPassword ? 'seePassword' : 'password',
            counter: '24',
            appendIcon: this.showPassword ? 'mdi-eye' : 'mdi-eye-off',
            appendClick: () => {
              this.showPassword = !this.showPassword;
            },
            autocomplete: 'new-password',
          },
          lg: 6,
          hideDetails: true,
          placeholder: 'labels.password',
        },
        {
          key: {
            parent: 'item',
            child: 'confirmPassword',
          },
          rules:
            'required|max:24|confirmedPassword:' + this.$t('labels.password'),
          validationLabel: 'labels.userPasswordAgain',
          formLabel: 'labels.userPasswordAgain',
          formType: {
            name: this.showConfirmPassword ? 'text' : 're-password',
            appendIcon: this.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',
            appendClick: () => {
              this.showConfirmPassword = !this.showConfirmPassword;
            },
          },
          lg: 6,
          placeholder: 'labels.userPasswordAgain',
        },
        {
          key: {
            parent: 'item',
            child: 'howDidYouHearAboutUs',
          },
          rules: '',
          validationLabel: 'labels.howDidYouHearAboutUs',
          formLabel: 'labels.howDidYouHearAboutUs',
          formType: {
            name: 'select',
            items: {
              data: this.platforms,
              value: 'Id',
              text: 'Name',
            },
          },
          hideDetails: true,
          placeholder: 'labels.howDidYouHearAboutUs',
        },
      ];
    },
  },
  watch: {
    operation() {
      this.resetForm();
    },
  },
  methods: {
    setup() {
      this.genderItems = [
        {
          Name: 'Bay',
          Id: 0,
        },
        {
          Name: 'Bayan',
          Id: 1,
        },
      ];
    },

    /**
     * Resetting forms' validation.
     */
    async resetForm() {
      this.name = '';
      this.$refs.observer.reset();
      this.setup();
    },

    /**
     * If user does not get any SMS code. Refresh it.
     */
    refreshVerificationCode() {
      if (this.time === 0) {
        this.renderSmsPage(true);
      }
    },

    /**
     * Create a login request to get a SMS Verification Code.
     */
    sendLogin() {
      this.pageLoading = true;
      this.$store.state.loadingStatus = true;

      let data = {
        Email: this.username,
        Password: this.item.password,
      };

      AccountService.login(data)
        .then((res) => {
          if (res.data.EnableTwoFa) {
            const data = res.data;
            if (data) {
              this.temporaryPhone = data.Phone;
              this.userId = data.UserId;
              this.pageLoading = false;
              this.$store.state.loadingStatus = false;
              this.renderSmsPage();
            }
          } else {
            console.log('RES DATA >> ', res.data);
            let loginWithoutSMSBody = {
              onComplete: () => {
                this.$store.state.Id = res.data.UserId;
                if (res.data.CompanyId) {
                  this.$router.push({ name: 'home' });
                } else {
                  this.$router.push({ name: 'addCompany' });
                }
              },
              userInfo: res.data,
              userToken: {
                access: res.data.Token,
                refresh: res.data.Token,
              },
              userType:
                res.data.Roles.length > 0 ? res.data.Roles[0] : 'Unassigned',
            };
            this.loginWithoutSMS(loginWithoutSMSBody);
          }
        })
        .catch((err) => {
          window.showError(err);
          this.pageLoading = false;
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Registering User
     */
    register() {
      this.pageLoading = true;
      this.$store.state.loadingStatus = true;

      let body = {
        Name: this.item.firstName,
        Surname: this.item.lastName,
        Email: this.item.email,
        CompanyName: this.item.companyName,
        Gender: this.item.selectedGender,
        PhoneNumber: clearText(this.item.phoneNumber),
        Password: this.item.password,
        WhereDidYouHeard: this.item.howDidYouHearAboutUs,
        ContactPermission: this.contactPermissionAccepted,
        IsChangePassword: true,
      };

      AccountService.register(body)
        .then((response) => {
          window.showSuccess('Başarılı bir şekilde kayıt oldunuz.');
          this.resetAllModels();
          this.operation = 'login';

          this.$store.state.loadingStatus = false;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.pageLoading = false;
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      // Register Model
      this.item = {
        firstName: '',
        lastName: '',
        email: '',
        selectedGender: 'Bay',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        temporaryPhone: '00',
        howDidYouHearAboutUs: null,
      };

      this.genderItems = [];
      this.platform = [];
      this.passWordInfoDialog = false;
      this.showPassword = false;
      this.showConfirmPassword = false;
      // Login
      this.username = '';
      this.verificationCode = '';
      this.time = 180;
      this.smsTimer = null;
      this.userId = '';
      this.pageLoading = false;
      this.ForgotPassword = false;
      this.bayiEmail = '';
      this.loadings = {
        refresh: false,
        editedButton: false,
      };
      this.forgot = {
        email: '',
        id: '',
      };
      this.remainingLogin = null;
      this.forgotPassword = false;

      this.$refs.observer.reset();
    },

    /**
     * Finish Login when the response returns successfully from SMS Verification Code
     */
    completeLogin() {
      this.pageLoading = true;
      this.$store.state.loadingStatus = true;

      let body = {
        UserId: this.userId,
        Code: this.verificationCode,
      };

      this.login(body)
        .then((response) => {
          const data = response.data;
          if (data) {
            this.$store.state.Id = this.userId;
            if (data.Result.CompanyId) {
              this.$router.push({ name: 'home' });
            } else {
              this.$router.push({ name: 'addCompany' });
            }
          }
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.pageLoading = false;
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Render SMS Verification Code Page
     */
    renderSmsPage(isRepeat = false) {
      if (this.userId) {
        if (isRepeat) {
          this.$store.state.loadingStatus = true;
          this.loadings.refresh = true;
          let body = {
            UserId: this.userId,
          };

          AccountService.resendTwoFA(body)
            .then((response) => {
              this.loadings.refresh = false;
              this.$store.state.loadingStatus = false;
              window.showSuccess('Doğrulama kodunuz tekrar gönderilmiştir.');
              this.startTimer();
            })
            .catch((error) => {
              this.$store.state.loadingStatus = false;

              this.loadings.refresh = false;
            });
        } else {
          this.startTimer();
        }
      }
    },

    /**
     * Start the timer.
     */
    startTimer() {
      this.time = 180;
      clearInterval(this.smsTimer);

      this.operation = 'sms';
      this.smsTimer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.smsTimer);
        }
      }, 1000);
    },

    /**
     * Sends a reset password link to user's email address.
     */
    sendResetPasswordLink() {
      let body = {
        Email: this.forgot.email,
      };

      this.loadings.editedButton = true;
      this.$store.state.loadingStatus = true;

      AccountService.forgotPassword(body)
        .then((res) => {
          window.showSuccess(this.$t('messages.forgotPasswordSuccess'));
          this.dialogForgotPassword = true;
          this.forgot.email = '';
          this.resetAllModels();
        })
        .catch((err) =>
          this.onServiceError(err, (error) => {
            window.showError(error);
          })
        )
        .finally(() => {
          this.loadings.editedButton = false;
          this.$store.state.loadingStatus = false;
        });
    },

    ...mapActions(['login', 'loginWithoutSMS']),
  },
};
</script>
<style>
.wrapper {
  background: url('../images/3.jpg') !important;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.text-field-label {
  color: #45b9be;
  margin-bottom: 2vh;
  font-weight: 500;
}

.loginPage {
  background: url('../images/2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.registerPage {
  padding-top: 3rem;
}

.forgotPasswordPage {
  background: url('../images/5.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
</style>
