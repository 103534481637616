<template>
  <v-col cols="12" class="my-4">
    <v-layout wrap align-center justify-center fill-height>
      <v-card style="border-radius: 20px" max-width="100%" min-width="100%">
        <v-row>
          <v-col style="padding: 0 !important;" v-if="creditCardUrl" md="12" xs="12">
            <iframe
              style="width:  100%; height: 350px; border: none;"
              :src="creditCardUrl"
            ></iframe>
          </v-col>
          <v-col style="padding: 0 !important;" v-else md="12" xs="12">
            <div>
              <div
                style="color: #FB3D6B; font-weight: 700; font-size: 24px; margin-bottom: 22px;"
              >
                {{ $t("titles.creditCard") }}
              </div>
              <ValidationObserver
                ref="observer"
                v-slot="{ invalid, handleSubmit }"
              >
                <form @submit.prevent="handleSubmit(startPayment)">
                  <v-row class="mt-10 justify-center">
                    <FormElement
                      v-for="(element, index, key) in itemForm"
                      :key="element.key.parent + '_' + element.key.child"
                      :vertical="element.vertical"
                      v-model="$data[element.key.parent][element.key.child]"
                      :validationLabel="$t(element.validationLabel)"
                      :rules="element.rules"
                      :formLabel="$t(element.formLabel)"
                      :onChange="element.onChange"
                      :md="element.md"
                      :formType="element.formType"
                      :placeholder="$t(element.placeholder)"
                    />
                    <v-col
                      v-if="showPaymentButton"
                      cols="12"
                      sm="6"
                      xl="12"
                      style="padding: 0;"
                      class="mb-4 d-xl-flex justify-xl-center"
                    >
                      <v-btn
                        style="width: 100%;color: #FFF;"
                        elevation="0"
                        class="darken-1 small-button add"
                        type="submit"
                        :loading="loading"
                        :disabled="loading"
                      >
                        {{ $t("buttons.complete", { value: "Ödemeyi" }) }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </form>
              </ValidationObserver>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-layout>
  </v-col>
</template>
<script>
import FormElement from "./FormElement.vue";

export default {
  data() {
    return {
      loading: false,
      isPaymentStarted: false,
      item: {},
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      creditCardUrl: "",
    };
  },
  props: {
    isVerified: false,
    data: {},
    showPaymentButton: {
      type: Boolean,
      default: () => true,
    },
  },
  mounted() {},
  components: {
    FormElement,
  },
  computed: {
    years() {
      const lastYear = new Date().getFullYear();
      let temporaryYears = [];
      for (let index = lastYear; index < lastYear + 10; index++) {
        temporaryYears.push(index);
      }

      return temporaryYears;
    },
    itemForm() {
      return [
        {
          key: {
            parent: "item",
            child: "CreditCardNumber",
          },
          md: 5,
          rules: {
            required: true,
            numeric: true,
            max: 16,
            regex: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
          },
          validationLabel: "labels.creditCard.number",
          formLabel: "labels.creditCard.number",
          formType: {
            name: "text",
          },
          placeholder: "labels.creditCard.number",
        },
        {
          key: {
            parent: "item",
            child: "CreditCardMonth",
          },
          rules: "required",
          validationLabel: "labels.creditCard.month",
          formLabel: "labels.creditCard.month",
          md: 2,
          formType: {
            name: "select",
            items: {
              data: this.months,
              value: null,
              text: null,
            },
          },
          placeholder: "labels.creditCard.month",
        },
        {
          key: {
            parent: "item",
            child: "CreditCardYear",
          },
          rules: "required",
          validationLabel: "labels.creditCard.year",
          formLabel: "labels.creditCard.year",
          md: 2,
          formType: {
            name: "select",
            items: {
              data: this.years,
              value: null,
              text: null,
            },
          },
          placeholder: "labels.creditCard.year",
        },
        {
          key: {
            parent: "item",
            child: "CreditCardCCV",
          },
          rules: {
            required: true,
            numeric: true,
            min: 2,
            max: 3,
          },
          md: 3,
          validationLabel: "labels.creditCard.ccv",
          formLabel: "labels.creditCard.ccv",
          formType: {
            name: "text",
          },
          placeholder: "labels.creditCard.ccv",
        },
      ];
    },
  },
  methods: {
    /**
     * After your verifying. You can change password.
     */
    startPayment() {
      this.loading = true;
      this.isPaymentStarted = true;
      this.$store.state.globalOperation = true;

      new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, 2000);
      })
        .then((response) => {
          this.creditCardUrl = "http://localhost:8080/payment-completed";
        })
        .catch((error) => {
          window.showError(error);
          this.isPaymentStarted = false;
        })
        .finally((response) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.text-field-label {
  color: #45b9be;
  margin-bottom: 2vh;
  font-weight: 500;
}
</style>
