import axios from '../javascript/custom-axios'
const RESOURCE_NAME = 'marketplace/photo'

export default {
  
  addProductPhotoToMarketplace(body){
    return axios.post(`${RESOURCE_NAME}/add`,body)
 
  },
  updateProductPhotoToMarketplace(body){
    return axios.put(`${RESOURCE_NAME}/update`,body)
 
  }
}