// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'company-hazmat-price';

export default {
  getHazmatCodes() {
    return axios.get(`${RESOURCE_NAME}/get`);
  },
  addHazmatCode(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },
  updateHazmatCodes(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },
};
