import { get, update } from 'lodash';
import axios from '../javascript/custom-axios';

const RESOURCE_NAME = '/glossary-of-terms';

export default {
  getGlossaryOfTerms(page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get`, {
      params: {
        Page: page,
        PageSize: pageSize,
      },
    });
  },

  addTerm(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },

  updateTerm(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  deleteTerm(id) {
    return axios.delete(`${RESOURCE_NAME}/delete`, { params: { Id: id } });
  },

  search(searchText, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/getBySearch`, {
      params: {
        SearchText: searchText,
        page: page,
        pageSize: pageSize,
      },
    });
  },
};
