import axios from '../javascript/custom-axios'
const RESOURCE_NAME = 'excel'

export default {
  
  bulkImport(body){
    return axios.post(`${RESOURCE_NAME}/importProducts`,body)
 
  },
  bulkExport(body){
    return axios.post(`${RESOURCE_NAME}/export`,body)
 
  },
  downloadImportExampleFile(body,fileName){
    return axios.post(`file/download`,body, { responseType: 'blob' })
    .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`)
        link.click()
      })  
  },
  getExcelFiles(pageSize,page) {
    return axios.get(`${RESOURCE_NAME}/getExports`, {
      params: { page:page,pageSize:pageSize }
    });
  },
}