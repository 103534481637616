<template>
  <div>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="item in items" :key="item.Id">
          <v-expansion-panel-header @click="getAnswers(item.Id)">
            <v-row>
              <v-col class="popupHeadline" cols="12">
                <h5>{{ item.Question }}</h5>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="start" class="px-sm-5 d-flex justify-center">
              <v-col cols="12" sm="9" v-if="answers.length > 0">
                <div style="">
                  <v-carousel progress>
                    <v-carousel-item
                      v-for="answer in answers"
                      :key="answer.Index"
                    >
                      <div
                        class="d-flex justify-space-around align-center py-4"
                      >
                        {{ answer.Text }}
                      </div>
                      <v-img
                        :src="answer.FileUrl"
                        class="bg-grey-lighten-2 pb-24"
                        width="100%"
                        height="100%"
                        contain
                      ></v-img>
                    </v-carousel-item>
                  </v-carousel>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </div>
</template>

<script>
import UserGuideService from '../services/UserGuideService';

export default {
  data: () => ({
    items: [],
    model: 1,
    answers: [],
  }),
  watch: {},
  mounted() {
    this.getQuestions();
  },
  methods: {
    getQuestions() {
      this.$store.state.loadingStatus = true;
      UserGuideService.getAll()
        .then((res) => {
          this.items = res.data.Result;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getAnswers(id) {
      this.$store.state.loadingStatus = true;
      UserGuideService.getAnswers(id)
        .then((res) => {
          this.answers = res.data.Result;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>

<style>
.bg-active {
  background-color: #192e59;
}
</style>
