<template>
  <div>
    <!-- SUBMIT ADDRESS FORM MODAL -->
    <v-dialog
      v-model="submitAddressFormModal"
      max-width="600"
    >
    <v-card class="popup-card" elevation="1">
      <v-card-title
        class="popupHeadline d-flex justify-center"
        style="font-size: 16px"
      >
        <h3>{{ $t( `labels.${editAddressMode ? 'editSubCompany' : 'addSubCompany'}`) }}</h3>
      </v-card-title>
      <v-divider class="mb-4" />
      <ValidationObserver v-slot="{ handleSubmit }" ref="addressFormObserver">
        <v-card-text class="">
          <form @submit.prevent="handleSubmit(submitAddressForm)">
            <v-row>
              <FormElement
                v-for="(element, index) in addressFormItems"
                :key="
                  element.key.parent +
                  '_' +
                  element.key.child +
                  '_' +
                  index
                "
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :formLabel="$t(element.formLabel)"
                :onChange="element.onChange"
                :formType="element.formType"
                :lg="element.lg"
                :cols="element.cols"
                :hidden="element.hidden"
                :labelShown="element.labelShown"
                :topLabel="element.topLabel"
                :active="element.active"
                :placeholder="$t(element.placeholder)"
              />
            </v-row>
            <v-row class="mb-4">
              <v-col
                cols="12"
                class="d-flex flex-row px-2"
                style="padding: 0"
              >
                <v-checkbox
                  style="padding: 0; margin: 0"
                  hide-details
                  v-model="addressForm.IsHomeAddress"
                ></v-checkbox>
                <label> {{ $t('labels.isHome') }} </label>
              </v-col>
            </v-row>
            <v-card-actions class="text-center justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
              >
                {{ $t('buttons.save') }}
              </v-btn>
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-2"
                @click="submitAddressFormModal = false"
              >
                {{ $t('buttons.close') }}
              </v-btn>
            </v-card-actions>
          </form>
        </v-card-text>
      </ValidationObserver>
    </v-card>
  </v-dialog>
    <!-- SUBMIT ADDRESS FORM MODAL END -->
     
    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="$t('messages.addVolumeWarning')"
      :confirm="confirmation.onConfirm"
      v-model="confirmation.visible"
    />
    <v-dialog
      v-model="addOrderModal"
      max-width="1200"
    >
    <v-stepper v-model="stepper" alt-labels>
      <div class="d-flex justify-space-between" style="padding: 10px;  background-color: #fff !important;">
        <div class="popupHeadline" style="font-weight: bold;">
          {{ $t('buttons.createOrder') }}
        </div>
        <v-btn
          elevation="0"
          type="button"
          @click="addOrderModal = false"
          fab
          style="width: 20px; height: 20px; padding: 14px;"
          dark
          color="red"
        >
          <v-icon dark>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-stepper-header>
        <v-stepper-step
          step="1"
        >
          {{ $t('titles.quickSearch') }}
        </v-stepper-step>
  
        <v-divider></v-divider>
  
        <v-stepper-step
          step="2"
        >
          {{ $t('labels.results') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="3"
        >
          {{ $t('buttons.createOrder') }}
        </v-stepper-step>
      </v-stepper-header>
  
      <v-stepper-items>
        <v-stepper-content step="1">
          <ValidationObserver v-if="searching" ref="addOrderObserver" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(searchOffer)"
            >
            <v-card class="popup-card" elevation="1">
              <v-card-title v-if="false" class="popupHeadline" style="font-size: 24px">
                <div class="text-center popupHeadline" style="width: 100%">
                  {{ $t('buttons.add') }}
                </div>
              </v-card-title>
              <v-divider class="mb-4" />
                  <v-card-text>
                    <v-row class="mb-4">
                      <fragment :key="element.key.parent + '_' + element.key.child + '_' + index" v-for="(element, index) in formItems">
                        <FormElement
                          v-if="!element.onClick"
                          
                          v-model="$data[element.key.parent][element.key.child]"
                          :validationLabel="$t(element.validationLabel)"
                          :rules="element.rules"
                          :formLabel="$t(element.formLabel)"
                          :onChange="element.onChange"
                          :formType="element.formType"
                          :lg="element.lg"
                          :cols="element.cols"
                          :sm="element.sm"
                          :disabled="element.disabled"
                          :hidden="element.hidden"
                          :labelShown="element.labelShown"
                          :active="element.active"
                          :topLabel="element.topLabel"
                          :placeholder="$t(element.placeholder)"
                        />
                        <v-col
                          v-else
                          :cols="2"
                          :sm="2"
                          :md="1"
                          :lg="1"
                          :xl="1"
                          style="padding: 0"
                        >
                          <v-btn
                            :disabled="element.disabled"
                            class="rounded-lg"
                            @click="element.onClick"
                            style="
                              width: 95%;
                              height: 65%;
                              min-width: 0px;
                              min-height: 0px;
                              background-color: #235c9e;
                              color: #ffffff;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                            <v-icon> {{ element.icon }} </v-icon>
                          </v-btn>
                        </v-col>
                      </fragment>
                        <FormElement
                          v-for="(element, index) in formContainerDetails"
                          :key="
                            element.key.parent + '_' + element.key.child + '_' + index
                          "
                          v-model="$data[element.key.parent][element.key.child]"
                          :validationLabel="$t(element.validationLabel)"
                          :rules="element.rules"
                          :formLabel="$t(element.formLabel)"
                          :onChange="element.onChange"
                          :formType="element.formType"
                          :lg="element.lg"
                          :cols="element.cols"
                          :disabled="element.disabled"
                          :hidden="element.hidden"
                          :labelShown="element.labelShown"
                          :active="element.active"
                          :topLabel="element.topLabel"
                          :placeholder="$t(element.placeholder)"
                        />
                    </v-row>
                    <AddVolume
                      v-if="addOrderModal"
                      isFirstSearch
                      :onSave="onSaveVolumes"
                      :calculateParameter="calculateParameter"
                      :calculateChargeableWeight="calculateChargeableWeight"
                      :isCard="true"
                      key="ADD_ORDER_VOLUME_CARD"
                    />
                  </v-card-text>
                  <v-card-actions v-if="searching" class="justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: green;
      
                        padding: 1vh 2.5vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      :loading="$store.state.loadingStatus"
                      >
                      <v-icon dark>
                        mdi-magnify
                      </v-icon>
                      {{ $t('buttons.searchPrice') }}</v-btn
                    >
                  </v-card-actions>
                  <v-card-actions v-else class="justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;
      
                        padding: 1vh 1vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      :loading="$store.state.loadingStatus"
                      >{{ $t('buttons.save') }}</v-btn
                    >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: var(--red);
                        padding: 1vh 1vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      @click="addOrderModal = false"
                      >{{ $t('buttons.cancel') }}</v-btn
                    >
                  </v-card-actions>
            </v-card>
          </form>
          </ValidationObserver>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="popup-card" elevation="1">
            <v-card-text>
              <v-row class="mt-5" justify="center" v-if="searchResults.length > 0">
                <FormElement
                    key="FORM_ELEMENT_SEARCH_RESULTS"
                    v-model="selectedSearchResult"
                    :validationLabel="$t('labels.results')"
                    rules="required"
                    :formLabel="$t('labels.results')"
                    :formType="{
                      name: 'multipleSelect',
                      items: {
                        data: searchResults,
                        text: item => `${item.ListName} - ${item.Supplier} - ${item.Price} ${item.PriceCurrency}`,
                        value: 'ListId',
                      },
                      returnObject: true
                    }"
                    :lg="6"
                    :cols="6"
                    :labelShown="true"
                    :topLabel="true"
                    :placeholder="$t('labels.results')"
                  />
              </v-row>
            </v-card-text>
            <v-card-actions v-if="searching" class="justify-center">
              <v-btn
              style="
                color: #fff;
                text-transform: unset !important;
                background-color: #0077d5;

                padding: 1vh 2.5vh;
                border-radius: 30px !important;
                font-weight: bolder;
                font-size: medium;
              "
              elevation="0"
              class="darken-1"
              type="button"
              @click="stepper = 1"
              >
              <v-icon dark>
                mdi-arrow-left
              </v-icon>
              {{ $t('buttons.back') }}
             </v-btn
            >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;

                  padding: 1vh 2.5vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="button"
                @click="stepper = 3"
                :disabled="!selectedSearchResult"
                :loading="$store.state.loadingStatus"
                >
                {{ $t('buttons.next') }}
                <v-icon dark>
                  mdi-arrow-right
                </v-icon></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>
  
        <v-stepper-content step="3">
          <ValidationObserver ref="createOrderFormObserver" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(createOrder)"
            >
            <v-card class="popup-card" elevation="1">
              <v-card-title class="popupHeadline" style="font-size: 24px">
                <div class="text-center popupHeadline" style="width: 100%">
                  {{ $t('buttons.createOrder') }}
                </div>
              </v-card-title>
              <v-divider class="mb-4" />
                  <v-card-text>
                    <v-row>
                      <FormElement
                          v-for="(element, index) in createOrderFormItems"
                          :key="
                            element.key.parent + '_' + element.key.child + '_' + index
                          "
                          v-model="$data[element.key.parent][element.key.child]"
                          :validationLabel="$t(element.validationLabel)"
                          :rules="element.rules"
                          :formLabel="$t(element.formLabel)"
                          :onChange="element.onChange"
                          :formType="element.formType"
                          :lg="element.lg"
                          :cols="element.cols"
                          :disabled="element.disabled"
                          :hidden="element.hidden"
                          :labelShown="element.labelShown"
                          :active="element.active"
                          :topLabel="element.topLabel"
                          :placeholder="$t(element.placeholder)"
                          :classes="element.classes"
                        />
                      </v-row>
                  </v-card-text>
                  <v-card-actions v-if="searching" class="justify-center">
                    <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: #0077d5;
    
                      padding: 1vh 2.5vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    type="button"
                    @click="stepper = 2"
                    >
                    <v-icon dark>
                      mdi-arrow-left
                    </v-icon>
                    {{ $t('buttons.back') }}
                   </v-btn
                  >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: green;
      
                        padding: 1vh 2.5vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      :loading="$store.state.loadingStatus"
                      >
                      {{ $t('buttons.createOrder') }}</v-btn
                    >
                  </v-card-actions>
                  <v-card-actions v-else class="justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;
      
                        padding: 1vh 1vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      :loading="$store.state.loadingStatus"
                      >{{ $t('buttons.save') }}</v-btn
                    >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: var(--red);
                        padding: 1vh 1vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      @click="addOrderModal = false"
                      >{{ $t('buttons.cancel') }}</v-btn
                    >
                  </v-card-actions>
            </v-card>
          </form>
          </ValidationObserver>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    </v-dialog>
    <!-- Page content -->
    <v-card elevation="1">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row justify="space-between">
            <v-col
              class="justify-start justify-lg-end d-flex align-center"
              cols="6"
              sm="4"
              md="5"
              lg="6"
              xl="6"
            >
              <h3>{{ $t('labels.orders') }}</h3>
            </v-col>

            <v-col
              v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
              cols="6"
              class="py-1 py-lg-0 px-1 d-flex justify-end items-center align-center"
            >
              <RefreshButton :refreshFunction="getTableList"></RefreshButton>
            </v-col>
            <v-col
              class="d-flex justify-end items-center align-center pl-0 px-0 pt-0 pt-sm-2"
              cols="12"
              sm="12"
              md="7"
              lg="5"
              xl="4"
            >
              <v-col
                v-if="endDateFormat || startDateFormat"
                :cols="6"
                :sm="6"
                :md="5"
                :lg="5"
                :xl="5"
                class="py-1 py-lg-0 px-1"
              >
                <v-btn
                  variant="tonal"
                  color="#0177d4"
                  class="button d-flex"
                  style="
                    color: white;
                    border-radius: 14px !important;
                    width: 98% !important;
                  "
                  @click="dateReset"
                >
                  {{ $t('buttons.resetDates') }}
                </v-btn>
              </v-col>
              <v-col
                v-if="
                  selectedOrderStatus ||
                  selectedShipmentType ||
                  orderFrom ||
                  orderTo
                "
                :cols="6"
                :sm="6"
                :md="5"
                :lg="5"
                :xl="5"
                class="py-1 py-lg-0 px-1 pr-0 pr-sm-2"
              >
                <v-btn
                  variant="tonal"
                  color="#0177d4"
                  class="button"
                  style="
                    color: white;
                    border-radius: 14px !important;
                    width: 98% !important;
                  "
                  @click="resetFilter"
                >
                  {{ $t('buttons.clearFilter') }}
                </v-btn>
              </v-col>
              <v-col
                v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                :cols="1"
                :sm="1"
                :md="1"
                :lg="1"
                :xl="1"
                class="d-flex justify-end items-center align-center"
              >
                <v-tooltip bottom v-if="!isSupplier">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-3"
                      style="font-size: 32px"
                      v-bind="attrs"
                      v-on="on"
                      @click="addOrderModal = true"
                    >
                      mdi-plus-box-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.add') }}</span>
                </v-tooltip>
                <RefreshButton :refreshFunction="getTableList"></RefreshButton>
              </v-col>
            </v-col>
          </v-row>
        </v-card-title>
      </div>

      <v-divider />

      <v-card-text fill-height fluid>
        <v-row v-if="!isAdmin" align="center" justify="start" class="d-flex">
          <v-col cols="12" class="align-center">
            <v-row class="align-center pt-3">
              <FormElement
                v-model="trackingNo"
                :validationLabel="$t('titles.integration.trackingNumber')"
                :formLabel="$t('titles.integration.trackingNumber')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('titles.integration.trackingNumber')"
                :classes="'mb-1 mb-sm-0'"
              />
              <FormElement
                v-model="search"
                :validationLabel="$t('labels.companyName')"
                :formLabel="$t('labels.companyName')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.companyName')"
                :classes="'mb-1 mb-sm-0'"
              />

              <v-col
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
                class="py-1 py-lg-0 px-1"
              >
                <v-select
                  class="custom-border"
                  v-model="selectedShipmentType"
                  :value="selectedShipmentType"
                  :items="shipmentTypes"
                  :label="$t('labels.shipmentType')"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                  @change="filterContent()"
                >
                </v-select>
              </v-col>
              <v-col
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
                class="py-1 py-lg-0 px-1"
              >
                <!-- <v-text-field
                  clearable
                  variant="outlined"
                  v-model="orderFrom"
                  :value="orderFrom"
                  :label="$t('labels.orderFrom')"
                  :append-icon="orderFrom ? 'mdi-arrow-right-bold' : null"
                  @click:append="filterContent"
                  @keydown.enter.prevent="filterContent"
                ></v-text-field> -->
                <v-autocomplete
                  class="custom-border"
                  v-model="orderFrom"
                  :value="orderFrom"
                  :items="
                    countryItems.concat({ Name: $t('labels.All'), Value: '' })
                  "
                  :label="$t('titles.from')"
                  item-text="Name"
                  item-value="Value"
                  @change="filterContent()"
                  clearable
                  dense
                  hide-details
                  variant="solo"
                  outlined
                >
                </v-autocomplete>
              </v-col>
              <v-col
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
                class="py-1 py-lg-0 px-1"
              >
                <!-- <v-text-field
                  clearable
                  variant="outlined"
                  v-model="orderTo"
                  :value="orderTo"
                  :label="$t('labels.orderTo')"
                  :append-icon="orderTo ? 'mdi-arrow-right-bold' : null"
                  @click:append="filterContent"
                  @keydown.enter.prevent="filterContent"
                ></v-text-field> -->
                <v-autocomplete
                  class="custom-border"
                  v-model="orderTo"
                  :value="orderTo"
                  :items="
                    countryItems.concat({ Name: $t('labels.All'), Value: '' })
                  "
                  :label="$t('titles.to')"
                  item-text="Name"
                  item-value="Value"
                  @change="filterContent()"
                  clearable
                  dense
                  hide-details
                  variant="solo"
                  outlined
                >
                </v-autocomplete>
              </v-col>
              <v-col
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
                class="py-1 py-lg-0 px-1"
              >
                <v-select
                  class="custom-border"
                  v-model="selectedOrderStatus"
                  :value="selectedOrderStatus"
                  :items="orderStatuses"
                  :label="$t('labels.orderStatus')"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                  @change="filterContent()"
                >
                </v-select>
              </v-col>
              <v-col
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
                class="py-1 py-lg-0 px-1"
              >
                <v-select
                  class="custom-border"
                  v-model="selectedSupplierOrderStatus"
                  :value="selectedSupplierOrderStatus"
                  :items="supplierOrderStatuses"
                  :label="$t('labels.fileStatus')"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                  @change="filterContent()"
                >
                </v-select>
              </v-col>
              <v-col
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
                class="py-1 py-lg-0"
              >
                <!-- begin start date picker -->
                <v-dialog
                  ref="startDateDialog"
                  v-model="startDateModalDialog"
                  :return-value.sync="dateModal.startDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDateFormat"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :label="$t('labels.startDate')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateModal.startDate"
                    :locale="$i18n.locale"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="startDateModalDialog = false"
                    >
                      {{ $t('buttons.close') }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.startDateDialog.save(dateModal.startDate);
                        filterContent();
                      "
                    >
                      {{ $t('buttons.select') }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <!-- end start date picker -->
              </v-col>
              <v-col
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
                class="py-1 py-lg-0"
              >
                <!-- begin start date picker -->
                <v-dialog
                  ref="endDateDialog"
                  v-model="endDateModalDialog"
                  :return-value.sync="dateModal.endDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDateFormat"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :label="$t('labels.endDate')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateModal.endDate"
                    :locale="$i18n.locale"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="endDateModalDialog = false"
                    >
                      {{ $t('buttons.close') }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.endDateDialog.save(dateModal.endDate);
                        filterContent();
                      "
                    >
                      {{ $t('buttons.select') }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <!-- end start date picker -->
              </v-col>
            </v-row>
          </v-col>

          <v-row class="align-center"> </v-row>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
          ref="orderList"
        >
          <!-- eslint-disable -->

          <template v-slot:item.Customer="{ item }">
            <div
              class="pt-2 d-flex flex-column justify-center items-center align-center"
            >
              <img
                :src="item.CustomerLogo"
                alt="Logo"
                style="width: 60px; height: auto"
              />
              {{ item.Customer }}
            </div>
          </template>
          <template v-slot:item.Carrier="{ item }">
            <div
              class="pt-2 d-flex flex-column justify-center items-center align-center"
            >
              <img
                :src="item.CarrierLogo"
                alt="Logo"
                style="width: 60px; height: auto"
              />
              {{ item.Carrier }}
            </div>
          </template>
          <template class="w-min" v-slot:item.FromToWhere="{ item }">
            <div style="white-space: normal; word-wrap: break-word">
              {{ item.From }}
            </div>
            <span>-</span>
            <div style="white-space: normal; word-wrap: break-word">
              {{ item.To }}
            </div>
          </template>

          <template class="w-min" v-slot:item.CarrierLogo="{ item }">
            <div>
              <img
                :src="item.CarrierLogo"
                alt="Logo"
                style="width: 50px; height: auto"
              />
            </div>
          </template>
          <template v-slot:item.ShipmentType="{ item }">
            <div style="white-space: normal; word-wrap: break-word">
              {{ item.ShipmentType }}
            </div>
            <span>-</span>
            <div style="white-space: normal; word-wrap: break-word">
              {{ item.LoadingType }}
            </div>
          </template>
          <template v-slot:item.CreatedOnUtc="{ item }">
            {{ convertToLocal(item.CreatedOnUtc) }}
          </template>
          <template v-slot:item.OrderType="{ item }">
            {{ orderTypes[item.OrderType] }}
          </template>
          <template v-slot:item.OrderStatus="{ item }">
            <v-chip
              v-if="item.OrderStatus == 0"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.detail') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 1"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.supplier') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 2"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.payment') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 3"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.approved') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 4"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.loaded') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 5"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.shipping') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 6"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.inDestination') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 7"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.delivered') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 8"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.archived') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 9"
              class="ma-2"
              small
              color="#E72929"
              text-color="white"
              style="font-weight: 600"
            >
              {{ $t('labels.labelsOrderStatuses.canceled') }}
            </v-chip>
          </template>
          <template v-slot:item.SupplierOrderStatus="{ item }">
            <div v-if="item.OrderStatus == 9">
              <v-chip
                class="ma-2"
                small
                color="#E72929"
                text-color="white"
                style="font-weight: 600"
              >
                {{ $t('labels.labelsOrderStatuses.canceled') }}
              </v-chip>
            </div>
            <div v-else>
              <v-chip
                v-if="item.SupplierOrderStatus == 0"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.notPlanned') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 1"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.pickupPlanned') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 2"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.inWarehouse') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 3"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.loading') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 4"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.invoiced') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 5"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.atDestination') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 6"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.delivered') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus === null"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.notAvailable') }}
              </v-chip>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="d-flex text-start">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goOrderDetail(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipEdit') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-if="item.LabelStatus && item.OrderStatus != 9"
                    color="#29B7B7"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadTag(item)"
                    style="font-size: 20px"
                  >
                    mdi-barcode
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipDownloadLabel') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.OrderStatus == 2 && item.OrderStatus != 9"
                    small
                    color="#29B7B7"
                    @click="goToPayments()"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1"
                    style="font-size: 20px"
                  >
                    mdi-credit-card
                  </v-icon>
                </template>
                <span>{{ $t('buttons.goPayment') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.OrderStatus == 7 && !item.IsArchive"
                    small
                    color="#29B7B7"
                    @click="toggleArchive(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1"
                    style="font-size: 20px"
                  >
                    mdi-archive-arrow-down
                  </v-icon>
                </template>
                <span>{{ $t('buttons.setArchive') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.IsArchive"
                    small
                    color="#29B7B7"
                    @click="toggleArchive(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1"
                    style="font-size: 20px"
                  >
                    mdi-archive-arrow-up
                  </v-icon>
                </template>
                <span>{{ $t('buttons.unarchive') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="
                      item.OrderStatus == 7 &&
                      (userRole == 'Customer' ||
                        userRole == 'PendingCustomer') &&
                      item.IsRate &&
                      item.OrderStatus != 9
                    "
                    small
                    color="#29B7B7"
                    @click="goRateScreen(item)"
                    v-bind="attrs"
                    v-on="on"
                    style="font-size: 20px"
                  >
                    mdi-star
                  </v-icon>
                </template>
                <span>{{ $t('labels.rate') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="messageToUser(item.Id)"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <span>{{ $t('buttons.sendAMessage') }}</span>
              </v-tooltip>
              <BlockUser
                v-if="isSupplier"
                :customer="{
                  customerId: item.CustomerCompanyId,
                  fullName: item.Customer,
                }"
                :dataTable="true"
              />
            </div>
          </template>
        </v-data-table>

        <div class="text-xs-center pt-2 md4 lg4">
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="page"
                :length="pages"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="rowsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage()"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import OrderService from '../services/OrderService';
import RefreshButton from '../components/RefreshButton.vue';
import MessageServices from '../services/MessageServices';
import BlockUser from '../components/BlockUser.vue';
import FormElement from '../components/FormElement.vue';
import { getCountries, convertVolumeToBoxes, getAirports, getCurrencyTypes, getCities } from '../utils/constants';
import rules from '../utils/validationRules';
import CompanyServices from '../services/CompanyServices';
import AddVolume from '../views/OfferScreens/AddVolume.vue';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import SearchServices from '../services/SearchServices';
import ShipmentService from '../services/ShipmentService';
import AddressService from '../services/AddressService';

const USA_ID = 'ccf580f9-25e0-4aac-9216-6741eeece89e';
const CANADA_ID = '8e1060f5-9433-4b64-9e8f-3da864f7cac6';

const stateRequiredCountries = [USA_ID, CANADA_ID];

export default {
  data: () => ({
    options: {
      itemsPerPage: 15,
    },
    tableData: [],
    headers: [],
    userRole: '',
    top: 15,
    selectedOrderStatus: null,
    selectedSupplierOrderStatus: null,
    selectedShipmentType: null,
    orderFrom: '',
    search: '',
    trackingNo: '',
    orderTo: '',
    dateModal: {
      startDate: '',
      endDate: '',
    },
    startDateModalDialog: false,
    endDateModalDialog: false,
    loadings: {
      table: false,
      select: false,
      send: false,
    },
    countryItems: [],
    orderStatuses: [],
    supplierOrderStatuses: [],
    shipmentTypes: [],
    totalItems: 0,
    debounceTimeout: null,
    rowsPerPage: 15,
    page: 1,
    perPageValues: [
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
    isSupplier: false,
    isAdmin: false,

    orderTypes: ['Manuel', 'Etsy', 'Amazon'],

    // Order
    addOrderModal: false,
    form: {
      ServiceType: 1,
      ExportImportType: 1
    },
    orderForm: {},
    taxPayers: [],
    subCompanies: [],
    calculateParameter: 166.67,
    transferType: 2,
    searching: true,
    searchResults: [],
    selectedSearchResult: null,
    confirmation: {
      onConfirm: () => {},
      visible: false,
    },
    airports: [],
    fastShippingSearchId: null,

    stepper: 1,
    purposeOfShippingItems: [],
    currencies: [],
    packageTypes: [],
    expressServiceTypes: [],

    // Subcompany
    submitAddressFormModal: false,
    addressForm: {},
    cities: [],
    phonePrefix: null,
    editAddressMode: false,
    addressKey: "",
    addresses: {
      LoadingCompany: "",
      BuyerCompany: ""
    }
  }),
  components: {
    RefreshButton,
    BlockUser,
    FormElement,
    AddVolume,
    ConfirmationModal
  },
  async mounted() {
    this.getSubCompanies();

    this.purposeOfShippingItems = this.$root.purposeOfShippingList;
    this.packageTypes = [
      {
        name: this.$t('labels.document'),
        value: 0,
      },
      {
        name: this.$t('labels.package'),
        value: 1,
      },
      {
        name: this.$t('labels.envelope'),
        value: 2,
      },
    ];
    this.expressServiceTypes = [
      {
        name: this.$t('labels.ETGBExpress'),
        value: 0,
      },
      {
        name: this.$t('labels.ETGBEconomy'),
        value: 1,
      },
      {
        name: this.$t('labels.ExitWithDeclaration'),
        value: 2,
      },
      {
        name: this.$t('labels.Economic'),
        value: 3,
      },
      {
        name: this.$t('labels.sample'),
        value: 4,
      },
    ];

    this.taxPayers = [
      {
        name: this.$t('labels.dap'),
        value: 0,
      },
      {
        name: this.$t('labels.ddp'),
        value: 1,
      },
    ];

    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';

    this.isAdmin = this.$store.state.Type === 'Admin';

    this.shipmentTypes = [
      {
        name: this.$t('labels.All'),
        value: null,
      },
      {
        name: this.$t('labels.cargo'),
        value: 1,
      },
      {
        name: this.$t('labels.express'),
        value: 2,
      },
    ];

    this.orderStatuses = [
      {
        name: this.$t('labels.All'),
        value: null,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.detail'),
        value: 0,
      },
      // {
      //   name: this.$t('labels.labelsOrderStatuses.supplier'),
      //   value: 1,
      // },
      // {
      //   name: this.$t('labels.labelsOrderStatuses.payment'),
      //   value: 2,
      // },
      {
        name: this.$t('labels.labelsOrderStatuses.approved'),
        value: 3,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.loaded'),
        value: 4,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.shipping'),
        value: 5,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.inDestination'),
        value: 6,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.delivered'),
        value: 7,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.archived'),
        value: 8,
      },
    ];
    this.supplierOrderStatuses = [
      {
        name: this.$t('labels.fileStatuses.notPlanned'),
        value: 0,
      },
      {
        name: this.$t('labels.fileStatuses.pickupPlanned'),
        value: 1,
      },
      {
        name: this.$t('labels.fileStatuses.inWarehouse'),
        value: 2,
      },
      {
        name: this.$t('labels.fileStatuses.loading'),
        value: 3,
      },
      {
        name: this.$t('labels.fileStatuses.invoiced'),
        value: 4,
      },
      {
        name: this.$t('labels.fileStatuses.atDestination'),
        value: 5,
      },
      {
        name: this.$t('labels.fileStatuses.delivered'),
        value: 6,
      },
    ];
    this.perPageValues.push({
      name: this.$t('labels.All'),
      value: 9999,
    });

    this.userRole = this.$store.getters.getUserType;
    this.setup();
    this.getCountries();
    this.getTableList();

    this.currencies = await getCurrencyTypes();
  },
  watch: {
    submitAddressFormModal(newValue){
      if(!newValue){
        this.addressForm = {};
        this.editAddressMode = false;
        this.$refs.addressFormObserver.reset();
        this.addressKey = "";
      }
    },
    addOrderModal(newValue){
      if(!newValue){
        this.stepper = 1;
        this.form = {
          ServiceType: 1,
          ExportImportType: 1
        }
        this.selectedSearchResult = null;
        this.orderForm = {};
        this.$refs.addOrderObserver.reset();
        this.$refs.createOrderFormObserver.reset();
        this.searchResults = [];
      }
    }
  },
  computed: {
    isStateCodeRequired() {
      if (!this.addressForm.CountryId) return false;

      return (
        stateRequiredCountries.findIndex(
          (element) => element === this.addressForm.CountryId
        ) > -1
      );
    },
    addressFormItems(){
      return [
        {
          key: {
            parent: 'addressForm',
            child: 'Name',
          },
          rules: 'required|max:35',
          validationLabel: 'labels.companyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'Address',
          },
          rules: 'required',
          validationLabel: 'labels.address1',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.address1',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'CountryId',
          },
          rules: 'required',
          validationLabel: 'labels.country',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countryItems,
              value: 'Id',
              text: 'Name',
            },
          },
          onChange: () => {
            this.onChangeCountry();
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.country',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'CityId',
          },
          rules: 'required',
          validationLabel: 'labels.city',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.cities,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.city',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'AuthorizedPerson',
          },
          rules: 'required',
          validationLabel: 'labels.authorizedPerson',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.authorizedPerson',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'EoriCode',
          },
          validationLabel: 'labels.EORICode',
          rules: 'numeric',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.EORICode',
        },

        {
          key: {
            parent: 'addressForm',
            child: 'PostalCode',
          },
          rules: 'required|min:4|max:9',
          validationLabel: 'labels.postalCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.postalCode',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'StateCode',
          },
          rules: this.isStateCodeRequired ? 'required|max:2' : '',
          validationLabel: 'labels.stateCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            autocomplete: 'new-password',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.stateCode',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'ContactPhone',
          },
          rules: rules.phone,
          validationLabel: 'labels.phone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prefix: this.phonePrefix,
            autocomplete: 'new-password',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.phone',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'Mail',
          },
          validationLabel: 'labels.email',
          rules: 'required|email|max:50',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.email',
        },
      ];
    },

    insuranceOptions() {
      return [
        {
          Value: 0,
          Name: this.$t('labels.customerInsurance'),
        },
        {
          Value: 1,
          Name: this.$t('labels.supplierInsurance'),
        },
        {
          Value: 2,
          Name: this.$t('labels.serviceProviderInsurance'),
        },
      ];
    },
    proformaOptions() {
      return [
        {
          Value: 0,
          Name: this.$t('labels.customerProforma'),
        },
        {
          Value: 1,
          Name: this.$t('labels.supplierProforma'),
        },
      ];
    },
    serviceTypes() {
      let serviceTypes = [];
      if (this.transferType !== 2) {
        serviceTypes.push({
          Id: 3,
          Name: this.$t('labels.portToPort'),
        });
      }

      serviceTypes.push({
        Id: 1,
        Name: this.$t('labels.doorToDoor'),
      });

      if (this.transferType == 2 && this.form.ExportImportType == 0) {
        serviceTypes.push({
          Id: 5,
          Name: this.$t('labels.doorToPort'),
        });
      }

      return serviceTypes;
    },
    formContainerDetails() {
      return [
        {
          key: {
            parent: 'form',
            child: 'SumCapPieces',
          },
          rules: 'required|numeric|min_value:1',
          validationLabel: 'labels.doorCount',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-package-variant',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          disabled: this.demand || this.transferType == 2,
          placeholder: 'labels.doorCount',
        },
        {
          key: {
            parent: 'form',
            child: 'SumWeight',
          },
          rules: 'required|double|min_value:0',
          validationLabel: 'labels.kilo',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-weight-kilogram',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          disabled: this.demand || this.transferType == 2,
          placeholder: 'labels.kilo',
        },
        {
          key: {
            parent: 'form',
            child: 'SumVolume',
          },
          rules: 'required|double|min_value:0',
          validationLabel: 'labels.volume',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-package',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          disabled: this.demand || this.transferType == 2,
          placeholder: 'labels.volume',
        },
        {
          key: {
            parent: 'form',
            child: 'ChargeableWeight',
          },
          rules: 'required|double|min_value:0',
          validationLabel: 'labels.chargeableWeight',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          disabled: true,
          placeholder: 'labels.chargeableWeight',
        },
      ];
    },
    createOrderFormItems(){
      return [
        {
          key: {
            parent: 'orderForm',
            child: 'PurposeOfShipment',
          },
          validationLabel: 'labels.purposeOfShippingLabel',
          rules: 'required',
          formType: {
            name: 'select',
            items: {
              data: this.purposeOfShippingItems,
              value: 'Value',
              text: 'Name',
            },
          },
          lg: 6,
          labelShown: false,
          topLabel: true,
          cols: 6,
          placeholder: 'labels.purposeOfShippingLabel',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'ProformaOption',
          },
          rules: 'required',
          validationLabel: 'labels.proformaOption',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.proformaOptions,
              value: 'Value',
              text: 'Name',
            },
          },
          lg: 6,
          placeholder: 'labels.proformaOption',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'GoodsPrice',
          },
          validationLabel: 'labels.goodsPrice',
          topLabel: true,
          rules: 'required|double',
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 4,
          xl: 2,
          placeholder: 'labels.goodsPrice',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'GoodsPriceCurrency',
          },
          rules: 'required',
          validationLabel: 'labels.currency',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.currencies,
              value: 'Id',
              text: 'Code',
            },
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 4,
          xl: 2,
          placeholder: 'labels.currency',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'CountryOfManufacture',
          },
          validationLabel: 'labels.origin',
          topLabel: true,
          rules: 'required',
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countryItems,
              value: 'ISOCode2',
              text: 'Name',
            },
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 4,
          xl: 2,
          placeholder: 'labels.origin',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'ExpressPackageType',
          },
          validationLabel: 'labels.packageType',
          topLabel: true,
          rules: 'required',
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.packageTypes,
              text: 'name',
              value: 'value',
            },
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 4,
          xl: 2,
          placeholder: 'labels.packageType',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'ExpressServiceType',
          },
          validationLabel: 'labels.serviceType',
          topLabel: true,
          rules: 'required',
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.expressServiceTypes,
              text: 'name',
              value: 'value',
            },
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 4,
          xl: 2,
          placeholder: 'labels.serviceType',
        },

        {
          key: {
            parent: 'orderForm',
            child: 'InsuranceType',
          },
          validationLabel: 'labels.insurance',
          topLabel: true,
          rules: 'required',
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.insuranceOptions,
              value: 'Value',
              text: 'Name',
            },
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 4,
          xl: 4,
          placeholder: 'labels.insurance',
          classes: 'mr-sm-16',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'SignedDelivery',
          },
          validationLabel: 'labels.wantSignedDelivery',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'checkbox',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 4,
          xl: 4,
          placeholder: 'labels.wantSignedDelivery',
          classes: 'ml-lg-16 mt-4 mt-sm-2',
        },
      ];
    },
    formItems(){
      return [
        {
          key: {
            parent: 'form',
            child: 'ExportImportType',
          },
          validationLabel: 'labels.exportImportType',
          rules: 'required',
          formType: {
            name: 'select',
            items: {
              data: [
                {
                  value: 0,
                  name: this.$t('labels.import')
                },
                {
                  value: 1,
                  name: this.$t('labels.export')
                }
              ],
              text: 'name',
              value: 'value',
            },
          },
          lg: 4,
          labelShown: false,
          topLabel: true,
          cols: 4,
          placeholder: 'labels.exportImportType',
        },
        {
          key: {
            parent: 'form',
            child: 'ServiceType',
          },
          rules: 'required',
          validationLabel: 'labels.serviceType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.serviceTypes,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-airplane-cog',
          },
          lg: 4,
          placeholder: 'labels.serviceType',
        },
        {
          key: {
            parent: 'form',
            child: 'TaxPayer',
          },
          rules: 'required',
          validationLabel: 'labels.taxPayer',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.taxPayers,
              value: 'value',
              text: 'name',
            },
            prepend: 'mdi-currency-usd',
          },
          lg: 4,
          placeholder: 'labels.taxPayer',
        },
        {
          key: {
            parent: 'form',
            child: 'LoadingCompany',
          },
          rules: 'required',
          validationLabel: 'labels.loaderCompany',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.subCompanies,
              value: 'Id',
              text: 'Name',
            },
            returnObject: true
          },
          lg: 4,
          cols: 4,
          sm: 8,
          placeholder: 'labels.loaderCompany',
          onChange: () => {
            this.addresses.LoadingCompany = this.convertToAddressFormat(this.form.LoadingCompany);
          }
        },
        {
          key: {
            parent: 'Button',
            child: 'LoadingCompanyAdd',
          },
          icon: "mdi-plus",
          onClick: () => {
            this.addressKey = "LoadingCompany";
            this.submitAddressFormModal = true
          }
        },
        {
          disabled: !this.form.LoadingCompany,
          key: {
            parent: 'Button',
            child: 'LoadingCompanyEdit',
          },
          icon: "mdi-pencil",
          onClick: () => {
            this.editAddressMode = true;
            this.submitAddressFormModal = true;
            this.addressForm = Object.assign({}, this.form.LoadingCompany);
          }
        },
        {
          key: {
            parent: 'form',
            child: 'BuyerCompany',
          },
          rules: 'required',
          validationLabel: 'labels.buyerCompany',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.subCompanies,
              value: 'Id',
              text: 'Name',
            },
            returnObject: true
          },
          cols:4,
          lg: 4,
          sm: 8,
          placeholder: 'labels.buyerCompany',
          onChange: async () => {
            this.addresses.BuyerCompany = this.convertToAddressFormat(this.form.BuyerCompany);

            this.airports = await getAirports(
              '',
              this.form.BuyerCompany.CountryId,
              this.form.BuyerCompany.CityId,
              1,
              9999
            );
          }
        },
        {
          key: {
            parent: 'Button',
            child: 'BuyerCompanyAdd',
          },
          icon: "mdi-plus",
          onClick: () => {
            this.addressKey = "BuyerCompany";
            this.submitAddressFormModal = true
          }
        },
        {
          disabled: !this.form.BuyerCompany,
          key: {
            parent: 'Button',
            child: 'BuyerCompanyEdit',
          },
          icon: "mdi-pencil",
          onClick: () => {
            this.editAddressMode = true;
            this.submitAddressFormModal = true;
            this.addressForm = Object.assign({}, this.form.BuyerCompany);
          }
        },
        {
          key: {
            parent: 'addresses',
            child: 'LoadingCompany',
          },
          disabled: true,
          validationLabel: 'labels.loaderCompany',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },

          lg: 6,
          placeholder: 'labels.loaderCompany',
        },
        {
          key: {
            parent: 'addresses',
            child: 'BuyerCompany',
          },
          disabled: true,
          validationLabel: 'labels.buyerCompany',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },

          lg: 6,
          placeholder: 'labels.buyerCompany',
        },
        {
          key: {
            parent: 'form',
            child: 'AirUnloadingPortId',
          },
          rules: this.form.ServiceType === 3 ? 'required' : '',
          validationLabel: 'labels.fromAirport',
          topLabel: true,
          labelShown: false,
          hidden: !(
            this.transferType == 2 &&
            this.form.ExportImportType == 0 &&
            this.form.ServiceType == 5
          ),
          active: !!this.form.ServiceType &&
            this.transferType == 2 &&
            this.form.ExportImportType == 0 &&
            this.form.ServiceType == 5,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.airports,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-airplane-takeoff',
          },

          lg: this.form.ServiceType === 5 ? 4 : 12,
          placeholder: 'labels.fromAirport',
        },
      ]
    },

    startDateFormat() {
      return this.dateModal.startDate
        ? convertToLocal(this.dateModal.startDate)
        : null;
    },
    endDateFormat() {
      return this.dateModal.endDate
        ? convertToLocal(this.dateModal.endDate)
        : null;
    },
    pages() {
      if (this.rowsPerPage == null || this.totalItems == null) {
        return 0;
      }
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
  },
  methods: {
    convertToAddressFormat(addressObject) {
      if(addressObject){
        let formattedAddress = '';
        formattedAddress = addressObject.Address;
        formattedAddress +=
          ' ' + addressObject.City + '/' + addressObject.CountryCode;
        if (addressObject.StateCode) {
          formattedAddress += ' ' + addressObject.StateCode;
        }

        formattedAddress += ' ' + addressObject.PostalCode;
        return formattedAddress.replaceAll('null', '');
      }
    },
    submitAddressForm(){
      this.$store.state.loadingStatus = true;
        const submitFunction = this.editAddressMode
          ? CompanyServices.updateCompany
          : CompanyServices.addSubCompany;

        
        submitFunction(this.addressForm)
          .then((response) => {
            this.submitAddressFormModal = false;
            this.getSubCompanies();

            const data = response.data.Result;
            if(data && data.Id && !this.editAddressMode && this.addressKey){
              this.form[this.addressKey] = data;
              this.addresses[this.addressKey] = this.convertToAddressFormat(data);
            }
          })
          .catch(window.showError)
          .finally(() => (this.$store.state.loadingStatus = false));
    },
    async onChangeCountry() {
      AddressService.getCounties(this.addressForm.CountryId)
        .then((response) => {
          this.phonePrefix = response.data.Result[0].PhoneCode;
        })
        .catch((error) => {});

      this.cities = await getCities(this.addressForm.CountryId);
    },
    // Add Order
    createOrder(){
      // Create Shipment
      this.$store.state.loadingStatus = true;
  
      const body = {
        FastShippingSearchId: this.fastShippingSearchId,
        Price: this.selectedSearchResult.Price,
        CarrierCompanyId: this.selectedSearchResult.CarrierId,
        CurrencyCode: this.selectedSearchResult.PriceCurrency,
        TransitTime: this.selectedSearchResult.TransportTime,
        PriceListId: this.selectedSearchResult.ListId,
        PriceDetails: this.selectedSearchResult.PriceDetails || [],
      };
      
      ShipmentService.createShipment(body)
        .then(async (res) => {
          var shipment = res.data;
          const orderBody = {
            ShipmentId: shipment.Id,
          };
          await OrderService.createOrder(orderBody)
            .then(async (response) => {
              const orderId = response.data.Result.Id;
              const {
                SumCapPieces,
                SumWeight,
                SumVolume,
                LoadingCompany,
                BuyerCompany,
                AirUnloadingPortId,

                ExportImportType,
                ServiceType,
                TaxPayer,
              } = this.form;

              const updateShipmentBody = {
                Id: shipment.Id,
                ...this.orderForm,

                SumCapPieces,
                SumWeight,
                SumVolume,

                ExportImportType,
                ServiceType,
                TaxPayer,

                DestinationSubCompanyId:  BuyerCompany.Id,
                LoadingSubCompanyId: LoadingCompany.Id,
              };
              
              if(AirUnloadingPortId && AirUnloadingPortId.search("_NA") === -1){
                updateShipmentBody.AirUnloadingPortId = AirUnloadingPortId;
              }
              await ShipmentService.updateShipment(updateShipmentBody)
              .then(response => {
                var order = response.data;
                this.$router.push({
                  name: `OrderDetail`,
                  query: {
                    orderId,
                  },
                });
              })
              .catch(window.showError)
            })
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    async onSaveVolumes(volumes, parameters) {
      this.searchResults = [];

      const {
        totalContainerCount,
        totalVolume,
        totalKilo,
        totalInvoiceWeight,
        chargeableWeight,
      } = parameters;

      const updateVolumes = () => {
        this.form.SumCapPieces = totalContainerCount;
        this.form.SumWeight = totalKilo;
        this.form.SumVolume = totalVolume;
        this.form.ChargeableWeight = chargeableWeight;

        this.form.ShipmentBoxes = convertVolumeToBoxes(volumes);

        this.form = Object.assign({}, this.form);
      };

      this.confirmation.onConfirm = () => {
        updateVolumes();
      };

      if (
        totalVolume > 0 &&
        (parseInt(this.form.SumCapPieces) !== totalContainerCount ||
          parseFloat(this.form.SumWeight).toFixed(2) !== totalKilo ||
          parseFloat(this.form.SumVolume).toFixed(2) !== totalVolume) &&
        this.form.SumCapPieces &&
        this.form.SumWeight &&
        this.form.SumVolume &&
        this.form.TransportType !== 2
      ) {
        this.confirmation.visible = true;
      } else {
        this.confirmation.onConfirm();
      }
    },
    calculateChargeableWeight(width, length, height, containerCount) {
      return (width * length * height * containerCount) / 5000;
    },
    searchOffer(){
      const onResponse = response => {
        let data = response.data.Result;
        if(data){
          this.fastShippingSearchId = data.fastShippingSearchId;
          this.searchResults = data.supplierLists;

          if(this.searchResults.length === 0){
            window.showInfo(this.$t('messages.noOffer'))
          }
          else{
            this.stepper = 2;
          }
        }
      };
      
      this.$store.state.loadingStatus = true;
      const body = {
        "TransportType": 2,
        "ShipmentType": 1,
        "LoadingType": 1,
        "ServiceType": this.form.ServiceType,
        "LoadingCountryId": this.form.LoadingCompany.CountryId,
        "DestinationCountryId": this.form.BuyerCompany.CountryId,
        "DestinationCityId": this.form.BuyerCompany.CityId,
        "DestinationPostalCode": this.form.BuyerCompany.PostalCode,
        "LoadingCityId": this.form.LoadingCompany.CityId,
        "LoadingPostalCode": this.form.LoadingCompany.PostalCode,
        "SumCapPieces": this.form.SumCapPieces,
        "SumWeight": this.form.SumWeight,
        "SumVolume": this.form.SumVolume,
        "ChargeableWeight": this.form.ChargeableWeight,
        "ExportImportType": this.form.ExportImportType,
        "Boxes": this.form.ShipmentBoxes,
        "TaxPayer": this.form.TaxPayer,
      };

      if(this.form.AirUnloadingPortId && this.form.AirUnloadingPortId.search("_NA") === -1){
        body.AirUnloadingPortId = this.form.AirUnloadingPortId;
      }

      SearchServices.getSearches(body)
      .then(onResponse)
      .catch(window.showError)
      .finally(() => this.$store.state.loadingStatus = false)
    },
    getSubCompanies() {
      this.$store.state.loadingStatus = true;
      CompanyServices.getSubCompanies()
        .then((response) => {
          this.subCompanies = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },


    getSearchDebounced() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.rowsPerPage = 15;
        this.itemsPerPage = 15;
        this.page = 1;
        this.getSearch();
      }, 300);
    },

    getSearch() {
      this.tableData = [];
      this.$store.state.loadingStatus = true;
      OrderService.getOrders(
        this.rowsPerPage,
        this.page,
        this.dateModal.startDate,
        this.dateModal.endDate,
        this.selectedOrderStatus,
        null,
        this.selectedShipmentType,
        this.orderFrom,
        this.orderTo,
        null,
        this.search,
        this.trackingNo,
        null
      )
        .then((res) => {
          this.tableData = res.data.Result == null ? [] : res.data.Result;
          this.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    dateReset() {
      this.dateModal.startDate = '';
      this.dateModal.endDate = '';
      this.filterContent();
    },
    resetFilter() {
      this.selectedOrderStatus = null;
      this.selectedShipmentType = null;
      this.orderFrom = '';
      this.orderTo = '';
      this.filterContent();
    },
    downloadTag(item) {
      this.$store.state.loadingStatus = true;
      let string = item.RefNo + '_Labels';
      OrderService.downloadTag(item.Id, string).then(() => {
        this.$store.state.loadingStatus = false;
      });
    },
    toggleArchive(item) {
      const body = {
        OrderId: item.Id,
      };
      OrderService.archiveOrder(body).then((res) => {
        this.getTableList();
      });
    },
    goToPayments() {
      this.$router.push('/home/payments');
    },
    changeItemsPerPage() {
      this.page = 1;
      this.options.itemsPerPage = this.rowsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.page = pageNumber;
      this.getTableList();
    },
    async getCountries() {
      this.countryItems = await getCountries();
    },
    setup() {
      this.headers = [
        {
          text: this.$t('titles.integration.trackingNumber'),
          value: 'TrackingNo',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('labels.customer'),
          value: 'Customer',
          sortable: true,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.supplier'),
          value: 'Carrier',
          sortable: true,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.sender'),
          value: 'Sender',
          sortable: true,
          groupable: false,
          align: 'center',
        },

        {
          text: this.$t('labels.shipmentType'),
          value: 'ShipmentType',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('titles.from') + '/' + this.$t('titles.to'),
          value: 'FromToWhere',
          sortable: false,
          align: 'center',
        },
        // {
        //   text: 'Yük',
        //   value: 'Shipment.SumWeight',
        //   sortable: false,
        //   align: 'center',
        // },

        {
          text: this.$t('labels.referenceNumber'),
          value: 'RefNo',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('labels.dateAsNoun'),
          value: 'CreatedOnUtc',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.orderStatus'),
          value: 'OrderStatus',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('labels.fileStatus'),
          value: 'SupplierOrderStatus',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('titles.orderType'),
          value: 'OrderType',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('labels.price'),
          value: 'Price',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
        },
      ];

      if (this.userRole === 'Customer' || this.userRole === 'PendingCustomer') {
        this.headers = this.headers.filter(
          (header) =>
            header.value !== 'Customer' &&
            header.value !== 'SupplierOrderStatus'
        );
      } else if (
        this.userRole === 'Supplier' ||
        this.userRole === 'PendingSupplier'
      ) {
        this.headers = this.headers.filter(
          (header) =>
            header.value !== 'Carrier' && header.value !== 'OrderStatus'
        );
      } else if (this.isAdmin) {
        this.headers = this.headers.filter(
          (header) => header.value !== 'actions' && header.value !== 'actions'
        );
      }
    },
    goOrderDetail(item) {
      this.$router.push({
        name: `OrderDetail`,
        query: {
          orderId: item.Id,
        },
        params: {
          transportType: item.TransportType,
        },
      });
    },
    goRateScreen(item) {
      this.$router.push({
        name: `rateOrder`,
        params: {
          order: item,
        },
      });
    },
    filterContent() {
      this.tableData = [];
      this.rowsPerPage = 15;
      this.itemsPerPage = 15;
      this.page = 1;
      this.getTableList();
    },
    //Fill's the survey list
    getTableList() {
      this.$store.state.loadingStatus = true;
      if (this.isAdmin) {
        this.tableData = [];
        OrderService.getListForAdmin(
          this.rowsPerPage,
          this.page,
          this.dateModal.startDate,
          this.dateModal.endDate,
          this.selectedOrderStatus,
          this.selectedSupplierOrderStatus,
          this.selectedShipmentType,
          this.orderFrom,
          this.orderTo,
          null,
          this.search,
          this.trackingNo
        )
          .then((res) => {
            this.tableData = res.data.Result == null ? [] : res.data.Result;
            this.totalItems = res.data.TotalItems;
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      } else {
        this.tableData = [];
        OrderService.getOrders(
          this.rowsPerPage,
          this.page,
          this.dateModal.startDate,
          this.dateModal.endDate,
          this.selectedOrderStatus,
          this.selectedSupplierOrderStatus,
          this.selectedShipmentType,
          this.orderFrom,
          this.orderTo,
          null,
          this.search,
          this.trackingNo,
          null
        )
          .then((res) => {
            this.tableData = res.data.Result == null ? [] : res.data.Result;
            this.totalItems = res.data.TotalItems;
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    async messageToUser(id) {
      this.$store.state.loadingStatus = true;
      await MessageServices.getById(id)
        .then(async (response) => {
          const data = response.data.Result;
          let chatId = data.Id;
          if (chatId) {
            this.$router.push({
              name: 'messageDetail',
              query: {
                id: chatId,
              },
            });
          }
        })
        .catch((error) => this.onServiceError(error))
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    getShipmentType(item) {
      let type = item.ShipmentType;
      if (item.TransportType == 2) {
        type = item.ShipmentType + ' (Express)';
      }
      return type;
    },
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },
  },
};
</script>
<style>
.v-stepper__label{
  text-align: center !important;
}
</style>
