<template>
  <v-card
    style="padding: 5vh; border-radius: 0"
    max-width="100%"
    min-width="80%"
    elevation="2"
  >
    <v-row>
      <v-card-text class="py-3 mb-10">
        <div
          class="font-weight-medium text-h6"
          style="width: 100%; color: #0077d5"
        >
          {{ $t('description.addCompanyInformationText') }}
        </div>
      </v-card-text>
      <v-col md="12" xs="12">
        <div>
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form autocomplete="off" @submit.prevent="handleSubmit(addCompany)">
              <v-row>
                <FormElement
                  v-for="(element, index) in formItems"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  :vertical="element.vertical"
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :cols="element.cols"
                  :light="element.light"
                  :hidden="element.hidden"
                  :labelShown="element.labelShown"
                  :topLabel="element.topLabel"
                  :active="element.active"
                  :disabled="element.disabled"
                  :placeholder="$t(element.placeholder)"
                />
                <v-col
                  cols="12"
                  class="d-flex justify-center mt-10 align-center"
                >
                  <v-btn
                    class="add"
                    size="x-large"
                    style="
                      text-transform: uppercase !important;
                      border-radius: 30px !important;
                      padding: 2vh 7vh !important;
                      font-weight: bold;
                      font-size: 24px !important;
                    "
                    dark
                    type="submit"
                    :loading="isLoading"
                  >
                    {{ $t('buttons.add') }}
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import AddressService from '../../services/AddressService';
import CurrencyService from '../../services/CurrencyService';
import CompanyServices from '../../services/CompanyServices';
import { mapActions, mapState } from 'vuex';
import DeleteModal from '../../components/General/DeleteModal';
import TabScreen from '../../components/TabScreen.vue';
import FormElement from '../../components/FormElement';
import uploadFile from '../../utils/uploadFile';
import UserService from '../../services/UserService';
import { clearText } from '../../utils/constants';
import rules from '../../utils/validationRules';

export default {
  data: () => ({
    // General Models
    receiptStatus: false,
    isLoading: false,
    isUpdate: false,
    isUploading: false,
    base64: '',
    showPassword: false,
    cities: [],
    countries: [],

    districts: [
      {
        id: 'Konak',
        cityId: 1,
        value: 'KONAK',
      },
    ],

    descriptionLimit: 60,
    model: null,
    search: null,
    companies: [],
    currencies: [],

    deletedId: -1,

    company: {},
    fileParent: {
      file: null,
    },
    companyTypes: [],
    accountTypes: [],

    districts: [
      {
        id: 'Konak',
        cityId: 1,
        value: 'KONAK',
      },
    ],

    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    headers: [],
    //Loading states
    loadings: {
      table: false,
      send: false,
    },
    pagination: {},
    itemsPerPage: 10,
  }),

  computed: {
    formItems() {
      return [
        {
          key: {
            parent: 'company',
            child: 'CompanyName',
          },
          rules: 'required',
          validationLabel: 'labels.companyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 3,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'company',
            child: 'TaxNo',
          },
          rules: 'required',
          validationLabel: 'labels.company.taxNo',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 3,
          placeholder: 'labels.company.taxNo',
        },
        {
          key: {
            parent: 'company',
            child: 'CompanyWorkType',
          },
          rules: 'required',
          validationLabel: 'labels.accountType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.companyTypes,
              value: 'Value',
              text: 'Name',
            },
          },
          lg: 3,
          placeholder: 'labels.accountType',
        },
        {
          key: {
            parent: 'company',
            child: 'AccountType',
          },
          rules: 'required',
          validationLabel: 'labels.accountType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.accountTypes,
              value: 'Value',
              text: 'Name',
            },
          },
          lg: 3,
          placeholder: 'labels.companyType',
        },

        {
          key: {
            parent: 'company',
            child: 'CountryId',
          },
          rules: 'required',
          validationLabel: 'labels.country',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countries,
              value: 'Id',
              text: 'Name',
            },
          },
          onChange: async () => {
            this.onChangeCountry();
          },
          lg: 3,
          placeholder: 'labels.country',
        },
        {
          key: {
            parent: 'company',
            child: 'CityId',
          },
          rules: 'required',
          validationLabel: 'labels.city',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.cities,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 3,
          placeholder: 'labels.city',
        },
        {
          key: {
            parent: 'company',
            child: 'District',
          },
          rules: 'required',
          validationLabel: 'labels.district',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 3,
          placeholder: 'labels.district',
        },
        {
          key: {
            parent: 'company',
            child: 'PostalCode',
          },
          rules: 'required|min:4|max:9',
          validationLabel: 'labels.postalCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 3,
          placeholder: 'labels.postalCode',
        },
        {
          key: {
            parent: 'company',
            child: 'TaxOffice',
          },
          rules: 'required',
          validationLabel: 'labels.company.taxOffice',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 4,
          placeholder: 'labels.company.taxOffice',
        },
        {
          key: {
            parent: 'company',
            child: 'TradeRegisterNumber',
          },
          validationLabel: 'labels.company.tradeRegisterNumber',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 4,
          placeholder: 'labels.company.tradeRegisterNumber',
        },
        {
          key: {
            parent: 'company',
            child: 'IndustryRegisterNumber',
          },
          validationLabel: 'labels.company.industryRegisterNumber',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 4,
          placeholder: 'labels.company.industryRegisterNumber',
        },
        {
          key: {
            parent: 'company',
            child: 'Website',
          },
          rules: {
            regex:
              /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
          },
          validationLabel: 'labels.website',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 4,
          placeholder: 'labels.website',
        },
        {
          key: {
            parent: 'company',
            child: 'GsmNo',
          },
          rules: rules.phone,
          validationLabel: 'labels.phone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text'
          },
          lg: 4,
          placeholder: 'labels.phone',
        },
        {
          key: {
            parent: 'company',
            child: 'Email',
          },
          rules: 'required|email',
          validationLabel: 'labels.email',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 4,
          placeholder: 'labels.email',
        },

        {
          key: {
            parent: 'company',
            child: 'Address1',
          },
          rules: 'required',
          validationLabel: 'labels.companyAddress',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 6,
          placeholder: 'labels.companyAddress',
        },
        {
          key: {
            parent: 'company',
            child: 'ReceiptAddress',
          },
          rules: 'required',
          hidden: this.company.receiptStatus,
          validationLabel: 'labels.receiptAddress',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 6,
          placeholder: 'labels.receiptAddress',
        },
        {
          key: {
            parent: 'company',
            child: 'receiptStatus',
          },
          // rules: 'required',
          validationLabel: 'labels.receiptAddressInfo',
          topLabel: true,
          light: false,
          labelShown: false,
          formType: {
            name: 'checkbox',
          },
          onChange: () => {
            this.clickedReceiptStatus();
          },
          lg: 12,
          placeholder: 'labels.receiptAddressInfo',
        },
        {
          key: {
            parent: 'fileParent',
            child: 'file',
          },
          validationLabel: 'labels.logo',
          formLabel: 'labels.logo',
          labelShown: false,
          formType: {
            name: 'file',
            type: 'image',
            data: {
              lazy: this.base64 ? this.base64 : this.company.PhotoUrl,
              source: this.base64 ? this.base64 : this.company.PhotoUrl,
              accept: 'image/png, image/jpeg, image/bmp',
            },
          },
          onChange: this.onChangeImage,
          lg: 6,
          placeholder: 'labels.logo',
        },
      ];
    },
    getNoImage() {
      return require('../../assets/no-image.png');
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    items() {
      return this.companies.map((entry) => {
        const CompanyName = entry.CompanyName;
        const CompanyId = entry.Id || 1;
        return Object.assign({}, entry, { CompanyId, CompanyName });
      });
    },
    ...mapState(['CompanyName', 'PhoneNumber', 'Email']),
  },

  watch: {
    CompanyName(newValue, oldValue) {
      this.company.CompanyName = newValue;
    },
    PhoneNumber(newValue, oldValue) {
      this.company.GsmNo = newValue;
    },
    Email(newValue, oldValue) {
      this.company.Email = newValue;
    },
  },

  async mounted() {
    this.setup();
  },

  components: {
    FormElement,
    DeleteModal,
    TabScreen,
  },
  methods: {
    clickedReceiptStatus() {
      if (this.company.receiptStatus) {
        this.company.ReceiptAddress = this.company.Address1;
      } else {
        this.company.ReceiptAddress = '';
      }
    },
    //Page setup
    setup() {
      this.company.CompanyName = this.$store.state.CompanyName;
      this.company.Email = this.$store.state.Email;
      this.company.GsmNo = this.$store.state.PhoneNumber;

      this.getAllCurrencies();
      this.companyTypes = [
        {
          Name: this.$t('labels.supplier'),
          Value: 1,
        },
        {
          Name: this.$t('labels.customer'),
          Value: 2,
        },
      ];
      this.accountTypes = [
        {
          Name: this.$t('labels.personal'),
          Value: 0,
        },
        {
          Name: this.$t('labels.corporate'),
          Value: 1,
        },
      ];
      // Get Countries
      AddressService.getCounties()
        .then((response) => {
          this.countries = response.data.Result;
        })
        .catch((error) => {});
    },
    /**
     * Get Company Type List
     */

    //Clears all modal's states and item ID
    clearModal() {
      setTimeout(() => {
        this.resetForm();
        this.isUpdate = false;
      }, 500);
    },

    /**
     * onChangeImage trigger.
     */
    onChangeImage() {
      if (this.fileParent.file) {
        var reader = new FileReader();
        reader.readAsDataURL(this.fileParent.file);
        reader.onload = () => {
          this.base64 = reader.result;
        };
        this.isUploading = true;
      } else {
        this.isUploading = false;
      }
    },
    onChangeCountry() {
      AddressService.getCities(this.company.CountryId)
        .then((response) => {
          this.cities = response.data.Result;
          this.company.CityId = null;
        })
        .catch((error) => {});
    },
    getAllCurrencies() {
      CurrencyService.getCurrencies()
        .then((response) => {
          this.currencies = response.data.Result;
        })
        .catch((error) => {});
    },

    /**
     * Resetting forms' validation.
     */
    async resetForm() {
      if (this.$refs.observer) {
        this.$refs.observer.reset();
      }
    },

    /**
     * Add a company.
     * FIXME: Şirket eklerken bütün verilerin gönderilmesi gerekiyor.
     * FIXME: Şirketi eklerken kime ait olduğuna dair bir veri gönderilmiyor.
     */
    async addCompany(uploadFinished = false) {
      this.isLoading = true;
      this.$store.state.loadingStatus = true;

      if (this.isUploading && !uploadFinished) {
        await uploadFile(this.fileParent.file, (data) => {
          this.company.PhotoUrl = data;
          this.addCompany(true);
        });
      } else {
        const body = {
          CompanyName: this.company.CompanyName,
          TaxOffice: this.company.TaxOffice,
          TaxNo: this.company.TaxNo,
          TradeRegisterNumber: this.company.TradeRegisterNumber,
          IndustryRegisterNumber: this.company.IndustryRegisterNumber,
          Email: this.company.Email,
          GsmNo: clearText(this.company.GsmNo),
          Country: this.company.CountryId,
          City: this.company.CityId,
          District: this.company.District,
          Postcode: this.company.PostalCode,
          Address1: this.company.Address1,
          ReceiptAddress: this.company.ReceiptAddress,
          CutOffHour: this.company.CutOffHour,
          LoadingDeadline: this.company.LoadingDeadline,
          LatestNotifyTime: this.company.LatestNotifyTime,
          TermDay: this.company.TermDay,
          PenaltyPerDay: this.company.PenaltyPerDay,
          AccountType: this.company.AccountType,
          CompanyWorkType: this.company.CompanyWorkType,
          PhotoUrl: this.company.PhotoUrl,
          Website: this.company.Website,
          SectorId: '',
        }

        this.company.CutOffHour = parseInt(this.company.CutOffHour);
        this.company.LatestNotifyTime = parseInt(this.company.LatestNotifyTime);
        this.company.LoadingDeadline = parseInt(this.company.LoadingDeadline);
        this.company.PenaltyPerDay = parseInt(this.company.PenaltyPerDay);
        this.company.TermDay = parseInt(this.company.TermDay);
        this.company.ReceiptAddress = this.receiptStatus
          ? this.company.Address1
          : this.company.ReceiptAddress;

        CompanyServices.add(body)
          .then((response) => {
            window.showSuccess('Şirket Başarıyla Eklendi.');
            UserService.getUserDetail(this.$store.getters.getId).then(
              (response) => {
                this.isLoading = false;
                this.$store.state.loadingStatus = false;

                this.updateUserType(
                  response.data.Result.Roles.length > 0
                    ? response.data.Result.Roles[0]
                    : 'Unassigned'
                ).then(() => {
                  this.isLoading = false;
                  this.$store.state.loadingStatus = false;
                  this.$router.push({ name: 'company-information' });
                });
              }
            );
          })
          .catch((error) => {
            window.showError(error);
            this.isLoading = false;
            this.$store.state.loadingStatus = false;
          });
      }
    },
    ...mapActions(['updateUserType']),
  },
};
</script>
