<template>
  <div>
    <v-dialog
      @click:outside="$refs.observer.reset()"
      max-width="25%"
      v-model="phoneDialog"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 16px">
          <div>
            {{ $t('labels.changeNumber') }}
          </div>
        </v-card-title>
        <v-divider />

        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(submitDialog)">
            <v-sheet style="padding: 1vh">
              <!-- eslint-disable -->
              <FormElement
                v-for="element in formUpdatePhone"
                :key="element.key.parent + '_' + element.key.child"
                :vertical="element.vertical"
                v-model="
                  element.key.child
                    ? $data[element.key.parent][element.key.child]
                    : $data[element.key.parent]
                "
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :formLabel="$t(element.formLabel)"
                :onChange="element.onChange"
                :disabled="element.disabled"
                :formType="element.formType"
                :topLabel="true"
                :labelShown="false"
                :lg="element.lg"
                :cols="element.cols"
                :active="element.active"
                :placeholder="$t(element.placeholder)"
              />
              <!-- eslint-enable -->
            </v-sheet>
            <v-card-actions
              class="d-flex justify-center rounded-lg card-action"
            >
              <v-btn dark color="var(--turquoise)" class="button" type="submit">
                {{
                  isCodeSended
                    ? $t('title.update', { value: $t('labels.phone') })
                    : $t('buttons.sendCode')
                }}
              </v-btn>

              <v-btn
                dark
                color="var(--lightRed)"
                class="button rounded-lg"
                @click="
                  phoneDialog = false;
                  clearModal();
                "
              >
                {{ $t('buttons.close') }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card elevation="1">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row>
            <v-col class="text-lg-left text-center" lg="5" cols="12">
              <h3>{{ $t('labels.profileInformation') }}</h3>
            </v-col>
          </v-row>
        </v-card-title>
      </div>
      <!-- <v-card-text v-if="!user.SignatureUrl" class="py-3">
        <div class="font-weight-medium" style="width: 100%; color: #d50000">
          {{ $t('description.signatureInformationText') }}
        </div>
      </v-card-text> -->
      <v-divider />
      <v-sheet :rounded="true" class="offerFormSheet">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form autocomplete="off" @submit.prevent="handleSubmit(updateUser)">
            <v-row class="mb-4">
              <FormElement
                v-for="element in formItems"
                :key="element.key.parent + '_' + element.key.child"
                :vertical="element.vertical"
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :formLabel="$t(element.formLabel)"
                :topLabel="true"
                :labelShown="false"
                :onChange="element.onChange"
                :disabled="element.disabled"
                :formType="element.formType"
                :lg="element.lg"
                :cols="element.cols"
                :placeholder="$t(element.placeholder)"
              />
              <FormElement
                v-for="element in formPhone"
                :key="element.key.parent + '_' + element.key.child"
                :vertical="element.vertical"
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :formLabel="$t(element.formLabel)"
                :topLabel="true"
                hide-details
                :labelShown="false"
                :onChange="element.onChange"
                :formType="element.formType"
                :lg="element.lg"
                :disabled="true"
                :cols="10"
                :placeholder="$t(element.placeholder)"
              />

              <v-col cols="2" lg="1" style="padding: 0; margin: 0">
                <v-btn
                  elevation="0"
                  dark
                  color="var(--turquoise)"
                  style="height: 100%"
                  class="rounded-lg text-end"
                  @click="phoneDialog = true"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center align-center">
              <FormElement
                v-for="element in formCompany"
                :key="element.key.parent + '_' + element.key.child"
                :vertical="element.vertical"
                v-model="
                  element.key.child
                    ? $data[element.key.parent][element.key.child]
                    : $data[element.key.parent]
                "
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :formLabel="$t(element.formLabel)"
                :topLabel="true"
                :labelShown="false"
                :onChange="element.onChange"
                :formType="element.formType"
                :lg="element.lg"
                :cols="element.cols"
                :disabled="element.disabled"
                :placeholder="$t(element.placeholder)"
              />
            </v-row>
            <v-row class="d-flex justify-center align-center mt-5">
              <FormElement
                key="2FA_CONSENT"
                :vertical="true"
                v-model="user.EnableTwoFa"
                :validationLabel="
                  $t('description.smsVerificationCheckboxLabel')
                "
                :topLabel="true"
                :labelShown="false"
                :formType="{
                  name: 'checkbox',
                }"
                :onChange="
                  () => {
                    updateUser();
                  }
                "
                :lg="6"
                :classes="'d-flex justify-center align-center mt-5 mb-5'"
                :placeholder="$t('description.smsVerificationCheckboxLabel')"
              />
            </v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                class="add"
                size="x-large"
                style="
                  text-transform: uppercase !important;
                  border-radius: 30px !important;
                  padding: 3vh 4vh !important;
                  font-weight: bold;
                  font-size: 16px !important;
                "
                dark
                type="submit"
                :loading="isLoading"
              >
                {{ $t('buttons.save') }}
              </v-btn>
            </v-col>
            <!-- eslint-enable -->
          </form>
        </ValidationObserver>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import FormElement from '../components/FormElement.vue';
import FileService from '../services/FileService';
import UserService from '../services/UserService';
import { clearText } from '../utils/constants';
import rules from '../utils/validationRules';

export default {
  data: () => ({
    //Main user object in form
    user: {
      CompanyId: '',
      CompanyName: '',
      Email: '',
      Gender: '',
      Id: '',
      Name: '',
      PhoneNumber: '',
      PhotoUrl: '',
      Surname: '',
      UserName: '',
    },

    averageScore: 3.5,

    //Flag variables for uploading,loading, change phone dialog and verification send
    isUploading: false,
    isLoading: false,
    phoneDialog: false,
    isCodeSended: false,

    //Change phone dialog variables
    newPhoneNumber: '',
    verificationCode: '',

    //File variables
    file: null,
    // signature: null,
    base64: '',
    base64Signature: '',
  }),
  watch: {},
  beforeMount() {
    this.setup();
  },
  computed: {
    formUpdatePhone() {
      return [
        {
          key: {
            parent: 'user',
            child: 'PhoneNumber',
          },
          validationLabel: 'labels.formUserPhone',
          formLabel: 'labels.formUserPhone',
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          disabled: true,
          placeholder: 'labels.formUserPhone',
        },
        {
          key: {
            parent: 'newPhoneNumber',
          },
          rules: rules.phone,
          validationLabel: 'labels.newPhoneNumber',
          formLabel: 'labels.newPhoneNumber',
          formType: {
            name: 'text'
          },
          lg: 12,
          cols: 12,
          disabled: this.isCodeSended ? true : false,
          placeholder: 'labels.newPhoneNumber',
        },
        {
          key: {
            parent: 'verificationCode',
          },
          rules: {
            required: true,
            numeric: true,
            min: 1,
            max: 6,
          },
          validationLabel: 'labels.verificationCode',
          formLabel: 'labels.verificationCode',
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          active: this.isCodeSended,
          placeholder: 'labels.verificationCode',
        },
      ];
    },
    formItems() {
      return [
        {
          key: {
            parent: 'user',
            child: 'Name',
          },
          rules: {
            required: true,
            min: 3,
            onlyStringText: true,
          },
          validationLabel: 'labels.name',
          formLabel: 'labels.name',
          topLabel: true,
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.name',
        },
        {
          key: {
            parent: 'user',
            child: 'Surname',
          },
          rules: {
            required: true,
            min: 2,
            onlyStringText: true,
          },
          validationLabel: 'labels.user.lastName',
          formLabel: 'labels.user.lastName',
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.user.lastName',
        },
        {
          key: {
            parent: 'user',
            child: 'Email',
          },
          rules: 'required|email',
          validationLabel: 'labels.userEmail',
          formLabel: 'labels.userEmail',
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.userEmail',
        },
        {
          key: {
            parent: 'user',
            child: 'CompanyName',
          },
          rules: {
            required: true,
          },
          validationLabel: 'labels.companyName',
          formLabel: 'labels.companyName',
          formType: {
            name: 'text',
          },
          lg: 6,
          disabled: true,
          placeholder: 'labels.companyName',
        },
      ];
    },
    formPhone() {
      return [
        {
          key: {
            parent: 'user',
            child: 'PhoneNumber',
          },
          validationLabel: 'labels.userPhone',
          formLabel: 'labels.userPhone',
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.userPhone',
        },
      ];
    },
    formCompany() {
      return [
        {
          key: {
            parent: 'file',
            child: null,
          },
          validationLabel: 'title.tableHeaderProfilePic',
          formLabel: 'title.tableHeaderProfilePic',
          formType: {
            name: 'file',
            type: 'image',
            data: {
              lazy: this.base64 ? this.base64 : this.user.PhotoUrl,
              source: this.base64 ? this.base64 : this.user.PhotoUrl,
              accept: 'image/png, image/jpeg, image/bmp',
            },
          },
          lg: 6,
          onChange: this.onChangeImage,
          placeholder: 'title.tableHeaderProfilePic',
        },
        // {
        //   key: {
        //     parent: 'signature',
        //     child: null,
        //   },
        //   validationLabel: 'title.tableHeaderSignature',
        //   formLabel: 'title.tableHeaderSignature',
        //   formType: {
        //     name: 'file',
        //     type: 'image',
        //     data: {
        //       lazy: this.base64Signature
        //         ? this.base64Signature
        //         : this.user.SignatureUrl,
        //       source: this.base64Signature
        //         ? this.base64Signature
        //         : this.user.SignatureUrl,
        //       accept: 'image/png, image/jpeg, image/bmp',
        //     },
        //   },
        //   lg: 6,
        //   onChange: this.onChangeSignature,
        //   placeholder: 'title.tableHeaderSignature',
        // },
      ];
    },
  },
  components: { FormElement },
  methods: {
    setup() {
      this.$store.state.loadingStatus = true;
      UserService.getUserDetail(this.$store.getters.getId)
        .then((res) => {
          this.user = res.data.Result;
          console.log('User >> ', this.user);
          //this.user.PhoneNumber = formatPhoneNumber(this.user.PhoneNumber);
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    /**
     * Change image trigger.
     */
    onChangeImage() {
      if (this.file) {
        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.base64 = reader.result;
        };
        this.isUploading = true;
      } else {
        this.isUploading = false;
      }
    },
    // onChangeSignature() {
    //   if (this.signature) {
    //     var reader = new FileReader();
    //     reader.readAsDataURL(this.signature);
    //     reader.onload = () => {
    //       this.base64Signature = reader.result;
    //     };
    //     this.isUploading = true;
    //   } else {
    //     this.isUploading = false;
    //   }
    // },

    /**
     *
     * @param {*} uploadFinished
     */
    updateUser(uploadFinished = false) {
      console.log('sss');
      this.isLoading = true;
      this.$store.state.loadingStatus = true;

      if (this.isUploading && !uploadFinished) {
        this.uploadFile();
        // this.uploadSignatureFile();
      } else {
        let body = {
          Name: this.user.Name,
          Surname: this.user.Surname,
          PhotoUrl: this.user.PhotoUrl,
          Email: this.user.Email,
          EnableTwoFa: this.user.EnableTwoFa,
          // SignatureUrl: this.user.SignatureUrl,
        };
        UserService.updateUser(body)
          .then((response) => {
            this.isLoading = false;
            this.$store.state.loadingStatus = false;
            this.showSuccessPopup = true;
            window.showSuccess(this.$t('messages.profileUpdateSuccess'));
            this.setup();
          })
          .catch((error) => {
            this.showSuccessPopup = true;
            window.showError(error);
          });
      }
    },

    /**
     * Upload a file.
     */
    uploadFile() {
      if (this.file) {
        const media = this.file;

        if (media === undefined) {
        }
        let formData = new FormData();
        if (media.type == 'image/jpeg' || media.type == 'image/png') {
          formData.append('Files', media);
          formData.append('Type', 'media');
        }

        FileService.uploadFile(formData)
          .then((uploadFileResponse) => {
            this.user.PhotoUrl = uploadFileResponse.data.Result[0].Url;
            this.updateUser(true);
          })
          .catch((error) => {});
      }
    },
    // uploadSignatureFile() {
    //   if (this.signature) {
    //     const media = this.signature;

    //     if (media === undefined) {
    //     }
    //     let formData = new FormData();
    //     if (media.type == 'image/jpeg' || media.type == 'image/png') {
    //       formData.append('Files', media);
    //       formData.append('Type', 'media');
    //     }

    //     FileService.uploadFile(formData)
    //       .then((uploadFileResponse) => {
    //         this.user.SignatureUrl = uploadFileResponse.data.Result[0].Url;
    //         this.updateUser(true);
    //       })
    //       .catch((error) => {});
    //   }
    // },

    /**
     * Handles the dialog form submit
     */
    submitDialog() {
      if (this.isCodeSended) {
        this.changePhoneNumber();
      } else {
        this.sendVerificationCode();
      }
    },

    /**
     * Changes the phone number with given verification code
     */
    changePhoneNumber() {
      let body = {
        ConfirmCode: this.verificationCode,
        NewPhone: clearText(this.newPhoneNumber),
      };
      this.$store.state.loadingStatus = true;
      UserService.changePhoneNumber(body)
        .then(() => {
          this.phoneDialog = false;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.clearModal();
          this.setup();
        });
    },

    /**
     * Sends the old and new phone number in order to get verification code
     */
    sendVerificationCode() {
      let body = {
        OldPhone: clearText(this.user.PhoneNumber),
        NewPhone: clearText(this.newPhoneNumber),
      };
      
      this.$store.state.loadingStatus = true;
      UserService.sendVerificationCode(body)
        .then((response) => {
          if (response.data.Code === 200) {
            this.isCodeSended = true;
          }
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Clears the dialog component
     */
    clearModal() {
      this.isCodeSended = false;
      this.newPhoneNumber = '';
      this.verificationCode = '';
      this.$refs.observer.reset();
    },
  },
};
</script>
