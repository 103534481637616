<template>
  <v-card elevation="1" class="mt-0 py-lg-4" style="margin: 0 !important">
    <v-card-title style="color: var(--darkBlue)">
      <v-row>
        <v-col
          class="text-lg-left text-center d-flex align-center"
          cols="8"
          sm="10"
          md="10"
          lg="10"
          xl="10"
        >
          <v-icon
            large
            style="color: #0177d4 !important"
            id="menu-activator"
            @click="goBack"
          >
            mdi-arrow-left-bold
          </v-icon>
          <h5>{{ item.ListName }}</h5>
        </v-col>
        <v-col
          class="d-flex align-end items-end justify-end"
          cols="4"
          sm="2"
          md="2"
          lg="2"
          xl="2"
        >
          <FormElement
            key="ENTRY_IS_ACTIVE"
            :vertical="true"
            v-model="item.isActive"
            :validationLabel="$t('labels.isActive')"
            rules="required"
            :topLabel="true"
            :labelShown="false"
            :lg="12"
            :formType="{
              name: 'checkbox',
            }"
            :onChange="
              () => {
                updateActiveStatus();
              }
            "
            :placeholder="$t('labels.isActive')"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />

    <v-dialog
      content-class="short-content"
      v-model="updateExpiryDateModal"
      max-width="400"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('labels.expiryDate') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver ref="expiryDateObserver" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="
              handleSubmit(() => {
                updateExpiryDate();
              })
            "
          >
            <v-card-text>
              <FormElement
                key="ENTRY_CURRENT_EXPIRY_DATE"
                :vertical="true"
                v-model="item.ExpiryDate"
                :validationLabel="$t('labels.expiryDate')"
                rules="required"
                :formLabel="$t('labels.currentExpiryDate')"
                :lg="12"
                :cols="11"
                :disabled="true"
                :topLabel="true"
                :labelShown="false"
                :formType="{
                  name: 'date',
                  dateMode: 'date',
                  backgroundColor: '#E6E6E6',
                }"
                :placeholder="$t('labels.expiryDate')"
              />
              <FormElement
                key="ENTRY_NEW_EXPIRY_DATE"
                :vertical="true"
                v-model="form.ExpiryDate"
                :validationLabel="$t('labels.expiryDate')"
                rules="required"
                :formLabel="$t('labels.newExpiryDate')"
                :lg="12"
                :cols="11"
                :topLabel="true"
                :labelShown="false"
                :formType="{
                  name: 'date',
                  dateMode: 'date',
                }"
                :placeholder="$t('labels.expiryDate')"
              />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;

                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="saveLoading"
                >{{ $t('buttons.save') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="updateExpiryDateModal = false"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateNameModal" max-width="400">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('labels.listName') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver ref="nameObserver" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="
              handleSubmit(() => {
                updateName();
              })
            "
          >
            <v-card-text>
              <FormElement
                key="ENTRY_NAME"
                :vertical="true"
                v-model="form.ListName"
                :validationLabel="$t('labels.name')"
                rules="required"
                :topLabel="true"
                :labelShown="false"
                :lg="12"
                :formType="{
                  name: 'text',
                }"
                :placeholder="$t('labels.name')"
              />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="saveLoading"
                >{{ $t('buttons.save') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="
                  () => {
                    updateNameModal = false;
                    form.ListName = item.ListName;
                  }
                "
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <ConfirmationModal
      v-model="confirmationModal"
      :title="$t('labels.warning')"
      :message="$t('messages.changeTheExcelFile')"
      :confirm="finishExcelFileOperation"
    />
    <ValidationObserver
      v-if="priceData"
      ref="addChartEntryObserver"
      v-slot="{ handleSubmit }"
    >
      <form
        class="mt-5"
        autocomplete="off"
        @submit.prevent="handleSubmit(update)"
      >
        <v-card>
          <v-card-text style="padding: 0px; margin: 0px">
            <v-row class="px-4 pt-4">
              <v-col
                cols="12"
                sm="6"
                md="6"
                lg="3"
                xl="2"
                class="d-flex"
                style="padding: 0px"
              >
                <FormElement
                  key="ENTRY_NAME"
                  :vertical="true"
                  v-model="item.ListName"
                  :validationLabel="$t('labels.name')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :cols="9"
                  :lg="9"
                  :xl="9"
                  :disabled="true"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.name')"
                />
                <v-col cols="3" style="padding: 0px">
                  <v-btn
                    style="border-radius: 12px !important; height: 65%"
                    class="px-2"
                    elevation="0"
                    color="var(--mainColor)"
                    dark
                    type="button"
                    @click="updateNameModal = true"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
                lg="3"
                xl="2"
                class="d-flex"
                style="padding: 0px"
              >
                <FormElement
                  key="ENTRY_EXPIRY_DATE"
                  :vertical="true"
                  v-model="item.ExpiryDate"
                  :validationLabel="$t('labels.expiryDate')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :disabled="true"
                  :cols="9"
                  :lg="9"
                  :xl="9"
                  :formType="{
                    name: 'date',
                    dateMode: 'date',
                  }"
                  :placeholder="$t('labels.expiryDate')"
                />
                <v-col cols="3" style="padding: 0px">
                  <v-btn
                    style="border-radius: 12px !important; height: 65%"
                    class="px-2"
                    elevation="0"
                    color="var(--mainColor)"
                    dark
                    type="button"
                    @click="updateExpiryDateModal = true"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-col>

              <FormElement
                key="ENTRY_COUNTRY"
                :vertical="true"
                v-model="priceData.Result.OriginCountry"
                :validationLabel="$t('labels.country')"
                rules="required"
                :topLabel="true"
                :labelShown="false"
                :lg="2"
                :disabled="true"
                :formType="{
                  name: 'text',
                }"
                :placeholder="$t('labels.country')"
              />

              <FormElement
                key="ENTRY_CURRENCY"
                :vertical="true"
                v-model="item.CurrencyCode"
                :validationLabel="$t('labels.currency')"
                rules="required"
                :topLabel="true"
                :labelShown="false"
                :lg="2"
                :disabled="true"
                :formType="{
                  name: 'text',
                }"
                :placeholder="$t('labels.currency')"
              />

              <FormElement
                key="ENTRY_SHIPMENT_TYPE"
                :vertical="true"
                v-model="item.ShipmentType"
                :validationLabel="$t('titles.shipmentType')"
                :disabled="true"
                :topLabel="true"
                :labelShown="false"
                :lg="2"
                :formType="{
                  name: 'select',
                  items: {
                    data: shipmentTypes,
                    value: 'Id',
                    text: 'Name',
                  },
                }"
                :placeholder="$t('titles.shipmentType')"
              />
            </v-row>
            <v-divider />
            <div style="position: relative; width: 100%">
              <div
                v-if="
                  !(activeTab == 2 && !outOfAreaData) &&
                  ($vuetify.breakpoint.xl ||
                    $vuetify.breakpoint.lg ||
                    $vuetify.breakpoint.md)
                "
                class="d-flex mt-6 px-4"
                style="position: absolute; right: 0; top: 0; z-index: 1"
              >
                <v-btn
                  style="border-radius: 12px !important"
                  elevation="0"
                  color="primary"
                  class="button"
                  dark
                  :href="
                    activeTab === 0
                      ? priceData?.Result?.PriceFileUrl
                      : priceData?.Result?.RegionFileUrl
                  "
                  depressed
                  download
                  ><v-icon left> mdi-download </v-icon
                  >{{ $t('buttons.downloadFile') }}</v-btn
                >
                <div class="ml-2">
                  <v-btn
                    style="border-radius: 12px !important"
                    elevation="0"
                    color="primary"
                    class="button"
                    dark
                    depressed
                    @click="fileUpload"
                  >
                    <v-icon left> mdi-upload </v-icon>
                    {{ $t('buttons.changeExcelFile') }}
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    @change="onFileChanged"
                  />
                </div>
              </div>

              <div
                v-if="
                  !(activeTab == 2 && !outOfAreaData) &&
                  !(
                    $vuetify.breakpoint.xl ||
                    $vuetify.breakpoint.lg ||
                    $vuetify.breakpoint.md
                  )
                "
                class="d-flex mt-4 px-4"
                style="z-index: 1"
              >
                <v-btn
                  style="border-radius: 12px !important"
                  elevation="0"
                  color="primary"
                  class="button"
                  dark
                  :href="
                    activeTab === 0
                      ? priceData?.Result?.PriceFileUrl
                      : priceData?.Result?.RegionFileUrl
                  "
                  depressed
                  download
                  ><v-icon left> mdi-download </v-icon
                  >{{ $t('buttons.downloadFile') }}</v-btn
                >
                <div class="ml-2">
                  <v-btn
                    style="border-radius: 12px !important"
                    elevation="0"
                    color="primary"
                    class="button"
                    dark
                    depressed
                    @click="fileUpload"
                  >
                    <v-icon left> mdi-upload </v-icon>
                    {{ $t('buttons.changeExcelFile') }}
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    @change="onFileChanged"
                  />
                </div>
              </div>

              <TabScreen
                :slots="slots"
                :changeIndexFunction="onTabChange"
                class="px-4 pt-4"
              >
                <template v-slot:prices>
                  <div>
                    <v-simple-table
                      v-if="priceData"
                      border="2"
                      style="
                        width: 100%;
                        border-collapse: collapse;
                        border-radius: 0;
                        border-style: solid;
                        border-bottom-width: 1px;
                        border-left-width: 2px;
                        border-right-width: 2px;
                        border-top-width: 0px;
                        border-color: lightgray;
                      "
                    >
                      <thead>
                        <th v-for="(item, index) in priceData.Result.Headers">
                          {{ item }}
                        </th>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in priceData.Result.Values">
                          <td
                            style="padding: 0 25px; text-align: center"
                            v-for="(value, valueIndex) in item"
                          >
                            {{ value ? value : '-' }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                </template>
                <template v-slot:regions>
                  <div
                    style="
                      border-radius: 0;
                      border-style: solid;
                      border-bottom-width: 1px;
                      border-left-width: 2px;
                      border-right-width: 2px;
                      border-top-width: 0px;
                      border-color: lightgray;
                    "
                  >
                    <v-data-table
                      :no-data-text="$t('messages.noData')"
                      @update:sort-desc="sort"
                      :headers="[
                        {
                          text: $t('labels.country'),
                          value: 'CountryName',
                          sortable: true,
                          groupable: false,
                          align: 'center',
                        },
                        {
                          text: $t('labels.region'),
                          value: 'RegionName',
                          sortable: true,
                          groupable: false,
                          align: 'center',
                        },
                        {
                          text: $t('labels.minZipCode'),
                          value: 'MinZipCode',
                          sortable: true,
                          groupable: false,
                          align: 'center',
                        },
                        {
                          text: $t('labels.maxZipCode'),
                          value: 'MaxZipCode',
                          sortable: true,
                          groupable: false,
                          align: 'center',
                        },
                        {
                          text: $t('titles.transferTime'),
                          value: 'TransitTime',
                          sortable: true,
                          groupable: false,
                          align: 'center',
                        },
                      ]"
                      :loading="$store.state.loadingStatus"
                      :options="{
                        itemsPerPage: itemsPerPage,
                        page: page,
                        sortBy: ['Region', 'DestinationCountry'],
                        sortDesc: [sortDesc],
                      }"
                      hide-default-footer
                      :items="priceData.Result.Regions"
                      :item-key="'DestinationCountry'"
                    >
                      <template v-slot:footer>
                        <div class="d-flex justify-center mt-8">
                          <v-row align="center" justify="center">
                            <v-col
                              v-if="
                                $vuetify.breakpoint.md ||
                                $vuetify.breakpoint.lg ||
                                $vuetify.breakpoint.xl
                              "
                              md="2"
                            ></v-col>
                            <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                              <v-pagination
                                class="my-4"
                                @input="onPageChange"
                                :value="page"
                                :length="pageLength"
                                circle
                                :total-visible="
                                  $vuetify.breakpoint.xs ? 10 : 10
                                "
                              ></v-pagination>
                            </v-col>
                            <v-col
                              cols="11"
                              sm="11"
                              md="3"
                              lg="3"
                              xl="3"
                              class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                            >
                              <v-select
                                v-model="itemsPerPage"
                                :items="[5, 10, 15, 20, 50, 100, 1000]"
                                :label="$t('labels.selectItemsPerPage')"
                                item-value="value"
                                item-text="name"
                                outlined
                                dense
                                hide-details
                                @change="changeItemsPerPage"
                                rounded
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                        </div>
                      </template>
                      <template v-slot:no-data>
                        <div class="my-10 d-flex flex-column align-center">
                          <h4>{{ $t('messages.noSearch') }}</h4>
                          <v-btn
                            style="
                              color: white;
                              background-color: var(--darkBlue);
                            "
                            class="button my-2"
                            @click="showCreateOffer = true"
                          >
                            {{ $t('buttons.search') }}
                            <v-icon dark right> mdi-magnify </v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </template>
              </TabScreen>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center"> </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import PartialTransportationServices from '../services/PartialTransportationServices';
import { ITEMS_PER_PAGE_OPTIONS, shipmentTypes } from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import TabScreen from '../components/TabScreen.vue';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';

export default {
  data() {
    return {
      item: {},
      shipmentTypes,
      priceData: null,
      slots: [],
      isSelecting: false,
      file: null,
      priceExcel: null,
      page: 1,
      itemsPerPage: 10,
      updateNameModal: false,
      confirmationModal: false,
      updateExpiryDateModal: false,
      form: {},
      saveLoading: false,
      sortDesc: null,
      activeTab: 0,
    };
  },
  components: { FormElement, TabScreen, ConfirmationModal },
  mounted() {
    this.item = this.$route.params.item;
    console.log(this.item);
    if (!this.item) this.$router.push('partial-transportation');
    else {
      this.item.ExpiryDate = this.item.ExpiryDate.slice(0, 10);

      this.form = {
        ExpiryDate: this.item.ExpiryDate,
        ListName: this.item.ListName,
      };

      this.slots = [
        {
          name: 'prices',
          title: this.$t('labels.priceList'),
          unSeenCount: 0,
        },
        {
          name: 'regions',
          title: this.$t('labels.regionList'),
          unSeenCount: 0,
        },
      ];

      this.$store.state.loadingStatus = true;
      this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
      this.setup();
    }
  },
  watch: {
    updateExpiryDateModal(newValue) {
      if (!newValue) {
        this.form.ExpiryDate = this.item.ExpiryDate;
      }
    },
  },
  computed: {
    pageLength() {
      return Math.ceil(
        this.priceData?.Result?.Regions?.length / this.itemsPerPage
      );
    },
  },
  methods: {
    onTabChange(index) {
      this.activeTab = index;
    },
    sort(item) {
      this.sortDesc = !!item;
    },
    goBack() {
      if (this.$router.getRoutes().length === 0) {
        this.$router.push({ name: 'PriceChartEntry' });
      } else {
        this.$router.go(-1);
      }
    },

    fileUpload() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      this.confirmationModal = true;
    },
    finishExcelFileOperation() {
      this.$store.state.loadingStatus = true;

      if (this.activeTab === 0) {
        let body = {
          PartialPriceId: this.priceData.Result.Id,
          PriceExcel: this.file,
        };

        PartialTransportationServices.updatePriceExcel(body)
          .then((response) => {
            window.showSuccess(this.$t('messages.updatePriceExcel'));
            this.getPriceExcel();
          })
          .catch((err) => {
            window.showError(err);
          })
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      } else {
        let body = {
          RegionExcelId: this.priceData.Result.RegionExcelId,
          RegionExcel: this.file,
        };

        PartialTransportationServices.updateRegionExcel(body)
          .then((response) => {
            window.showSuccess(this.$t('messages.updateRegionExcel'));
            this.getPriceExcel();
          })
          .catch((err) => {
            window.showError(err);
          })
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    // finishExcelFileOperation() {
    //   let service = null,
    //     formData = new FormData(),
    //     finalService = null;
    //   if (this.activeTab === 0) {
    //     service = ExpressCargoServices.updatePriceExcel;
    //     formData.append('PriceExcelId', this.item.PriceExcelId);
    //     finalService = this.getPriceExcel;
    //     this.selectedFiles.priceExcel = this.file;
    //   } else {
    //     service = ExpressCargoServices.updateRegionExcel;
    //     formData.append('RegionExcelId', this.item.RegionExcelId);
    //     formData.append(
    //       'OriginCountryId',
    //       this.regionData.Result.OriginCountryId
    //     );
    //     finalService = this.getRegionExcel;
    //     this.selectedFiles.regionExcel = this.file;
    //   }

    //   formData.append('File', this.file);

    //   if (service) {
    //     this.$store.state.loadingStatus = true;
    //     service(formData)
    //       .then((response) => {
    //         window.showSuccess(this.$t('messages.changeFileSuccessful'));
    //         finalService();
    //       })
    //       .catch(this.onServiceError)
    //       .finally(() => {
    //         this.$store.state.loadingStatus = false;
    //       });
    //   }
    // },
    updateActiveStatus() {
      this.$store.state.loadingStatus = true;
      let body = {
        PriceListId: this.item.Id,
        isActive: this.item.isActive ? true : false,
      };
      PartialTransportationServices.updateActivityStatus(body)
        .then((response) => {
          window.showSuccess(this.$t('messages.updateActiveExpress'));
        })
        .catch((err) => {
          window.showError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    updateExpiryDate() {
      this.$store.state.loadingStatus = true;
      let body = {
        PriceListId: this.item.Id,
        ExpiryDate: new Date(this.form.ExpiryDate),
      };
      PartialTransportationServices.updateExpiryDate(body)
        .then((response) => {
          window.showSuccess(this.$t('messages.updateExpiryDate'));
          this.item.ExpiryDate = this.form.ExpiryDate;
          this.updateExpiryDateModal = false;
        })
        .catch((err) => {
          window.showError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    updateName() {
      this.$store.state.loadingStatus = true;
      let body = {
        PriceListId: this.item.Id,
        ListName: this.form.ListName,
      };
      PartialTransportationServices.updateName(body)
        .then((response) => {
          window.showSuccess(this.$t('messages.updateListNameSuccess'));
          this.item.ListName = this.form.ListName;
          this.updateNameModal = false;
        })
        .catch((err) => {
          window.showError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    updateTransportTime() {
      //
      // Servis bekleniyor
      //
      // this.$store.state.loadingStatus = true;
      // let body = {
      //   PriceListId: this.item.Id,
      //   TransportTime: this.form.TransportTime,
      // };
      // PartialTransportationServices.updateTransportTime(body)
      //   .then((response) => {
      //     window.showSuccess(this.$t('messages.updateTransportTime'));
      //     this.item.TransportTime = this.form.TransportTime;
      //     this.updateTransportTimeModal = false;
      //   })
      //   .catch(this.onServiceError)
      //   .finally(() => {
      //     this.$store.state.loadingStatus = false;
      //   });
    },
    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    changeItemsPerPage(newValue) {
      this.page = 1;
      this.itemsPerPage = newValue;
    },

    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {
      this.page = newPage;
    },

    setup() {
      this.getPriceExcel();
    },

    getPriceExcel() {
      PartialTransportationServices.getPriceDetail(this.item.Id)
        .then((response) => {
          this.priceData = response?.data;
          console.log(this.priceData);
        })
        .catch((err) => {
          window.showError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}

.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
