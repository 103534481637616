<template>
  <div
    style="z-index: 999999"
    class="text-center"
    v-if="$store.state.forceLogout"
  >
    <v-overlay>
      <v-card
        elevation="12"
        style="background-color: white; max-width: 400px; padding: 20px"
      >
        <!-- Title Section -->
        <v-card-title
          style="display: flex; justify-content: center; align-items: center"
        >
          <img
            src="../../images/error.png"
            alt="Image Description"
            style="width: 200px; max-height: 200px"
          />
        </v-card-title>

        <v-card-text>
          <p class="text-h5 black--text" style="font-weight: bold">
            {{ $t('description.titleInformation') }}
          </p>
          <p class="black--text text-subtitle-1">
            {{ $t('description.logoutInformationText') }}
          </p>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            variant="tonal"
            class="rounded-lg"
            style="
              color: white;
              text-transform: none;
              font-size: large;
              font-weight: bold;
              padding: 1em;
            "
            color="var(--red)"
            @click="forceLogoutClicked"
          >
            {{ $t('buttons.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue';
export default {
  components: {
    LottieAnimation,
  },
  data: () => ({}),
  computed: {},
  methods: {
    forceLogoutClicked() {
      this.$store.state.forceLogout = false;

      if (
        this.$router.history.current.name !== 'createOffer' &&
        this.$router.history.current.name !== 'login'
      ) {
        this.$store.dispatch('logout');
      } else {
        // window.location.reload();
        // this.$store.dispatch('logout');
      }
    },
  },
};
</script>

<style></style>
