<template>
  <div>
    <v-dialog
      @input="reset()"
      @close="reset()"
      v-if="customer"
      v-model="blockUserModal"
      max-width="600"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 22px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('titles.blockUserNameOf', { value: customer.fullName }) }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <v-card-text class="">
            <form
              @submit.prevent="
                handleSubmit(() => {
                  blockUserConfirmationModal = true;
                })
              "
            >
              <v-row>
                <FormElement
                  v-for="(element, index) in formItems"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :cols="element.cols"
                  :hidden="element.hidden"
                  :labelShown="false"
                  :topLabel="true"
                  :active="element.active"
                  :placeholder="$t(element.placeholder)"
                />
              </v-row>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: #0077d5;
                    padding: 1vh 1vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1"
                  type="submit"
                  color="primary"
                  >{{ $t('buttons.send') }}</v-btn
                >
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: var(--red);
                    padding: 1vh 1vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1"
                  @click="
                    reset();
                    blockUserModal = false;
                  "
                  >{{ $t('buttons.cancel') }}</v-btn
                >
              </v-card-actions>
            </form>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>

    <v-tooltip v-if="dataTable" top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          color="red"
          class="mr-1"
          style="font-size: 20px"
          v-bind="attrs"
          v-on="on"
          @click="blockUserModal = true"
        >
          mdi-account-cancel
        </v-icon>
      </template>
      <span>{{ $t('titles.blockUser') }}</span>
    </v-tooltip>

    <v-btn
      v-if="customer && !dataTable"
      class="rounded-circle mr-4"
      style="
        min-width: 1px;
        min-height: 1px;
        width: 36px;
        height: 36px;
        background-color: gray;
      "
      @click="blockUserModal = true"
      :loading="$store.state.loadingStatus"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon small color="white" style="font-size: 24px" v-on="on">
            mdi-account-cancel
          </v-icon>
        </template>
        <span>{{ $t('titles.blockUser') }}</span>
      </v-tooltip>
    </v-btn>

    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="$t('messages.sureToBlockUser')"
      :confirm="blockUser"
      ref="confirmationModal"
      v-model="blockUserConfirmationModal"
    />
  </div>
</template>
<script>
import FormElement from './FormElement.vue';
import BlacklistServices from '../services/BlacklistServices';
import uploadFile from '../utils/uploadFile';
import ConfirmationModal from './General/ConfirmationModal.vue';

export default {
  constructor: () => {},
  name: 'BlockUser',
  props: {
    customer: {
      type: Object,
      default: null,
    },
    onBlocked: {
      type: Function,
      default: null,
    },
    dataTable: false,
  },
  components: { FormElement, ConfirmationModal },
  mounted() {},
  computed: {
    formItems() {
      return [
        {
          key: {
            parent: 'form',
            child: 'description',
          },
          rules: {
            required: true,
            max: 100,
            min: 10,
          },
          validationLabel: 'labels.description',
          formLabel: 'labels.description',
          formType: {
            name: 'textarea',
          },
          lg: 12,
          placeholder: 'labels.description',
        },
        {
          key: {
            parent: 'form',
            child: 'file',
          },

          validationLabel: 'labels.file',
          formLabel: 'labels.file',
          formType: {
            name: 'file',
            data: {
              accept: 'image/png, image/jpeg, image/bmp',
            },
          },
          lg: 12,
          placeholder: 'labels.file',
        },
      ];
    },
  },
  data() {
    return {
      blockUserModal: false,
      form: {},
      blockUserConfirmationModal: false,
    };
  },
  methods: {
    reset() {
      this.form = {};
      this.$refs.observer.reset();
    },

    // Block User Submit Function
    async blockUser() {
      this.$store.state.loadingStatus = true;
      let uploadedFile = null;
      if (this.form.file) {
        uploadedFile = await uploadFile(this.form.file);
      }

      BlacklistServices.add({
        CustomerId: this.customer.customerId,
        Reason: this.form.description,
        FileUrl: uploadedFile,
      })
        .then((response) => {
          window.showSuccess(this.$t('messages.addBlacklistSuccessfully'));
          this.reset();
          this.blockUserModal = false;

          if (this.onBlocked) {
            this.onBlocked();
          }
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
