<template>
  <!-- Forgot Password START -->
  <v-container>
    <v-layout wrap align-center justify-center fill-height>
      <v-card
        style="padding: 4vh; border-radius: 20px"
        min-width="35%"
        max-width="65%"
      >
        <v-row>
          <v-col md="12" xs="12">
            <div>
              <div
                class="d-flex justify-center bold-title text-h6 text-sm-h6 text-md-h6 text-lg-h5 text-xl-h5 text-xxl-h5 font-weight-bold"
              >
                {{ $t('description.verifyResetToken') }}
              </div>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form
                  autocomplete="off"
                  @submit.prevent="handleSubmit(changePassword)"
                >
                  <v-row class="mt-4">
                    <FormElement
                      v-for="element in formItems"
                      :key="element.key.parent + '_' + element.key.child"
                      :vertical="element.vertical"
                      v-model="$data[element.key.parent][element.key.child]"
                      :validationLabel="$t(element.validationLabel)"
                      :rules="element.rules"
                      :formLabel="$t(element.formLabel)"
                      :topLabel="true"
                      :labelShown="false"
                      :onChange="element.onChange"
                      :formType="element.formType"
                      :lg="element.lg"
                      :cols="element.cols"
                      :hideDetails="true"
                      :disabled="element.disabled"
                      :placeholder="$t(element.placeholder)"
                    />

                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;
                        padding: 2vh 4vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                      "
                      elevation="0"
                      class="darken-1 mt-4 mx-auto"
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                    >
                      {{ $t('buttons.save') }}
                    </v-btn>
                  </v-row>
                </form>
              </ValidationObserver>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-layout>
  </v-container>
</template>
<script>
import AccountService from '../services/AccountService';
import FormElement from './FormElement.vue';

export default {
  data() {
    return {
      loading: false,
      item: {
        password: '',
        confirmPassword: '',
      },
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  props: {
    isVerified: false,
    data: {},
  },

  components: { FormElement },
  computed: {
    formItems() {
      return [
        {
          key: {
            parent: 'item',
            child: 'password',
          },
          rules: 'required|max:24',
          rules: {
            required: true,
            max: 24,
            min: 6,
            regex:
              /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
          },
          validationLabel: 'labels.password',
          formLabel: 'labels.password',
          formType: {
            name: this.showPassword ? 'seePassword' : 'password',
            counter: '24',
            appendIcon: this.showPassword ? 'mdi-eye' : 'mdi-eye-off',
            appendClick: () => {
              this.showPassword = !this.showPassword;
            },
          },
          lg: 12,
          placeholder: 'labels.password',
        },
        {
          key: {
            parent: 'item',
            child: 'confirmPassword',
          },
          rules:
            'required|max:24|confirmedPassword:' + this.$t('labels.password'),
          validationLabel: 'labels.userPasswordAgain',
          formLabel: 'labels.userPasswordAgain',
          formType: {
            name: this.showConfirmPassword ? 'text' : 're-password',
            counter: '24',
            appendIcon: this.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',
            appendClick: () => {
              this.showConfirmPassword = !this.showConfirmPassword;
            },
          },
          lg: 12,
          placeholder: 'labels.userPasswordAgain',
        },
      ];
    },
  },
  methods: {
    /**
     * After your verifying. You can change password.
     */
    changePassword() {
      this.loading = true;
      console.log('Item', this.item);
      console.log('DATA ', this.data);

      const body = {
        UserId: this.data.UserId,
        ResetToken: this.data.ResetToken,
        Password: this.item.password,
        PasswordConfirm: this.item.confirmPassword,
      };

      this.item = body;

      if (this.item) {
        // If it is verified then we can change with ResetToken.
        if (this.isVerified) {
          AccountService.updatePassword(this.item)
            .then((response) => {
              window.showSuccess('Şifreniz başarılı bir şekilde güncellendi.');
              this.$router.push('/login');
            })
            .catch((error) => {
              window.showError(error);
            })
            .finally((response) => {
              this.loading = false;
            });
        } else {
          new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(true);
            }, 2000);
          })
            .then((response) => {
              window.showSuccess('Şifreniz başarılı bir şekilde güncellendi.');
            })
            .catch((error) => {
              window.showError(error);
            })
            .finally((response) => {
              this.loading = false;
            });
        }
      } else {
        throw new Error('There are no data in ChangePassword.');
      }
    },
  },
};
</script>
<style>
.text-field-label {
  color: #45b9be;
  margin-bottom: 2vh;
  font-weight: 500;
}
</style>
