import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'order';
export default {
  async getPlannerList(
    PageSize = 15,
    Page = 1,
    Customer = '',
    TrackingNo = '',
    RefNo = '',
    Planned = null,
    OrderStatus = null
  ) {
    return axios.get(`${RESOURCE_NAME}/getListForPlanner`, {
      params: {
        PageSize,
        Page,
        Customer,
        TrackingNo,
        RefNo,
        Planned,
        OrderStatus
      },
    });
  },

  plannedSelected(body) {
    return axios.put(`${RESOURCE_NAME}/plan`, body);
  },
};
