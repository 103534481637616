<template>
  <div>
    <!-- <NavMenu /> -->
    <div class="unauthenticatedPage">
      <v-layout wrap align-center justify-center fill-height>
        <v-container>
          <v-layout wrap align-center justify-center fill-height>
            <v-card
              style="border-radius: 20px"
              :max-width="
                $vuetify.breakpoint.xs
                  ? '100%'
                  : $vuetify.breakpoint.sm
                  ? '75%'
                  : $vuetify.breakpoint.md
                  ? '50%'
                  : $vuetify.breakpoint.lg
                  ? '40%'
                  : $vuetify.breakpoint.xl
                  ? '35%'
                  : '35%'
              "
              :min-width="
                $vuetify.breakpoint.xs
                  ? '100%'
                  : $vuetify.breakpoint.sm
                  ? '75%'
                  : $vuetify.breakpoint.md
                  ? '50%'
                  : $vuetify.breakpoint.lg
                  ? '40%'
                  : $vuetify.breakpoint.xl
                  ? '35%'
                  : '35%'
              "
              :style="{
                padding: $vuetify.breakpoint.xs
                  ? '8vh 1.5vh'
                  : $vuetify.breakpoint.sm
                  ? '10vh 3.5vh'
                  : $vuetify.breakpoint.md
                  ? '6vh 1.5vh'
                  : $vuetify.breakpoint.lg
                  ? '6vh 1.5vh'
                  : $vuetify.breakpoint.xl
                  ? '6vh 1.5vh'
                  : '6vh 1.5vh',
              }"
            >
              <v-row>
                <v-col md="12" xs="12">
                  <div>
                    <v-img
                      v-if="successful == 2"
                      :src="require('@/../public/img/successfull.png')"
                      style="max-width: 200px; margin: auto"
                      class="bg-grey-lighten-2"
                    ></v-img>
                    <v-img
                      v-if="successful == 1"
                      :src="require('@/../public/img/close.png')"
                      style="max-width: 200px; margin: auto"
                      class="bg-grey-lighten-2"
                    ></v-img>
                    <div
                      v-if="successful != 0"
                      class="text-center"
                      :style="
                        'font-weight: 700; font-size: 24px; margin-top: 30px;' +
                        (successful === 1
                          ? 'color: #f35050;'
                          : 'color: #10ad4e;')
                      "
                    >
                      {{
                        successful == 2
                          ? $t('messages.successfullLogin')
                          : $t('messages.failedLogin')
                      }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
        </v-container>
      </v-layout>
    </div>
  </div>
</template>

<script>
import NavMenu from '../components/NavMenu.vue';
import CompanyServices from '../services/CompanyServices';
import EtsyService from '../services/EtsyService';
import UPSService from '../services/UPSService';

export default {
  data() {
    return {
      successful: 0,
    };
  },
  components: { NavMenu },
  mounted() {
    if (this.$route.path.includes('etsy-callback')) {
      this.handleEtsyCallback();
    } else if (this.$route.path.includes('ups-callback')) {
      this.handleUPSCallback();
    } else if (this.$route.path.includes('connect-portal')) {
      this.handleConnectPortalCallback();
    }
  },
  methods: {
    handleEtsyCallback() {
      this.$store.state.loadingStatus = false;
      this.successful = 0;
      let body = {
        code: this.$route.query.code,
        state: this.$route.query.state,
      };
      EtsyService.oauthEtsy(body)
        .then((res) => {
          this.successful = 2;
          console.log('success');
          if (window.opener) {
            window.opener.location.reload();
          }
        })
        .catch((error) => {
          console.log('ERROR >> ', error);
          this.successful = 1;
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    handleUPSCallback() {
      this.$store.state.loadingStatus = false;
      this.successful = 0;
      let body = {
        Code: this.$route.query.code,
        State: this.$route.query.state,
      };

      UPSService.oauthUPS(body)
        .then((res) => {
          this.successful = 2;
          console.log('success');
          if (window.opener) {
            window.opener.location.reload();
          }
        })
        .catch((error) => {
          console.log('ERROR >> ', error);
          this.successful = 1;
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    handleConnectPortalCallback() {
      this.$store.state.loadingStatus = false;
      this.successful = 0;

      const supplierId = this.$route.params.supplierId;
      const portalId = this.$route.params.portalId;

      let body = {
        SupplierCompanyId: supplierId,
        PortalCompanyId: portalId,
      };

      CompanyServices.connectPortal(body)
        .then((res) => {
          this.successful = 2;
          console.log('success');
          if (window.opener) {
            window.opener.location.reload();
          }
        })
        .catch((error) => {
          console.log('ERROR >> ', error);
          this.successful = 1;
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
