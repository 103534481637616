import AccountService from '../services/AccountService';
import ModuleService from '../services/ModuleService';
import CompanyServices from '../services/CompanyServices';
import MessageServices from '../services/MessageServices';
import store from './store';

export default {
  login({ commit }, body) {
    return new Promise((resolve, reject) => {
      AccountService.twoFA(body)
        .then((res) => {
          commit('updateUserInfo', res.data.Result);
          commit('updateUserToken', {
            access: res.data.Result.Token,
            refresh: res.data.Result.Token,
          });
          commit(
            'updateUserType',
            res.data.Result.Roles.length > 0
              ? res.data.Result.Roles[0]
              : 'Unassigned'
          );
          resolve(res);
        })
        .catch((err) => {
          window.showError(err);
          return reject(err);
        });
    });
  },
  loginWithoutSMS({ commit }, body) {
    commit('updateUserInfo', body.userInfo);
    commit('updateUserToken', body.userToken);
    commit('updateUserType', body.userType);
    body.onComplete();
  },

  portalLogin({ commit }, body) {
    return new Promise((resolve, reject) => {
      commit('updateUserInfo', body.data.Result);
      commit('updateUserToken', {
        access: body.data.Result.Token,
        refresh: body.data.Result.Token,
      });
      commit(
        'updateUserType',
        body.data.Result.Roles.length > 0
          ? body.data.Result.Roles[0]
          : 'Unassigned'
      );
      resolve(body);
    });
  },

  updateUserType({ commit }, type) {
    commit('updateUserType', type);
  },
  updateChangePassword({ commit }, value) {
    commit('updateChangePassword', value);
  },

  updatePortalUserId({ commit }, id) {
    commit('updatePortalUserId', id);
  },
  updateIsPrevAdmin({ commit }, value) {
    commit('updateIsPrevAdmin', value);
  },
  logout({ commit }) {
    store.state.loadingStatus = true;
    AccountService.logout()
      .then((res) => {
        console.log('Logout res >> ', res);
      })
      .catch((err) => {
        console.log('Logout err >> ', err);
      })
      .then(() => {
        commit('authLogout', {});
        store.state.loadingStatus = false;
      });
  },

  async getUnseenCounts({ commit }) {
    await MessageServices.getUnSeenCount()
      .then((res) => {
        commit('updateUnseenCounts', res.data.Result || {});
      })
      .catch((err) => {
        window.showError(err);
      });
  },

  getMenuData({ commit }) {
    new Promise((resolve, reject) => {
      try {
        const data = [
          {
            Id: '617a8fe505e268a873562ffc',
            Name: 'Anasayfa',
            Title: 'Anasayfa',
            Key: 'dashboard',
            ShortDescription: null,
            LongDescription: 'test',
            MenuOrder: -10,
            ParentId: null,
            Icon: 'mdi-home',
            Type: 'static',
            Route: 'dashboard',
            CustomFields: [],
            CreatedAt: '2021-10-22T08:37:37.714Z',
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },
          {
            Id: '617a8fe505e268a873562f2c',
            Name: 'Şirket Bilgileri',
            Title: 'Şirket Bilgileri',
            Key: 'company',
            ShortDescription: null,
            LongDescription: 'test',
            MenuOrder: -10,
            ParentId: null,
            Icon: 'mdi-domain',
            Type: 'static',
            Route: 'company',
            CustomFields: [],
            CreatedAt: '2021-10-22T08:37:37.714Z',
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },
          /*{
            Id: "61727851720a496c5cbbee00",
            Name: "Aramıza Katılanlar",
            Title: "Aramıza Katılanlar",
            Key: "aramiza_katilanlar",
            ShortDescription: null,
            LongDescription: "test",
            MenuOrder: 0,
            ParentId: null,
            Icon: "mdi-abjad-arabic",
            Type: "custom",
            Route: "",
            CustomFields: [],
            CreatedAt: "2021-10-22T08:37:37.714Z",
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },*/
          {
            Id: '61727851720a496c5cbbee01',
            Name: 'Kategoriler',
            Title: 'Kategoriler',
            Key: 'Categories',
            ShortDescription: null,
            LongDescription: 'Kategoriler',
            MenuOrder: 0,
            ParentId: null,
            Icon: 'mdi-shape-plus-outline',
            Type: 'static',
            Route: 'Categories',
            CustomFields: [],
            CreatedAt: '2021-10-22T08:37:37.714Z',
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },
          {
            Id: '61727851720a496c5cbbee02',
            Name: 'Markalar',
            Title: 'Markalar',
            Key: 'brands',
            ShortDescription: null,
            LongDescription: 'Markalar',
            MenuOrder: 0,
            ParentId: null,
            Icon: 'mdi-tag',
            Type: 'static',
            Route: 'Brands',
            CustomFields: [],
            CreatedAt: '2021-10-22T08:37:37.714Z',
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },
          /*{
            Id: "61727c0c720a496c5cbbee02",
            Name: "Kurumsal Haberler",
            Title: "Kurumsal Haberler",
            Key: "kurumsal_haberler",
            ShortDescription: null,
            LongDescription: null,
            MenuOrder: 0,
            ParentId: null,
            Icon: "mdi-account-reactivate",
            Type: "custom",
            Route: "",
            CustomFields: [],
            CreatedAt: "2021-10-22T08:53:32.945Z",
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },*/
          {
            Id: '618a3512c05076d36c6b2437',
            Name: 'Siparişler',
            Title: 'Siparişler',
            Key: 'siparişler',
            ShortDescription: null,
            LongDescription: null,
            MenuOrder: 0,
            ParentId: null,
            Icon: 'mdi-shopping-search-outline',
            Type: 'static',
            Route: 'orders',
            CustomFields: [],
            CreatedAt: '2021-11-09T08:45:06.462Z',
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: true,
          },
          /*{
            Id: "61a4c2c94e1cabb0e3fe3e0c",
            Name: "Topluluk Uygulamaları",
            Title: null,
            Key: "other_applications",
            ShortDescription: null,
            LongDescription: "Topluluk uygulamaları için oluşturulan modül",
            MenuOrder: 0,
            ParentId: null,
            Icon: "mdi-arrow-top-right-thin-circle-outline",
            Type: "custom",
            Route: null,
            CustomFields: [],
            CreatedAt: "2021-11-29T12:08:41.312Z",
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },
          {
            Id: "61a9ba314fe09c940ac549ba",
            Name: "Bildirimler",
            Title: "Bildirimler",
            Key: "notifications",
            ShortDescription: null,
            LongDescription: null,
            MenuOrder: 0,
            ParentId: null,
            Icon: "mdi-bell-ring",
            Type: "static",
            Route: "notifications",
            CustomFields: [],
            CreatedAt: "2021-12-02T09:49:54.545Z",
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },
          {
            Id: "61ea9c859d2a76f8dd72b7e5",
            Name: "İlanlar",
            Title: null,
            Key: "ilanlar",
            ShortDescription: null,
            LongDescription: "İlanlar için oluşturulmuş Modül",
            MenuOrder: 0,
            ParentId: null,
            Icon: "mdi-airballoon",
            Type: "custom",
            Route: null,
            CustomFields: [],
            CreatedAt: "2022-01-21T11:44:04.961Z",
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },
          {
            Id: "62a1a8a26e569d6087ec1b63",
            Name: "Akademi",
            Title: null,
            Key: "akademi",
            ShortDescription: null,
            LongDescription: "Akademi/Eğitim için hazırlanmış modül",
            MenuOrder: 0,
            ParentId: null,
            Icon: "mdi-abjad-arabic",
            Type: "custom",
            Route: null,
            CustomFields: [],
            CreatedAt: "2021-11-30T12:45:48.301Z",
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },
          {
            Id: "6304cf673e8d917c047c7983",
            Name: "Raporlarrr",
            Title: null,
            Key: "reports",
            ShortDescription: null,
            LongDescription: "Raporlama için hazırlanmış modül",
            MenuOrder: 0,
            ParentId: null,
            Icon: "mdi-all-inclusive-box",
            Type: "static",
            Route: "statistics",
            CustomFields: [],
            CreatedAt: "2022-08-22T09:46:12.512Z",
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },
          {
            Id: "63a2cab1ff4239dab4d36b24",
            Name: "Döküman Atama",
            Title: null,
            Key: "LearningManagementSystem",
            ShortDescription: null,
            LongDescription: "",
            MenuOrder: 0,
            ParentId: null,
            Icon: "mdi-book",
            Type: "static",
            Route: "learningManagementSystem",
            CustomFields: [],
            CreatedAt: "2022-12-21T08:58:25.132Z",
            UpdatedAt: null,
            DeletedAt: null,
            Status: 0,
            IsAllGroups: false,
          },*/
        ];

        let items = [];
        data.forEach((element) => {
          let obj = {};
          if (element.Type == 'static') {
            //Sabit sayfalar
            obj = {
              label: element.Name,
              to: '/home/' + element.Route,
              icon: element.Icon,
            };
          } else {
            //Modül sayfalar , type = 'custom'
            obj = {
              label: element.Name,
              to: '/home/moduls/' + element.Id,
              icon: element.Icon,
            };
          }
          items.push(obj);
        });
        commit('setMenuItems', items);
      } catch (error) {
        reject(error);
      }
    });
    return;
    // FIXME: Sayfalar düzenlenecek.
    ModuleService.getMyPages()
      .then((res) => {
        let items = [];
        res.data.forEach((element) => {
          let obj = {};
          if (element.Type == 'static') {
            //Sabit sayfalar
            obj = {
              label: element.Name,
              to: '/home/' + element.Route,
              icon: element.Icon,
            };
          } else {
            //Modül sayfalar , type = 'custom'
            obj = {
              label: element.Name,
              to: '/home/moduls/' + element.Id,
              icon: element.Icon,
            };
          }
          items.push(obj);
        });
        commit('setMenuItems', items);
      })
      .catch((err) => {
        window.showError(err);
      })
      .then(() => {});
  },
};
