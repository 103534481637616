// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'supplier-price-offer';

export default {
  /**
   * Update supplier's price offer.
   * @param {any} body
   * @returns {Promise<any>}
   */
  updateSupplierPriceOffer(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  /**
   * Delete supplier's price offer.
   * @param {string} id
   * @returns {Promise<any>}
   */
  deleteSupplierPriceOffer(id) {
    return axios.delete(`${RESOURCE_NAME}/delete`, id);
  },

  /**
   * Get supplier's offers.
   * @param {string} spotShippingId
   * @param {string} transferType
   * @param {string} shipmentType
   * @param {string} loadType
   * @param {string} serviceType
   * @param {string} fromCountryId
   * @param {string} toCountryId
   * @param {string} page
   * @param {string} pageSize
   * @returns {Promise<any>}
   */
  getOffersForSuppliers(
    spotShippingId,
    transferType,
    shipmentType,
    loadType,
    serviceType,
    fromCountryId,
    toCountryId,
    customerCompanyName,
    page,
    pageSize
  ) {
    return axios.get(`${RESOURCE_NAME}/getForSupplier`, {
      params: {
        SpotShippingId: spotShippingId,
        TransportType: transferType,
        ShipmentType: shipmentType,
        LoadingType: loadType,
        ServiceType: serviceType,
        FromCountryId: fromCountryId,
        ToCountryId: toCountryId,
        CustomerCompany: customerCompanyName,
        page,
        pageSize,
      },
    });
  },

  /**
   * Get customer's offers.
   * @param {string} spotShippingId
   * @param {string} transferType
   * @param {string} shipmentType
   * @param {string} loadType
   * @param {string} serviceType
   * @param {string} serviceType
   * @param {string} fromCountryId // Unused
   * @param {string} toCountryId // Unused
   * @param {string} page
   * @param {string} pageSize
   * @returns {Promise<any>}
   */
  getOffersForCustomer(
    spotShippingId,
    transferType,
    shipmentType,
    loadType,
    serviceType,
    fromCountryId,
    toCountryId,
    customerCompanyName,
    page,
    pageSize
  ) {
    return axios.get(`${RESOURCE_NAME}/getForCustomer`, {
      params: {
        SpotShippingId: spotShippingId,
        TransportType: transferType,
        ShipmentType: shipmentType,
        LoadingType: loadType,
        ServiceType: serviceType,
        CustomerCompany: customerCompanyName,
        page,
        pageSize,
      },
    });
  },

  /**
   * Get supplier's offer by id.
   * @param {*} id
   * @returns {Promise<any>}
   */
  getSupplierPriceOfferById(id) {
    return axios.get(`${RESOURCE_NAME}/getById`, {
      params: {
        Id: id,
      },
    });
  },

  /**
   * Approve the price offer that is requested by customer.
   * @param {string} supplierPriceOfferId
   * @returns {Promise<any>}
   */
  approveOffer(supplierPriceOfferId) {
    return axios.put(`${RESOURCE_NAME}/approve-Offer`, {
      SupplierPriceOfferId: supplierPriceOfferId,
    });
  },
};
