// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
let RESOURCE_NAME = "warehouse";

export default {
  /**
   * Creates a new warehouse.
   * @param {*} body
   * @returns {Promise<any>}
   */
  add(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },

  /**
   * Update informations of warehouse.
   * @param {*} body
   * @returns {Promise<any>}
   */
  update(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  /**
   * Delete a warehouse.
   * @param {*} body
   * @returns {Promise<any>}
   */
  delete(warehouseId) {
    return axios.delete(`${RESOURCE_NAME}/delete?WarehouseId=${warehouseId}`);
  },

  /**
   * Get a specific warehouse.
   * @param {string} warehouseId
   * @returns {Promise<any>}
   */
  getById(warehouseId) {
    return axios.get(`${RESOURCE_NAME}/getById?WarehouseId=${warehouseId}`);
  },

  /**
   * Get a specific company marketplace's warehouse list.
   * @param {string} companyMarketplaceId
   * @returns {Promise<any>}
   */
  getByMarketId(companyMarketplaceId) {
    return axios.get(
      `${RESOURCE_NAME}/getById?CompanyMarketplaceId=${companyMarketplaceId}`
    );
  },

  /**
   * Get Warehouse List.
   * @returns {Promise<any>}
   */
  getAll() {
    return axios.get(
      `${RESOURCE_NAME}/getAll`
    );
  },
};
