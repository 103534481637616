<template>
  <v-card elevation="1" class="mt-0 py-lg-10" style="margin: 0 !important">
    <v-dialog v-model="addOutOfAreaModal" @input="resetModal" max-width="800">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-sm-center popupHeadline" style="width: 100%">
            {{ $t('labels.addNew') }}
          </div>
        </v-card-title>

        <v-divider class="mb-4" />

        <ValidationObserver
          ref="addChartEntryObserver"
          v-slot="{ handleSubmit }"
        >
          <form autocomplete="off" @submit.prevent="handleSubmit(add)">
            <div style="position: absolute; top: 12px; right: 10px">
              <v-btn
                @click="handleDownload()"
                class="rounded-circle"
                style="
                  min-width: 1px;
                  min-height: 1px;
                  width: 36px;
                  height: 36px;
                  background-color: #0177d4;
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      color="white"
                      style="font-size: 20px"
                      v-on="on"
                    >
                      mdi-file-check-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.sampleOutOfAreaExcelDownload') }}</span>
                </v-tooltip>
              </v-btn>
            </div>

            <v-card-text class="pb-0">
              <v-row>
                <FormElement
                  key="ENTRY_PORTAL_TYPE"
                  :vertical="true"
                  v-model="form.selectedLogisticType"
                  :validationLabel="$t('titles.subsupplier')"
                  :topLabel="true"
                  rules="required"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: logisticsTypes,
                      value: 'Value',
                      text: 'Name',
                    },
                  }"
                  :placeholder="$t('titles.subsupplier')"
                />
                <FormElement
                  key="ENTRY_OUTOFAREA"
                  :vertical="true"
                  v-model="form.outOfArea"
                  :validationLabel="$t('labels.outOfArea')"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :fileStyling="false"
                  :formType="{
                    name: 'file',
                    type: '',
                    data: {
                      accept:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    },
                  }"
                  :placeholder="$t('labels.outOfArea')"
                />
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pb-4">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;
                  padding: 2vh 2vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="$store.state.loadingStatus"
                >{{ $t('buttons.add') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 2vh 2vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="resetModal"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card-title style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="3" cols="12">
          <h5>{{ $t('navigationDrawerMenu.outOfAreaEntry') }}</h5>
        </v-col>
      </v-row>

      <v-icon
        v-if="this.isSupplier"
        small
        class="mr-6"
        style="font-size: 32px"
        @click="addOutOfAreaModal = true"
      >
        mdi-plus-box-outline
      </v-icon>
    </v-card-title>
    <v-divider />
    <v-data-table
      :headers="chartEntryHeaders"
      :items="items"
      hide-default-footer
      :loading="$store.state.loadingStatus"
      :item-key="'Id'"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:item.LogisticType="{ item }">
        <v-chip
          v-if="item.LogisticType == 1"
          style="font-weight: 600"
          class="ma-2"
          small
          color="#c5f4db"
          text-color="#2b3838"
        >
          FedEx
        </v-chip>
        <v-chip
          v-if="item.LogisticType == 2"
          style="font-weight: 600"
          class="ma-2"
          small
          color="#c5f4db"
          text-color="#2b3838"
        >
          UPS
        </v-chip>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>{{ $t('messages.noData') }}</h4>
        </div>
      </template>
      <!-- eslint-disable -->
      <template v-slot:item.actions="{ item }">
        <div class="text-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="#29B7B7"
                class="mr-1"
                style="font-size: 20px"
                v-bind="attrs"
                v-on="on"
                @click="
                  $router.push({ name: 'outOfAreaDetail', params: { item } })
                "
              >
                mdi-arrow-right
              </v-icon>
            </template>
            <span>{{ $t('buttons.goToDetails') }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import ExpressCargoServices from '../services/ExpressCargoServices';
import { logisticsTypes } from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';

export default {
  data() {
    return {
      addOutOfAreaModal: false,
      regionFile: null,
      chartEntryHeaders: [],
      items: [],
      logisticsTypes,
      form: {
        outOfArea: null,
        selectedLogisticType: null,
      },

      isSupplier: false,
    };
  },
  components: { FormElement },
  mounted() {
    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';

    this.setup();
  },

  methods: {
    handleDownload() {
      const downloadUrl =
        'https://algonder.s3.amazonaws.com/Documents/19.06.2024%2020:23:10Ornek_Uzak_Bolge.xlsx?AWSAccessKeyId=AKIA5TWV25RSSAFBF64U&Expires=1750353791&Signature=1zQx1FRVntvbW8yUev23dJnakg4%3D';

      window.open(downloadUrl, '_blank');
    },
    add() {
      this.$store.state.loadingStatus = true;
      const formData = new FormData();
      formData.append('LogisticType', this.form.selectedLogisticType);
      formData.append('File', this.form.outOfArea);

      ExpressCargoServices.addOutOfArea(formData)
        .then((response) => {
          window.showSuccess(this.$t('messages.successfulAddExpress'));
          this.form = {};
          this.addOutOfAreaModal = false;
          this.getData();
          this.resetModal();
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    getFormattedDate(date) {
      return formatDate(date, '/');
    },
    async setup() {
      this.getData();
      this.chartEntryHeaders = [
        {
          text: this.$t('title.tableHeaderCreateDate'),
          value: 'CreatedAt',
          sortable: true,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.subsupplier'),
          value: 'LogisticType',
          sortable: true,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.tableActions'),
          value: 'actions',
          sortable: false,
          groupable: false,
          align: 'center',
        },
      ];
    },
    getData() {
      this.$store.state.loadingStatus = true;
      ExpressCargoServices.getOutOfArea({})
        .then((response) => {
          this.items = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    resetModal() {
      this.$refs.addChartEntryObserver.reset();
      this.form = {
        outOfArea: null,
        selectedLogisticType: null,
      };
      this.addOutOfAreaModal = false;
    },
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.$store.state.loadingStatus = false;
      }
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
