import axios from 'axios'
import store from '../store/store'


//axios.defaults.headers.common['Json-Naming'] = 'camel_case'
export default axios.create({
  baseURL: process.env.VUE_APP_API_URL, // projenin ana dizininde .env.production ve .env.development dosyalarında VUE_APP_API_URL tanımlanmış olmalıdır.
  timeout: 40 * 1000,
  headers: {'X-Custom-Header': 'foobar'}
  // headers: {'Authorization':'headers'}

})
