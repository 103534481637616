<template>
  <fragment v-if="active">
    <AddVolume
      :onSave="onSaveVolumes"
      key="RAILWAY_ADD_VOLUME"
      :volumesByUser="convertedVolumes"
      v-if="addVolumeModal"
      :container-types="containerTypes"
      :fast-shipping-search-id="body.fastShippingSearchId"
      v-model="addVolumeModal"
      :read-only="demand"
      :calculateParameter="calculateParameter"
    />
    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="$t('messages.addVolumeWarning')"
      :confirm="confirmation.onConfirm"
      v-model="confirmation.visible"
    />
    <ValidationObserver ref="railwayObserver" v-slot="{ validate, invalid }">
      <form
        autocomplete="off"
        @submit.prevent="validate().then(createOffer(invalid, values))"
      >
        <v-row class="my-4">
          <v-col
            cols="12"
            v-if="
              ($store.state.Type === 'Customer' ||
                $store.state.Type === 'PendingCustomer') &&
              demand &&
              showDemandOptions
            "
            style="
              background-color: var(--demandBackground);
              padding: 30px;
              margin-bottom: 3%;
            "
          >
            <v-row>
              <FormElement
                v-for="(element, index) in form_demand_customer"
                :key="
                  'SUPPLIER_' +
                  element.key.parent +
                  '_' +
                  element.key.child +
                  '_' +
                  index
                "
                :vertical="element.vertical"
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :formLabel="$t(element.formLabel)"
                :onChange="element.onChange"
                :formType="element.formType"
                :cols="element.cols"
                :sm="element.sm"
                :md="element.md"
                :lg="element.lg"
                :light="true"
                :hidden="element.hidden"
                :labelShown="element.labelShown"
                :topLabel="element.topLabel"
                :active="element.active"
                :disabled="element.disabled"
                :placeholder="$t(element.placeholder)"
              />
              <v-col style="margin-top: 10%">
                <v-btn
                  style="
                    padding: 22px 40px !important;
                    border-radius: 12px !important;
                  "
                  elevation="0"
                  class="button flexible-button volumeButton"
                  dark
                  type="button"
                  @click="demandPrice(validate, invalid)"
                  :loading="$store.state.loadingStatus"
                >
                  {{
                    $t(
                      'buttons.' +
                        ($store.state.Type === 'Customer' ||
                        $store.state.Type === 'PendingCustomer'
                          ? targetPrice != null
                            ? 'updateOffer'
                            : 'demandPrice'
                          : 'giveCustomerAPrice')
                    )
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <FormElement
            v-for="(element, index) in form_types"
            :key="element.key.parent + '_' + element.key.child + '_' + index"
            :vertical="element.vertical"
            v-model="$data[element.key.parent][element.key.child]"
            :validationLabel="$t(element.validationLabel)"
            :rules="element.rules"
            :formLabel="$t(element.formLabel)"
            :onChange="element.onChange"
            :formType="element.formType"
            :cols="element.cols"
            :sm="element.sm"
            :md="element.md"
            :lg="element.lg"
            :light="true"
            :hidden="element.hidden"
            :labelShown="element.labelShown"
            :topLabel="element.topLabel"
            :active="element.active"
            :disabled="demand"
            :placeholder="$t(element.placeholder)"
          />
          <FormElement
            v-for="(element, index) in form_destination"
            :key="element.key.parent + '_' + element.key.child + '_' + index"
            :vertical="element.vertical"
            v-model="$data[element.key.parent][element.key.child]"
            :validationLabel="$t(element.validationLabel)"
            :rules="element.rules"
            :formLabel="$t(element.formLabel)"
            :onChange="element.onChange"
            :formType="element.formType"
            :cols="element.cols"
            :sm="element.sm"
            :md="element.md"
            :lg="element.lg"
            :light="true"
            :disabled="demand"
            :hidden="element.hidden"
            :labelShown="element.labelShown"
            :topLabel="element.topLabel"
            :active="element.active"
            :placeholder="$t(element.placeholder)"
          />
          <FormElement
            v-for="(element, index) in form_car"
            :key="element.key.parent + '_' + element.key.child + '_' + index"
            :vertical="element.vertical"
            v-model="$data[element.key.parent][element.key.child]"
            :validationLabel="$t(element.validationLabel)"
            :rules="element.rules"
            :formLabel="$t(element.formLabel)"
            :onChange="element.onChange"
            :formType="element.formType"
            :cols="element.cols"
            :sm="element.sm"
            :md="element.md"
            :lg="element.lg"
            :light="true"
            :hidden="element.hidden"
            :disabled="demand"
            :labelShown="element.labelShown"
            :topLabel="element.topLabel"
            :active="element.active"
            :placeholder="$t(element.placeholder)"
          />

          <FormElement
            v-for="(element, index) in form_door"
            :key="element.key.parent + '_' + element.key.child + '_' + index"
            :vertical="element.vertical"
            v-model="$data[element.key.parent][element.key.child]"
            :validationLabel="$t(element.validationLabel)"
            :rules="element.rules"
            :formLabel="$t(element.formLabel)"
            :onChange="element.onChange"
            :formType="element.formType"
            :cols="element.cols"
            :sm="element.sm"
            :md="element.md"
            :lg="element.lg"
            :light="true"
            :hidden="element.hidden"
            :labelShown="element.labelShown"
            :topLabel="element.topLabel"
            :active="element.active"
            :disabled="demand || element.disabled"
            :placeholder="$t(element.placeholder)"
          />
          <v-col
            v-if="
              item.loadType === 1 &&
              (!demand || (demand && item.volumes.length > 0))
            "
            lg="3"
            class="form-column d-flex justify-lg-center justify-xl-start"
            style="padding: 0; min-height: 86px"
          >
            <v-btn
              style="
                color: #fff;
                text-transform: unset !important;
                background-color: var(--primary);
                height: 65%;
                width: 100%;
                border-radius: 20px !important;
                font-weight: bold;
                font-size: small;
              "
              elevation="0"
              class="volumeButton"
              @click="addVolumeModal = true"
            >
              <p class="addPackageLabel mt-4">
                <v-icon>mdi-plus</v-icon>
                {{
                  $t(
                    'buttons.' + (demand ? 'viewPackageDetails' : 'addPackage')
                  ) +
                  '(' +
                  item.volumes?.length +
                  ')'
                }}
              </p>
            </v-btn>
          </v-col>
          <FormElement
            v-for="(element, index) in form_other"
            :key="element.key.parent + '_' + element.key.child + '_' + index"
            :vertical="element.vertical"
            v-model="$data[element.key.parent][element.key.child]"
            :validationLabel="$t(element.validationLabel)"
            :rules="element.rules"
            :formLabel="$t(element.formLabel)"
            :onChange="element.onChange"
            :formType="element.formType"
            :cols="element.cols"
            :sm="element.sm"
            :md="element.md"
            :lg="element.lg"
            :light="false"
            :hidden="element.hidden"
            :labelShown="element.labelShown"
            :topLabel="element.topLabel"
            :active="element.active"
            :placeholder="$t(element.placeholder)"
            :disabled="demand"
            v-if="item.selectedService"
          />
          <v-col
            v-if="!demand && item.selectedService"
            cols="12"
            sm="12"
            md="4"
            style="padding: 0"
            class="ml-auto d-flex justify-end"
          >
            <v-btn
              style="
                color: #fff;
                text-transform: unset !important;
                background-color: var(--primary);
                height: 100%;
                width: 100%;
                padding: 1vh 2vh;
                border-radius: 20px !important;
                font-weight: bolder;
                font-size: large;
              "
              elevation="0"
              type="submit "
              class="volumeButton"
              :loading="creatingOffer"
            >
              <p style="padding: 0px" class="getAnOfferButton mt-4">
                {{ $t('buttons.getAnOffer') }}
              </p>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            v-else-if="
              ($store.state.Type === 'Supplier' ||
                $store.state.Type === 'PendingSupplier') &&
              showDemandOptions
            "
            style="
              background-color: var(--demandBackground);
              padding: 30px;
              margin-top: 3%;
            "
          >
            <v-row>
              <v-col lg="12" sm="12" class="mb-6">
                <span
                  style="
                    font-weight: bold;
                    color: white;
                    text-decoration: underline;
                  "
                  v-if="item.searchPrice"
                  >{{ $t('messages.listedAlready', { price: item.searchPrice }) }}</span
                >
              </v-col>
              <FormElement
                v-for="(element, index) in form_demand_supplier"
                :key="
                  'SUPPLIER_' +
                  element.key.parent +
                  '_' +
                  element.key.child +
                  '_' +
                  index
                "
                :vertical="element.vertical"
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :formLabel="$t(element.formLabel)"
                :onChange="element.onChange"
                :formType="element.formType"
                :cols="element.cols"
                :sm="element.sm"
                :md="element.md"
                :lg="element.lg"
                :light="true"
                :hidden="element.hidden"
                :labelShown="element.labelShown"
                :topLabel="element.topLabel"
                :active="element.active"
                :disabled="element.disabled"
                :placeholder="$t(element.placeholder)"
              />

              <FormElement
                :key="'SUPPLIER_item_note_102321'"
                :vertical="true"
                v-model="item.supplierNote"
                :validationLabel="$t('labels.note')"
                :formLabel="$t('labels.note')"
                :formType="{
                  name: 'textarea',
                }"
                :lg="8"
                :cols="6"
                :labelShown="false"
                :topLabel="true"
                :light="true"
                :placeholder="$t('labels.note')"
              />
              <v-col lg="4" col="12">
                <v-row>
                  <FormElement
                    :key="'EXPIRY_DATE_RAILWAY'"
                    :vertical="true"
                    v-model="item.expiryDate"
                    :validationLabel="$t('labels.expiryDate')"
                    :formLabel="$t('labels.expiryDate')"
                    :formType="{
                      name: 'date',
                      dateMode: 'date',
                    }"
                    :lg="12"
                    :cols="12"
                    :labelShown="false"
                    :topLabel="true"
                    :light="true"
                    :placeholder="$t('labels.expiryDate')"
                  />
                  <v-col cols="12" lg="12">
                    <v-btn
                      style="
                        padding: 22px 40px !important;
                        border-radius: 12px !important;
                      "
                      elevation="0"
                      class="button flexible-button volumeButton"
                      dark
                      type="button"
                      @click="demandPrice(validate, invalid)"
                      :loading="$store.state.loadingStatus"
                    >
                      {{
                        $t(
                          'buttons.' +
                            ($store.state.Type === 'Customer' ||
                            $store.state.Type === 'PendingCustomer'
                              ? 'demandPrice'
                              : price != 0
                              ? 'updateOffer'
                              : 'giveCustomerAPrice')
                        )
                      }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </fragment>
</template>
<script>
import FormElement from '../../components/FormElement.vue';
import OfferServices from '../../services/OfferServices';
import ConfirmationModal from '../../components/General/ConfirmationModal.vue';
import SpotShippingServices from '../../services/SpotShippingServices';
import {
  getCountries,
  getCities,
  getCurrencyTypes,
  getRailways,
  getHazmatCodes,
  getEquipmentTypes,
  removeNullValues,
  convertVolumeToBoxes,
  convertBoxesToVolumes,
  convertToNumber
} from '../../utils/constants';
import AddVolume from './AddVolume.vue';

export default {
  constructor: () => {},
  name: 'Railway',
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    demandOnFinally: {
      type: Function,
      default: () => {},
    },
    showDemandOptions: {
      type: Boolean,
      default: true,
    },
    createOffer: {
      type: Function,
      default: () => {},
    },
    transferType: {
      type: Number,
      default: 1,
    },
    creatingOffer: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    body: {
      type: Object,
      default: () => null,
    },
    demand: {
      type: Boolean,
      default: false,
    },
  },
  components: { FormElement, AddVolume, ConfirmationModal },
  mounted() {
    this.setup();
  },
  data() {
    return {
      // List
      getDangerousMaterialLevels: [],
      countries: [],
      currencies: [],
      cities_fromWhere: [],
      cities_toWhere: [],
      containerTypes: [],
      railways: [],
      // Data
      item: {
        volumes: [],
      },

      // States
      shipmentType: 1,
      pageLoading: false,
      addVolumeModal: false,
      dateMenu: false,

      confirmation: {
        onConfirm: () => {},
        visible: false,
      },

      price: 0,
      targetPrice: 0,
      calculateParameter: 0,
    };
  },
  watch: {
    shipmentType() {
      this.resetForm();
    },
    'item.volume': function (newValue) {
      const totalVolume = newValue || 0,
        totalWeight = parseFloat(this.item.kilo) || 0;

      const calculatedVolumeWeight = parseFloat(
        totalVolume * this.calculateParameter
      ).toFixed(2);
      this.item.chargeableWeight =
        totalWeight > calculatedVolumeWeight
          ? totalWeight
          : calculatedVolumeWeight;
    },
    'item.kilo': function (newValue) {
      const totalVolume = this.item.volume || 0,
        totalWeight = parseFloat(this.item.kilo) || 0;

      const calculatedVolumeWeight = parseFloat(
        totalVolume * this.calculateParameter
      ).toFixed(2);
      this.item.chargeableWeight =
        totalWeight > calculatedVolumeWeight
          ? totalWeight
          : calculatedVolumeWeight;
    },
  },
  methods: {
    demandPrice(validate, invalid = false) {
      validate();
      if (!invalid) {
        this.$store.state.loadingStatus = true;
        if (
          this.$store.state.Type === 'Customer' ||
          this.$store.state.Type === 'PendingCustomer'
        ) {
          let request = null;
          if (this.add) {
            request = SpotShippingServices.addSpotShipping(
              this.item.fastShippingSearchId,
              convertToNumber(this.item.targetPrice),
              this.item.targetPriceCurrency,
              this.item.customerNote
            );
          } else if (this.item.spotShippingId) {
            request = SpotShippingServices.updateSpotShipping(
              this.item.spotShippingId,
              convertToNumber(this.item.targetPrice),
              this.item.targetPriceCurrency,
              this.item.customerNote
            );
          }
          try {
            request
              .then(() => {
                window.showSuccess(this.$t('messages.demandPriceSuccessfull'));
              })
              .catch((error) => {
                window.showError(error);
              })
              .finally(() => {
                this.$store.state.loadingStatus = false;
                if (this.demandOnFinally) {
                  this.demandOnFinally();
                }
              });
          } catch (error) {
            window.showError(this.$t('messages.unexpectedError'));
            this.$store.state.loadingStatus = false;
          }
        } else {
          OfferServices.updateSupplierPriceOffer({
            Id: this.item.id,
            Price: convertToNumber(this.item.price),
            CurrencyTypeId: this.item.priceCurrency,
            ExpiryDate: this.item.expiryDate,
            TransitTime: this.item.transferTime,
            Note: this.item.supplierNote,
          })
            .then(() => {
              window.showSuccess(this.$t('messages.demandPriceSuccessfull'));
            })
            .catch((error) => {
              window.showError(error);
            })
            .finally(() => {
              if (this.demandOnFinally) {
                this.demandOnFinally();
              }
            });
        }
      }
    },

    async setup() {
      this.getDangerousMaterialLevels.length == 0
        ? (this.getDangerousMaterialLevels = await getHazmatCodes())
        : null;

      if (this.currencies.length === 0) {
        this.currencies = await getCurrencyTypes();
      }

      if (this.body?.item) {
        this.item = { ...this.item, ...this.body.item };
        this.price = this.body.item.price;
        this.targetPrice = this.body.item.targetPrice;
        if (this.item.country_fromWhere && this.item.country_toWhere) {
          this.cities_fromWhere = await getCities(this.item.country_fromWhere);
          this.cities_toWhere = await getCities(this.item.country_toWhere);
        }

        this.updatePlaces('all');
      }
    },

    async updatePlaces(parameter = 'places') {
      if (parameter === 'places' || parameter === 'all') {
        if (this.railways.length === 0) {
          this.railways = await getRailways();
        }
        switch (this.item.selectedService) {
          case 1:
            this.countries.length === 0
              ? (this.countries = await getCountries())
              : null;
            break;
        }
      }
      if (parameter === 'equipments' || parameter === 'all') {
        if (this.containerTypes.length === 0) {
          this.containerTypes = await getEquipmentTypes({
            TransportationType: 4,
          });
        }
      }
    },
    /**
     * If user saves the volumes then this will be triggered.
     */
    onSaveVolumes(volumes, parameters) {
      const {
        totalContainerCount,
        totalVolume,
        totalKilo,
        totalInvoiceWeight,
        chargeableWeight,
      } = parameters;

      const updateVolumes = () => {
        this.item.volumes = volumes;
        this.item.doorCount = totalContainerCount;
        this.item.kilo = totalKilo;
        this.item.volume = totalVolume;

        this.item.chargeableWeight = chargeableWeight;
      };

      this.confirmation.onConfirm = () => {
        if (this.body?.fastShippingSearchId) {
          const converted = convertVolumeToBoxes(volumes);

          this.$store.state.loadingStatus = true;
          SpotShippingServices.updateBoxes({
            FastShippingSearchId: this.body.fastShippingSearchId,
            boxViewModels: converted,
            ChargeableWeight: parseFloat(chargeableWeight),
          })
            .then((response) => {
              updateVolumes();
              window.showSuccess(this.$t('messages.updateBoxesSuccessfully'));
            })
            .catch(window.showError)
            .finally(() => (this.$store.state.loadingStatus = false));
        } else {
          updateVolumes();
        }
      };

      if (
        (parseInt(this.item.doorCount) !== totalContainerCount ||
          parseFloat(this.item.kilo).toFixed(2) !== totalKilo ||
          parseFloat(this.item.volume).toFixed(2) !== totalVolume) &&
        this.item.doorCount &&
        this.item.kilo &&
        this.item.volume
      ) {
        this.confirmation.visible = true;
        return;
      } else {
        this.confirmation.onConfirm();
      }
    },

    /**
     * Resetting forms' validation.
     */
    async resetForm() {
      this.item = {};
      this.item.volumes = [];
      this.$refs.railwayObserver.reset();
      this.setup();
    },
  },
  computed: {
    convertedVolumes() {
      if (this.item.volumes.length > 0 && this.demand) {
        if (this.item.volumes[0].Id) {
          return convertBoxesToVolumes(
            this.item.volumes,
            this.body.shipmentType
          );
        }
      }
      return this.item.volumes;
    },
    values() {
      return removeNullValues(this.item);
    },
    serviceTypes() {
      switch (this.item.loadType) {
        case 1:
          return [
            {
              Id: 1,
              Name: this.$t('labels.doorToDoor'),
            },
          ];
        case 2:
          return [
            {
              Id: 1,
              Name: this.$t('labels.doorToDoor'),
            },
            {
              Id: 2,
              Name: this.$t('labels.stationToStation'),
            },
          ];
        default:
          return [];
      }
    },
    form_types() {
      return [
        {
          key: {
            parent: 'item',
            child: 'loadType',
          },
          rules: 'required',
          validationLabel: 'labels.loadType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.$root.loadTypeFor.railway,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-earth',
          },
          onChange: () => {
            this.updatePlaces('equipments');

            if (this.item.selectedService) {
              this.item.selectedService = -1;
            }
          },
          lg: 6,
          placeholder: 'labels.loadType',
        },
        {
          key: {
            parent: 'item',
            child: 'selectedService',
          },
          rules: 'required',
          validationLabel: 'labels.serviceType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.serviceTypes,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-train',
          },
          onChange: () => {
            this.item = {
              ...this.item,
              selectedService: this.item.selectedService,
              loadType: this.item.loadType,
            };

            this.updatePlaces();
          },
          lg: 6,
          placeholder: 'labels.serviceType',
        },
      ];
    },
    form_destination() {
      return [
        {
          key: {
            parent: 'item',
            child: 'station_fromWhere',
          },
          rules: this.item.selectedService === 2 ? 'required' : '',
          validationLabel: 'labels.fromStation',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.railways,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-bus-stop',
          },
          active: !!this.item.selectedService,
          lg: 6,
          cols: 6,
          placeholder: 'labels.fromStation',
        },
        {
          key: {
            parent: 'item',
            child: 'station_toWhere',
          },
          rules: this.item.selectedService === 2 ? 'required' : '',
          validationLabel: 'labels.toStation',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.railways,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-bus-stop',
          },
          active: !!this.item.selectedService,
          cols: 6,
          lg: 6,
          placeholder: 'labels.toStation',
        },
        {
          key: {
            parent: 'item',
            child: 'country_fromWhere',
          },
          rules: 'required',
          validationLabel: 'labels.fromWhere',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countries,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-flag-variant',
          },
          onChange: async () => {
            if (this.item.country_fromWhere)
              this.cities_fromWhere = await getCities(
                this.item.country_fromWhere
              );
          },
          active: this.item.selectedService === 1,
          cols: 12,
          lg: 6,
          placeholder: 'labels.fromWhere',
        },
        {
          key: {
            parent: 'item',
            child: 'city_fromWhere',
          },
          rules: 'required',
          validationLabel: 'labels.fromWhereCity',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.cities_fromWhere,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-flag-variant',
          },
          active: this.item.selectedService === 1,
          lg: 4,
          cols: 6,
          placeholder: 'labels.city',
        },
        {
          key: {
            parent: 'item',
            child: 'postalCode_fromWhere',
          },
          rules: 'required|min:4|max:9',
          validationLabel: 'labels.postalCode_from',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-mailbox-open-outline',
          },
          active: this.item.selectedService === 1,
          lg: 2,
          cols: 6,
          placeholder: 'labels.postalCode',
        },
        {
          key: {
            parent: 'item',
            child: 'country_toWhere',
          },
          rules: 'required',
          validationLabel: 'labels.toWhere',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countries,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-flag-variant',
          },
          onChange: async () => {
            if (this.item.country_toWhere)
              this.cities_toWhere = await getCities(this.item.country_toWhere);
          },
          active: this.item.selectedService === 1,
          lg: 6,
          cols: 12,
          placeholder: 'labels.toWhere',
        },
        {
          key: {
            parent: 'item',
            child: 'city_toWhere',
          },
          rules: 'required',
          validationLabel: 'labels.city',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.cities_toWhere,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-flag-variant',
          },
          active: this.item.selectedService === 1,
          lg: 4,
          cols: 6,
          placeholder: 'labels.city',
        },
        {
          key: {
            parent: 'item',
            child: 'postalCode_toWhere',
          },
          rules: 'required|min:4|max:9',
          validationLabel: 'labels.postalCode_to',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-mailbox-open-outline',
          },
          active: this.item.selectedService === 1,
          lg: 2,
          cols: 6,
          placeholder: 'labels.postalCode',
        },
      ];
    },
    form_car() {
      return [
        {
          key: {
            parent: 'item',
            child: 'carType',
          },
          rules: 'required',
          validationLabel: 'labels.carType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.containerTypes,
              value: 'Code',
              text: 'Code',
            },
            prepend: 'mdi-train-car-container',
          },
          active: this.item.loadType === 2,
          lg: 6,
          cols: 6,
          placeholder: 'labels.carType',
        },
        {
          key: {
            parent: 'item',
            child: 'carCount',
          },
          rules: 'required|numeric|min_value:1',
          validationLabel: 'labels.carCount',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-train-car-container',
          },
          active: this.item.loadType === 2,
          lg: 6,
          cols: 6,
          placeholder: 'labels.carCount',
        },
      ];
    },
    form_door() {
      return [
        {
          key: {
            parent: 'item',
            child: 'doorCount',
          },
          rules: 'required|numeric|min_value:1',
          validationLabel: 'labels.doorCount',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-package-variant',
          },
          active: this.item.loadType === 1,
          lg: 2,
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          placeholder: 'labels.doorCount',
        },
        {
          key: {
            parent: 'item',
            child: 'kilo',
          },
          rules: 'required|double|min_value:0',
          validationLabel: 'labels.kilo',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-weight-kilogram',
          },
          active: this.item.loadType === 1,
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          placeholder: 'labels.kilo',
        },
        {
          key: {
            parent: 'item',
            child: 'volume',
          },
          rules: 'required|double|min_value:0',
          validationLabel: 'labels.volume',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-package',
          },
          active: this.item.loadType === 1,
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          placeholder: 'labels.volume',
        },
        {
          key: {
            parent: 'item',
            child: 'chargeableWeight',
          },
          rules: 'required|double|min_value:0',
          validationLabel: 'labels.chargeableWeight',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          active: this.item.loadType === 1,
          disabled: true,
          placeholder: 'labels.chargeableWeight',
        },
      ];
    },
    form_other() {
      return [
        {
          key: {
            parent: 'item',
            child: 'isDangerousMaterial',
          },
          validationLabel: 'labels.isDangerousMaterial',
          formType: {
            name: 'checkbox',
          },
          lg: 3,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.isDangerousMaterial',
        },
        {
          key: {
            parent: 'item',
            child: 'dangerousMaterialLevel',
          },
          rules: !!this.item.isDangerousMaterial ? 'required' : '',
          validationLabel: 'labels.dangerousMaterialLevel',
          labelShown: false,
          topLabel: true,
          formType: {
            name: 'select',
            items: {
              data: this.getDangerousMaterialLevels,
              value: 'key',
              text: 'Value',
            },
          },
          lg: 9,
          active: !!this.item.isDangerousMaterial,
          cols: 12,
          placeholder: 'labels.dangerousMaterialLevel',
        },
        {
          key: {
            parent: 'item',
            child: 'willNotBeStacked',
          },
          validationLabel: 'labels.myLoadWillNotBeStacked',
          formType: {
            name: 'checkbox',
          },
          labelShown: false,
          topLabel: true,
          cols: 12,
          sm: 12,
          md: 6,
          lg: 6,
          placeholder: 'labels.myLoadWillNotBeStacked',
        },
      ];
    },
    form_demand_supplier() {
      return [
        {
          key: {
            parent: 'item',
            child: 'targetPrice',
          },
          rules:
            this.$store.state.Type === 'Customer' ||
            this.$store.state.Type === 'PendingCustomer'
              ? 'numeric|min_value:1'
              : '',
          validationLabel: 'labels.targetPrice',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            backgroundColor: 'var(--disabledGray)',
          },
          lg: 3,
          cols: 3,
          disabled: true,
          placeholder: 'labels.targetPrice',
        },
        {
          key: {
            parent: 'item',
            child: 'price',
          },
          rules: 'required|numericWithDelimiters',
          validationLabel: 'labels.yourOffer',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            maskType: 'money',
          },
          lg: 3,
          cols: 3,
          placeholder: 'labels.yourOffer',
        },
        {
          key: {
            parent: 'item',
            child: 'priceCurrency',
          },
          rules: 'required',
          validationLabel: 'labels.yourOfferCurrency',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.currencies,
              value: 'Id',
              text: 'Code',
            },
          },
          lg: 3,
          cols: 3,
          placeholder: 'labels.yourOfferCurrency',
        },
        {
          key: {
            parent: 'item',
            child: 'transferTime',
          },
          rules: 'required|numeric|min_value:1',
          validationLabel: 'titles.transferTime',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 3,
          cols: 3,
          placeholder: 'titles.transferTime',
        },
      ];
    },
    form_demand_customer() {
      return [
        {
          key: {
            parent: 'item',
            child: 'targetPrice',
          },
          rules: 'numericWithDelimiters',
          validationLabel: 'labels.targetPrice',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            maskType: 'money',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.targetPrice',
        },
        {
          key: {
            parent: 'item',
            child: 'targetPriceCurrency',
          },
          rules: '',
          validationLabel: 'labels.currency',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.currencies,
              value: 'Id',
              text: 'Code',
            },
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.currency',
        },
        {
          key: {
            parent: 'item',
            child: 'customerNote',
          },
          validationLabel: 'labels.note',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 8,
          cols: 6,
          placeholder: 'labels.note',
        },
      ];
    },
  },
};
</script>
<style></style>
