import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '';

export default {
  signContract(body) {
    return axios.post(`contract/sign`, body);
  },
  getById(id) {
    return axios.get(`contract/getById/`, {
      params: {
        id: id,
      },
    });
  },
  createContract(body) {
    return axios.post(`contract/createShippingContract`, body);
  },
};
