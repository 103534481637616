// Örnek servis codiagno login
import axios from "../javascript/custom-axios";
let RESOURCE_NAME_MANUAL = "ExchangeRateManual", SERVICE_NAME = "ExchangeRate";

export default {
  /**
   * Creates a new exchange rate.
   * @param {*} body
   * @returns {Promise<any>}
   */
  add(body) {
    return axios.post(`${RESOURCE_NAME_MANUAL}/add`, body);
  },

  /**
   * Update data of an exchange rate.
   * @param {*} body
   * @returns {Promise<any>}
   */
  update(body) {
    return axios.put(`${RESOURCE_NAME_MANUAL}/update`, body);
  },

  /**
   * Delete an exhange rate.
   * @param {*} body
   * @returns {Promise<any>}
   */
  delete(body) {
    return axios.delete(`${RESOURCE_NAME_MANUAL}/delete`, body);
  },

  /**
   * It returns exhange rate list by filter.
   * @param {{ Code: string, ValidityDate: string }} parameters
   * @returns {Promise<any>}
   */
  getAll(parameters = {}) {
    return axios.get(`${RESOURCE_NAME_MANUAL}/getAll`, {
      params: parameters
    });
  },

  /**
   * Get the price after exchanging rate.
   * @param {{From: string, To: string, Price: number}} parameters
   * @returns {Promise<any>}
   */
  getExchangedPrice(parameters) {
    return axios.get(`${RESOURCE_NAME_MANUAL}/getById`, {
      params: parameters
    });
  },

  /**
   * Get the price after exchanging rate.
   * @param {{From: string, To: string, Price: number}} parameters
   * @returns {Promise<any>}
   */
  getExchangeRateList(parameters) {
    return axios.get(`${SERVICE_NAME}/allExchangeRates`, {
      params: parameters
    });
  },
};
