<template>
  <div>
    <v-card-title class="popupHeadline">
      <v-row>
        <v-row class="pl-2 py-2"
          ><p class="clickable-header" @click="navigateBack()">
            {{ $t('buttons.backNavigate') }}
          </p>
        </v-row>
      </v-row>
    </v-card-title>
    <v-card elevation="2" style="border-radius: 0">
      <v-img
        :src="
          user.PhotoUrl ||
          'https://kinsta.com/wp-content/uploads/2021/05/there-has-been-a-critical-error-on-this-website-featured-image.jpeg'
        "
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <!-- eslint-disable-next-line  -->
        <v-card-title v-text="item.FunctionName"></v-card-title>
        <!-- eslint-disable-next-line  -->
        <v-card-subtitle style="color: #fff" v-text="item.Id"></v-card-subtitle>
      </v-img>
      <v-card-title class="popupHeadline">
        <v-row class="d-flex align-center">
          <v-col
            class="d-flex justify-lg-start justify-center"
            cols="12"
            lg="6"
          >
            <h6 class="align-self-center mr-4">{{ $t('titles.tags') }}:</h6>
            <v-chip-group active-class="primary--text" column>
              <v-chip color="green" text-color="white" key="HeaderValue">
                {{ item.HeaderValue }}
              </v-chip>
              <v-chip color="orange" text-color="white" key="SystemTypeValue">
                {{ item.SystemTypeValue }}
              </v-chip>
              <v-chip color="red" text-color="white" key="LogLevelValue">
                {{ item.LogLevelValue }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col class="d-flex justify-lg-end justify-center" cols="12" lg="6">
            <div style="font-size: 18px" class="align-self-center">
              {{ formatDate(item.CreatedAt) }}
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />

      <v-card-title>
        <div style="width: 100%" class="d-flex">
          <h3>{{ $t('titles.user') }}</h3>
        </div>
      </v-card-title>
      <v-card-text style="padding: 0 !important">
        <json-viewer :value="user" copyable sort theme="custom"> </json-viewer>
      </v-card-text>

      <v-card-title>
        <div style="width: 100%" class="d-flex">
          <h3>{{ $t('titles.output') }}</h3>
          <v-spacer />
          <h3>{{ $t('titles.line') + ': ' + item.LineNumber }}</h3>
        </div>
      </v-card-title>
      <v-card-text style="padding: 0 !important">
        <json-viewer
          :value="JSON.parse(item.DetailObject)"
          copyable
          sort
          theme="custom"
        >
        </json-viewer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PackageBasketServices from '../services/PackageBasketServices';
import LogServices from '../services/LogServices';
import formatDate from '../utils/formatDate';
import JsonViewer from 'vue-json-viewer';
import UserService from '../services/UserService';

export default {
  data: () => ({
    range: ['', ''],
    dateMenu: null,
    colors: [
      '#FFF200',
      '#EB3324',
      '#00129A',
      '#99D9EA',
      '#75163F',
      '#4C7570',
      '#FF2BBF',
      '#872B7D',
      '#FFA923',
      '#3AFF59',
    ],

    // General Models
    isLoading: false,
    isUpdate: false,
    file: null,
    step: 1,
    discount: 0,

    itemId: '',
    item: {
      DetailObject: '{}',
    },
    user: {},
    model: null,
    search: null,
    items: [],
    orders: [],
    deletedId: -1,
    marketplaces: [],

    buyPackageModal: false,

    // List options
    options: {
      itemsPerPage: 10,
    },
    headers: [],
    logHeaders: [],
    headerValue: '',
    //Loading states
    loadings: {
      send: false,
      item: false,
    },
    pagination: {},
    itemsPerPage: 10,
  }),

  computed: {
    selectedDateRange() {
      let temporaryRange = [];
      if (this.range[0] >= this.range[1]) {
        temporaryRange.push(this.range[0]);
        temporaryRange.push(this.range[1]);
      } else {
        temporaryRange.push(this.range[1]);
        temporaryRange.push(this.range[0]);
      }
      const startDate = this.range[0] || formatDate();
      const endDate = this.range[1] || formatDate();
      return startDate + ' - ' + endDate;
    },
    itemForm() {
      return [
        {
          key: {
            parent: 'item',
            child: 'BoughtMarketplaces',
          },
          rules: 'requiredArray',
          validationLabel: 'labels.marketplace.title',
          vertical: false,
          formLabel: 'labels.marketplace.title',
          formType: {
            name: 'multipleSelect',
            items: {
              specialized: true,
              data: this.marketplaces,
              value: 'Id',
              text: 'Name',
              subtitle: 'Price',
              avatar: 'PhotoUrl',
            },
          },
          placeholder: 'labels.marketplace.title',
        },
      ];
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    autoCompleteItems() {
      return this.logHeaders.map((entry) => {
        return Object.assign({}, entry, {
          Id: entry.Key,
          Name: entry.Value,
        });
      });
    },
  },

  watch: {
    search(val) {
      // Items have already been loaded
      if (this.autoCompleteItems.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.getLogHeaders();
    },
  },
  mounted() {
    this.itemId = this.$route.params.id;
    this.setup();
  },
  components: {
    JsonViewer,
  },
  methods: {
    /**
     * Navigates detail page of log.
     * @param {*} id
     */
    viewLog(id) {},
    /**
     * Like a bridge between formatDate function and object.
     */
    formatDate(date) {
      if (date) {
        return formatDate(new Date(date), '.');
      }
    },

    /**
     * Get Order List
     */
    async getOrders() {
      await PackageBasketServices.getPackageOrders({
        Page: this.pagination.page,
        Size: this.pagination.rowsPerPage,
      })
        .then((res) => {
          this.orders = res.data.Result.Orders;
          this.pagination.totalItems = res.data.Result.TotalOrderCount;
          this.discount = res.data.Result.DiscountRate;
        })
        .catch((err) => {
          window.handleServiceError(err);
        });
    },

    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getItem();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getItem();
    },

    async setup() {
      this.$store.state.loadingStatus = true;
      this.pagination = {
        totalItems: 0,
        rowsPerPage: 10,
        page: 1,
      };
      this.headers = [
        {
          text: this.$t('labels.type', { value: 'Sistem' }),
          value: 'SystemType',
          sortable: false,
          groupable: false,
        },
        {
          text: this.$t('labels.level', { value: 'Log' }),
          value: 'LogLevelValue',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderCreateDate'),
          value: 'CreatedAt',
          sortable: false,
          groupable: false,
          align: 'center',
        },
      ];
      await this.getItem();
      this.getUser();
      this.onFinal();
    },

    /**
     * Get Log Headers From Service
     */
    async getLogHeaders() {
      await LogServices.getHeaders({
        Value: this.headerValue || null,
      })
        .then((res) => {
          this.logHeaders = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
        });
    },

    //Clears all modal's states and item ID
    clearModal() {
      setTimeout(() => {
        this.resetForm();
        this.itemId = '';
        this.item = {
          DetailObject: '{}',
        };
      }, 500);
    },

    /**
     * Change active item of the page.
     * @param {*} item
     */
    async changeActiveItem(item) {
      this.itemId = item.Key;
      this.item = item;
    },

    /**
     * Get item detail.
     */
    async getItem() {
      this.$store.state.loadingStatus = true;
      await LogServices.getLogById(this.itemId)
        .then((res) => {
          this.item = res.data.Result;

          if (!this.item) {
            this.$router.push({ name: 'logs' });
          }
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Get user detail.
     */
    async getUser() {
      if (this.item.RelatedUserId) {
        await UserService.getUserDetail(this.item.RelatedUserId)
          .then((res) => {
            this.user = res.data.Result;
          })
          .catch((err) => {
            window.handleServiceError(err);
          });
      }
    },

    /**
     * Final Function Of Services
     */
    onFinal() {
      this.$store.state.loadingStatus = false;
    },
    /**
     * Navigates page back to log page
     */
    navigateBack() {
      this.$router.push({ name: 'logs' });
    },
  },
};
</script>

<style>
.form-input-item {
  text-align: center;
  width: 100%;
  padding: 10px 12px;
  border-radius: 4px;
  min-height: 56px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  font-size: 16px;
  color: #245c9e !important;
}
.custom {
  font-size: 20px;
  padding: 16px;
  background-color: rgb(245, 246, 246);
}

.jv-button {
  background-color: rgb(138, 138, 142);
  color: #fff;
  padding: 10px 20px !important;
  border-radius: 20px;
}
</style>
