<template>
  <v-card elevation="1" class="mt-0" style="margin: 0 !important">
    <delete-modal :yesFunc="deleteSelectedItems" ref="deleteModalRef" />

    <v-dialog v-model="showCreateOffer" max-width="800px">
      <CreateOfferForm :add="true" />
    </v-dialog>

    <div class="flex w-full rounded-tl-sm rounded-tr-sm">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col class="text-lg-left text-center" lg="5" cols="12">
            <h3>{{ $t('titles.searchHistory') }}</h3>
          </v-col>
        </v-row>
        <RefreshButton :refreshFunction="getHistory"></RefreshButton>
        <!-- @click="deleteSelectedItems" -->
        <v-btn
          elevation="0"
          v-if="selectedItems.length > 0"
          style="color: white !important"
          color="#FB773D"
          text-color="white"
          class="button ml-2"
          @click="openDeleteModal()"
          :loading="$store.state.loadingStatus"
        >
          {{ $t('buttons.deleteSelected') }}
        </v-btn>
      </v-card-title>
    </div>

    <v-divider />

    <v-data-table
      :headers="searchHistoryHeaders"
      hide-default-footer
      :loading="$store.state.loadingStatus"
      :options="{
        itemsPerPage: itemsPerPage,
      }"
      :items="filteredItems"
      show-select
      :item-key="'Id'"
      v-model="selectedItems"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:item.FromToWhere="{ item }">
        <div style="white-space: normal; word-wrap: break-word">
          {{ item.From }}
        </div>
        <span>-</span>
        <div style="white-space: normal; word-wrap: break-word">
          {{ item.To }}
        </div>
      </template>
      <template v-slot:item.ShipmentType="{ item }">
        <div style="font-weight: bold !important">
          {{ getShipmentType(item) }}
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-center mt-8">
          <v-row align="center" justify="center">
            <v-col
              v-if="
                $vuetify.breakpoint.md ||
                $vuetify.breakpoint.lg ||
                $vuetify.breakpoint.xl
              "
              md="2"
            ></v-col>
            <v-col cols="9" sm="12" md="7" lg="7" xl="7">
              <v-pagination
                v-if="pageLength > 0"
                class="my-4"
                @input="onPageChange"
                :value="page"
                :length="pageLength"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col
              cols="11"
              sm="11"
              md="3"
              lg="3"
              xl="3"
              class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
            >
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                hide-details
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>{{ $t('messages.noSearch') }}</h4>
          <v-btn
            style="color: white; background-color: var(--darkBlue)"
            class="button my-2"
            @click="showCreateOffer = true"
          >
            {{ $t('buttons.search') }}
            <v-icon dark right> mdi-magnify </v-icon>
          </v-btn>
        </div>
      </template>
      <!-- eslint-disable -->

      <template v-slot:item.createdDate="{ item }">
        {{ item.CreatedOnUtc }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="#29B7B7"
              class="mr-1"
              style="font-size: 24px"
              v-bind="attrs"
              v-on="on"
              @click="
                $router.push({
                  name: 'offerResultsById',
                  params: { id: item.Id },
                })
              "
            >
              mdi-magnify
            </v-icon>
          </template>
          <span>{{ $t('buttons.searchAgain') }}</span>
        </v-tooltip>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="color: white; background-color: #03a6ad"
              class="button my-2"
              v-bind="attrs"
              v-on="on"
              @click="
                $router.push({
                  name: 'offerResultsById',
                  params: { id: item.Id },
                })
              "
            >
              {{ $t('buttons.searchAgain') }}
            </v-btn>
          </template>
          <span>{{ $t('buttons.searchAgain') }}</span>
        </v-tooltip> -->
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import SearchServices from '../services/SearchServices';
import RefreshButton from '../components/RefreshButton.vue';
import {
  ITEMS_PER_PAGE_OPTIONS,
  triggerLoading,
  containerTypes,
  truckTypes,
  carriageTypes,
} from '../utils/constants';
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import DeleteModal from '../components/General/DeleteModal';

export default {
  data() {
    return {
      showCreateOffer: false,
      openDeleteDialog: false,
      offerType: 1,
      searchHistoryHeaders: [
        {
          text: this.$t('titles.shipmentType'),
          value: 'ShipmentType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.loadType'),
          value: 'LoadingType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.from') + '/' + this.$t('titles.to'),
          value: 'FromToWhere',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
          width: '20%',
        },
        {
          text: this.$t('titles.load'),
          value: 'Load',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.dateAsNoun'),
          value: 'createdDate',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          groupable: false,
        },
      ],
      searchHistoryItems: [],

      // States
      pageLoading: false,
      itemsPerPageOptions: [],
      selectedItems: [],
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      showCreateOffer: false,
      deleteId: null,
    };
  },
  components: { CreateOfferForm, FormElement, RefreshButton, DeleteModal },
  mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();
  },
  computed: {
    selectedFastShipments() {
      return this.selectedItems.flatMap((item) => item.Id);
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    filteredItems() {
      let items = [];
      this.searchHistoryItems.forEach((element, index) => {
        let object = element;
        object.ShipmentType =
          this.$root.shipmentTypes.find(
            (item) => item.Id === object.ShipmentType
          )?.Name || 'No Transfer';
        object.LoadingType =
          this.$root.loadTypes.find((item) => item.Id === object.LoadingType)
            ?.Name || 'No Transfer';

        if (object.SumCapPieces && object.SumVolume && object.SumWeight) {
          object.load = `${object.SumCapPieces || 0} x ${
            object.SumVolume || 0
          }m³ x ${object.SumWeight | 0} kg `;
        } else if (object.ContainerCount && object.ContainerType) {
          object.load = `${object.ContainerCount} x ${
            containerTypes.find((item) => item.Id === object.ContainerType).Name
          }`;
        } else if (object.TruckCount && object.TruckType) {
          object.load = `${object.TruckCount} x ${
            truckTypes.find((item) => item.Id === object.TruckType).Name
          }`;
        } else if (object.CarriageCount && object.CarriageType) {
          object.load = `${object.CarriageCount} x ${
            carriageTypes.find((item) => item.Id === object.CarriageType).Name
          }`;
        }
        items.push(object);
      });

      return items;
    },
  },
  watch: {},
  methods: {
    openDeleteModal() {
      this.$refs.deleteModalRef.modalStatus = true;
    },
    /**
     * @param {List} Ids
     * @returns {string}
     */
    deleteSelectedItems() {
      let body = {
        Ids: this.selectedFastShipments,
      };
      let value =
        this.selectedFastShipments.length > 1
          ? this.$t('labels.pastResearchPlural')
          : this.$t('labels.pastResearchSingular');
      triggerLoading();
      SearchServices.archiveFastShipping(body)
        .then((response) => {
          this.selectedItems = [];
          window.showSuccess(
            this.$t('messages.successfulDelete', { value: value })
          );
        })
        .catch((error) => {
          this.$refs.deleteModalRef.modalStatus = false;
        })
        .then(() => {
          triggerLoading();
        })
        .finally(() => {
          this.getHistory();
          this.$refs.deleteModalRef.modalStatus = false;
        });
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getHistory();
    },
    /**
     * It will be triggered when offer type change in select that is upper right.
     */
    onOfferTypeChange() {},

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },
    /**
     * Goes to back for changing search parameters.
     */
    changeTheSearch() {
      this.$router.push({
        name: 'createOffer',
        params: {
          ...this.$route.params,
        },
      });
    },
    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {
      this.page = newPage;
      this.getHistory();
    },

    setup() {
      this.getHistory();
    },

    /**
     * Get search history from service.
     */
    getHistory() {
      triggerLoading();
      SearchServices.getFastShippingSearch(this.page, this.itemsPerPage)
        .then((response) => {
          this.searchHistoryItems = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {})
        .finally(() => {
          triggerLoading();
        });
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      this.resetForm();
    },
    /**
     * Save the volumes.
     */
    save() {
      this.onSave(this.volumes);
      this.openModal = false;
    },
    /**
     * Deletes an item from volumes.
     */
    deleteItem(index) {
      this.volumes = this.volumes.filter((item) => item.index !== index);
    },
    /**
     * Calculate prices in the list.
     * @param {array} items
     */
    calculatePrice(items, key) {
      let total = 0;
      if (items) {
        items.map((item) => (total += parseFloat(item[key])));
      }
      return total;
    },

    /**
     * Add a new volume.
     */
    addVolume() {
      this.$store.state.loadingStatus = true;
      setTimeout(() => {
        this.volumeItem.index = this.volumes.length;
        this.volumes.unshift(this.volumeItem);
        this.$refs.addVolumeObserver.reset();
        this.volumeItem = {};
        window.showSuccess(
          this.$t('messages.successfulAdd', {
            value: this.$t('labels.volume').replaceAll('(m3)', ''),
          })
        );
        this.$store.state.loadingStatus = false;
      }, 1000);
    },
    getShipmentType(item) {
      let type = item.ShipmentType;
      if (item.TransportType == 2) {
        type = item.ShipmentType + ' (Express)';
      }
      return type;
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.item-header {
  color: var(--turquoise) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
