<template>
  <div>
    <delete-modal :yesFunc="confirmDelete" ref="deleteModalRef" />
    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="$t('messages.closeTicket')"
      :confirm="closeTicket"
      v-model="closeTicketModal"
    />
    <TabScreen
      v-if="!detailsDialog"
      :slots="slots"
      style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px"
    >
      <template v-slot:ticket v-if="!isAuth">
        <v-card
          style="
            padding: 2vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          key="SUPPORT_NEW_TICKET"
        >
          <ValidationObserver ref="fieldObserver" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(sendDemand)">
              <v-row class="mt-4">
                <FormElement
                  v-model="demandTitle"
                  :lg="6"
                  rules="required"
                  :validationLabel="$t('labels.demandTitle')"
                  :labelShown="false"
                  :placeholder="$t('labels.demandTitle')"
                  :formType="{
                    name: 'text',
                  }"
                />

                <FormElement
                  v-model="subject"
                  :lg="6"
                  rules="required"
                  :validationLabel="$t('labels.subject')"
                  :labelShown="false"
                  :placeholder="$t('labels.subject')"
                  :formType="{ name: 'text' }"
                />
              </v-row>

              <v-row>
                <FormElement
                  v-model="message"
                  :lg="12"
                  rules="required"
                  :validationLabel="$t('labels.yourMessage')"
                  :labelShown="false"
                  :placeholder="$t('labels.yourMessage')"
                  :formType="{ name: 'textarea' }"
                />
              </v-row>

              <!-- <ValidationProvider
                :name="$t('labels.yourMessage')"
                rules="required"
                v-slot="{ errors }"
                class="d-lg-flex"
                style="flex-grow: 1"
              >
                <v-textarea
                  v-model="message"
                  class="custom-border"
                  outlined
                  :label="$t('labels.yourMessage')"
                  :error-messages="errors[0]"
                ></v-textarea>
              </ValidationProvider> -->
            </form>
          </ValidationObserver>
          <v-row class="mt-4">
            <FormElement
              v-model="file"
              :lg="6"
              :labelShown="false"
              :placeholder="$t('labels.fileUpload')"
              :onChange="onUploadPhoto"
              :disabled="files.length > 4"
              :formType="{
                name: 'file',
                data: {
                  accept: 'image/png, image/jpeg, image/bmp',
                  multiple: true,
                },
              }"
            />
          </v-row>

          <div class="d-flex align-center flex-column flex-lg-row my-lg-0 my-2">
            <v-hover
              :key="'IMAGE_ITEM_' + index"
              v-for="(fileItem, index) in files"
              v-slot="{ hover }"
            >
              <v-img
                :key="index"
                :src="convertURL(fileItem)"
                height="100"
                width="auto"
                style="
                  cursor: pointer;
                  border-radius: 5px;
                  margin-bottom: 10px;
                  max-width: 150px;
                  margin: 4px;
                "
              >
                <div
                  v-if="hover"
                  style="
                    width: 100%;
                    height: 100%;
                    background: rgba(200, 200, 200, 0.5);
                  "
                  class="d-flex flex-row justify-center"
                >
                  <v-icon @click="deletePhoto(index)" large color="red"
                    >mdi-close</v-icon
                  >
                </div>
              </v-img>
            </v-hover>
          </div>
          <v-row class="justify-center">
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;
                  padding: 2vh 4vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: large;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                @click="sendDemand"
              >
                {{ $t('buttons.submit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>

      <template v-slot:myTickets>
        <v-card
          style="
            padding: 2vh;
            border-radius: 0;
            border-style: solid;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-top-width: 0px;
            border-color: lightgray;
          "
          key="SUPPORT_MY_TICKETS"
        >
          <v-card-text class="pt-4">
            <v-data-table
              :headers="tableHeaders"
              :items="demands"
              :search="search"
              :options="{
                itemsPerPage: itemsPerPage,
              }"
              item-key="Id"
              hide-default-footer
              :no-data-text="$t('messages.noData')"
            >
              <!-- eslint-disable -->
              <template v-slot:item.DemandTitle="{ item }">
                {{ item.DemandTitle }}
              </template>
              <template v-slot:item.Subject="{ item }">
                {{ item.Subject }}
              </template>
              <template v-slot:item.CompanyName="{ item }">
                {{ item.CompanyName }}
              </template>

              <template v-slot:item.DemandType="{ item }">
                <v-chip
                  v-if="item.DemandType === 0"
                  class="ma-2"
                  small
                  color="#1B305D"
                  text-color="white"
                >
                  {{ $t('labels.tooltipUnresolved') }}
                </v-chip>

                <v-chip
                  v-if="item.DemandType === 1"
                  class="ma-2"
                  small
                  color="#c5f4db"
                  text-color="#2b3838"
                >
                  {{ $t('labels.tooltipResolved') }}
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="text-start">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        color="#29B7B7"
                        class="mr-1"
                        style="font-size: 22px"
                        v-bind="attrs"
                        v-on="on"
                        @click="openDetailsDialog(item)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>{{ $t('labels.tooltipShowSupport') }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        color="#29B7B7"
                        class="mr-1"
                        style="font-size: 22px"
                        v-bind="attrs"
                        v-on="on"
                        @click="openDeleteModal(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>{{ $t('labels.tooltipDeleteSupport') }}</span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.DemandType !== 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        color="#29B7B7"
                        class="mr-1"
                        style="font-size: 22px"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          closeTicketModal = true;
                          selectedDemand = item;
                        "
                      >
                        mdi-check-circle
                      </v-icon>
                    </template>
                    <span>{{ $t('labels.endTicket') }}</span>
                  </v-tooltip>
                </div>
              </template>

              <template v-slot:top>
                <v-row>
                  <v-col md="2" class="d-flex align-center ml-auto">
                    <v-select
                      class="custom-border"
                      v-model="filterStatus"
                      :items="statusFilterItems"
                      :label="$t('labels.status')"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      @change="filterContent()"
                    ></v-select>
                  </v-col>
                  <v-col md="3" class="d-flex align-center">
                    <v-text-field
                      class="custom-border"
                      v-model="search"
                      :label="$t('labels.search')"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:footer>
                <div class="d-flex justify-center mt-8 pb-lg-0 pb-8">
                  <v-row justify="center">
                    <v-col md="3" class="ml-md-auto">
                      <v-pagination
                        class="mr-2"
                        @input="onPageChange"
                        :value="page"
                        :length="pageLength"
                        :total-visible="15"
                        circle
                      ></v-pagination>
                    </v-col>
                    <v-col md="2" class="ml-md-auto">
                      <v-select
                        v-model="itemsPerPage"
                        :items="perPageValues"
                        :label="$t('labels.selectItemsPerPage')"
                        item-value="value"
                        item-text="name"
                        outlined
                        dense
                        @change="changeItemsPerPage"
                        rounded
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </template>
    </TabScreen>
    <!-- Details Dialog -->
    <div v-if="detailsDialog" class="details-screen">
      <v-card>
        <v-row class="align-center">
          <v-btn
            @click="detailsDialog = false"
            icon
            color="primary"
            class="ml-4"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-card-title class="popupHeadline">
            <span>{{ $t('title.supportDetail') }}</span>
            <span v-if="selectedDemand.DemandType === 1" class="ml-2">
              <v-chip color="green" dark>{{
                $t('labels.tooltipResolved')
              }}</v-chip>
            </span>
          </v-card-title>
        </v-row>

        <v-divider class="mt-3"></v-divider>
        <v-row class="px-4 py-4">
          <v-col cols="12" sm="4">
            <v-card
              class="d-flex"
              style="
                border-width: 1px;
                border-color: #f0f0f4;
                border-style: solid;
                border-radius: 20px;
                margin-right: 5px;
              "
            >
              <v-card-text class="text-center">
                <p class="font-weight-bold" style="font-size: 20px">
                  {{ $t('labels.titleHr') }}
                </p>
                <p class="font-weight-bold" style="font-size: 24px">
                  {{ selectedDemand.DemandTitle }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card
              class="d-flex"
              style="
                border-width: 1px;
                border-color: #f0f0f4;
                border-style: solid;
                border-radius: 20px;

                margin-right: 5px;
              "
            >
              <v-card-text class="text-center">
                <p class="font-weight-bold" style="font-size: 20px">
                  {{ $t('labels.subject') }}
                </p>
                <p class="font-weight-bold" style="font-size: 24px">
                  {{ selectedDemand.Subject }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card
              class="d-flex"
              style="
                border-width: 1px;
                border-color: #f0f0f4;
                border-style: solid;
                border-radius: 20px;

                margin-right: 5px;
              "
            >
              <v-card-text class="text-center">
                <p class="font-weight-bold" style="font-size: 20px">
                  {{ $t('labels.companyName') }}
                </p>
                <p class="font-weight-bold" style="font-size: 24px">
                  {{ selectedDemand.CompanyName }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card-text>
          <v-row>
            <v-col>
              <MessageDetail
                v-if="selectedDemand.isChatType"
                :messages="messages"
                :id="selectedDemand.ChatId"
                :disableProp="selectedDemand.DemandType === 1"
                :isSupport="true"
                :isHeaderHidden="true"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-row v-if="selectedDemand.DemandFile.length > 0" class="px-4 py-4">
          <v-col cols="12">
            <div
              class="d-flex flex-column align-center"
              style="
                border-width: 1px;
                border-color: #f0f0f4;
                border-style: solid;
                border-radius: 20px;
                padding: 10px;
              "
            >
              <div class="d-flex justify-center mb-4">
                <p class="font-weight-bold my-auto" style="font-size: 20px">
                  {{ $t('titles.attachments') }}
                </p>
              </div>

              <v-row style="width: 100%">
                <v-col
                  v-for="(file, index) in selectedDemand.DemandFile"
                  :key="index"
                  cols="2"
                  @mouseover="setHoveredIndex(index)"
                  @mouseout="resetHoveredIndex()"
                  style="position: relative"
                  class="d-flex flex-row justify-center align-center"
                >
                  <v-img
                    src="../../public/img/zemin-login.png"
                    :src="file.Url"
                    height="auto"
                    style="
                      cursor: pointer;
                      border-radius: 5px;
                      margin-bottom: 10px;
                      max-width: 150px;
                    "
                    @click="openImagePreview(file.Url)"
                  ></v-img>
                  <v-icon
                    v-show="isIconVisible && hoveredIndex === index"
                    small
                    color="#FFFFFF"
                    class="mr-1"
                    style="
                      font-size: 48px;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      opacity: 0.75;
                    "
                    @click="openImagePreview(file.Url)"
                  >
                    mdi-eye
                  </v-icon>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row class="justify-center mt-5">
          <v-btn
            v-if="selectedDemand.DemandType === 0 && isAuth"
            variant="tonal"
            color="red"
            class="rounded-xl ml-2 mb-2"
            style="
              color: white;
              text-transform: none;
              padding: 30px;
              font-size: large;
              font-weight: bold;
            "
            @click="closeTicket"
          >
            {{ $t('labels.endTicket') }}
          </v-btn>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import TabScreen from '../components/TabScreen.vue';
import FormElement from '../components/FormElement.vue';
import DemandService from '../services/DemandService';
import MessageDetail from './MessageDetail.vue';
import FileService from '../services/FileService';
import store from '../store/store';
import DeleteModal from '../components/General/DeleteModal';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import { convertToURL } from '../utils/constants';
export default {
  data: () => ({
    showDeleteConfirmation: false,
    deletingTicket: null,
    isAuth: false,
    demandTitle: null,
    subject: null,
    message: null,
    file: null,
    demands: [],
    slots: [],
    search: '',
    messages: [],
    filterStatus: null,
    statusFilterItems: [],
    tableHeaders: [],
    demandTitleOptions:
      store.state.i18n.locale === 'tr'
        ? [{ text: 'Yazılım Desteği', value: 1 }]
        : [{ text: 'Software Support', value: 2 }],
    detailsDialog: false,
    selectedDemand: {},
    itemsPerPage: 10,
    page: 1,
    totalItems: 0,
    isIconVisible: false,

    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
    closeTicketModal: false,

    files: [],
  }),
  computed: {
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  mounted() {
    this.tableHeaders = [
      { text: this.$t('labels.titleHr'), value: 'DemandTitle' },
      { text: this.$t('labels.subject'), value: 'Subject' },
      { text: this.$t('labels.companyTitle'), value: 'CompanyName' },
      {
        text: this.$t('labels.ticketStatus'),
        value: 'DemandType',
        sortable: true,
        align: 'center',
      },
      { text: this.$t('labels.actions'), value: 'actions', sortable: false },
    ];
    this.statusFilterItems = [
      {
        name: this.$t('labels.All'),
        value: null,
      },
      {
        name: this.$t('labels.tooltipUnresolved'),
        value: 0,
      },
      {
        name: this.$t('labels.tooltipResolved'),
        value: 1,
      },
    ];
    this.getDemands();
    this.isStaff();
  },
  components: {
    TabScreen,
    FormElement,
    MessageDetail,
    DeleteModal,
    ConfirmationModal,
  },
  methods: {
    deletePhoto(index) {
      this.files.splice(index, 1);
    },
    onUploadPhoto(f) {
      if (this.file?.length > 0) {
        this.files = [...this.files, ...this.file];
        this.file = null;
      }
    },
    convertURL(fileItem) {
      return convertToURL(fileItem);
    },
    setHoveredIndex(index) {
      this.hoveredIndex = index;
      this.isIconVisible = true;
    },
    resetHoveredIndex() {
      this.hoveredIndex = null;
      this.isIconVisible = false;
    },
    goBack() {
      this.detailsDialog = false;
    },
    isStaff() {
      this.isAuth = this.$store.getters.getUserType === 'Admin' ? true : false;
      if (this.isAuth) {
        this.slots = [
          { name: 'myTickets', title: this.$t('labels.myTickets') },
        ];
      } else {
        this.slots = [
          { name: 'ticket', title: this.$t('labels.newSupport') },
          { name: 'myTickets', title: this.$t('labels.myTickets') },
        ];
      }
    },
    getDemands() {
      this.$store.state.loadingStatus = true;
      DemandService.getDemands(this.page, this.itemsPerPage, this.filterStatus)
        .then((response) => {
          this.demands = response.data.Result;
          this.totalItems = response.data.TotalItems;
          this.$store.state.loadingStatus = false;
        })
        .catch((err) => {
          this.$store.state.loadingStatus = false;
        });
    },
    async sendDemand() {
      this.$refs.fieldObserver.validate().then(async (valid) => {
        if (!valid) {
          return;
        }

        this.$store.state.loadingStatus = true;
        let fileIds = [];

        const body = {
          DemandTitle: this.demandTitle,
          Subject: this.subject,
          Message: this.message,
          FileIds: [],
        };

        console.log('Files: ', this.file);

        if (this.files && this.files.length > 0) {
          const fileUploadPromises = this.files.map(async (file) => {
            try {
              const formData = new FormData();
              formData.append('Files', file);
              formData.append('Type', 'Media');
              const fileUploadResponse = await FileService.uploadFile(formData);
              const fileId = fileUploadResponse.data.Result[0].Id;
              fileIds.push(fileId);
            } catch (err) {
              console.error('Error uploading file: ', err);
              throw err;
            }
          });

          try {
            await Promise.all(fileUploadPromises);
          } catch (err) {
            console.error('Error uploading files: ', err);
            this.$store.state.loadingStatus = false;
            window.showError(err);
            return;
          }

          body.FileIds = fileIds;
        }

        DemandService.sendDemand(body)
          .then((res) => {
            this.$store.state.loadingStatus = false;
            this.getDemands();
            window.showSuccess(this.$t('messages.ticketCreated'));

            this.demandTitle = '';
            this.subject = '';
            this.message = '';
            this.resetFields();
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      });
    },

    confirmDelete() {
      this.$store.state.loadingStatus = true;
      DemandService.deleteDemand(this.deletingTicket.Id)
        .then((res) => {
          window.showSuccess(this.$t('messages.ticketDeleteSuccess'));
          this.$refs.deleteModalRef.modalStatus = false;
          this.getDemands();
        })
        .catch((err) => {
          window.showError(error);
          this.$store.state.loadingStatus = false;
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          this.$refs.deleteModalRef.modalStatus = false;
        });
    },
    closeTicket() {
      this.$store.state.loadingStatus = true;
      const body = {
        Id: this.selectedDemand.Id,
      };
      DemandService.closeTicket(body)
        .then((res) => {
          this.getDemands();
          this.detailsDialog = false;
        })
        .catch((err) => {})
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    openImagePreview(url) {
      window.open(url, '_blank');
    },
    filterContent() {
      this.tableData = [];
      this.itemsPerPage = 10;
      this.page = 1;
      this.getDemands();
    },
    openDeleteModal(ticket) {
      this.deletingTicket = ticket;
      this.$refs.deleteModalRef.modalStatus = true;
    },
    openDetailsDialog(demand) {
      this.selectedDemand = demand;
      this.selectedDemand.isChatType = true;
      this.detailsDialog = true;
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getDemands();
    },
    onPageChange(newPage) {
      this.$store.state.loadingStatus = true;
      this.page = newPage;
      this.getDemands();
    },
    resetFields() {
      this.demandTitle = null;
      this.subject = null;
      this.message = null;
      this.file = null;
      this.files = [];
      this.$refs.fieldObserver.reset();
    },
  },
};
</script>
