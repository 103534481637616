export default {
  isAuthenticated(state) {
    return !!state.Token && !!state.Id;
  },
  getId(state) {
    return state.Id;
  },

  getToken(state) {
    return state.Token;
  },

  getUserType(state) {
    return state.Type;
  },

  getUserFullName(state) {
    return state.FullName;
  },

  getCompanyName(state) {
    return state.CompanyName;
  },
  getPasswordChange(state) {
    return state.IsChangePassword;
  },
};
