<template>
  <div>
    <delete-modal :yesFunc="deleteCompany" ref="deleteModalRef" />
    <!-- Page content -->
    <TabScreen :slots="slots">
      <!-- Information Template -->

      <!-- Information Template END -->
      <template v-slot:companyList>
        <v-card elevation="2" style="border-radius: 0">
          <v-card-title style="color: #595959">
            <v-row class="d-lg-flex">
              <v-col>
                <v-autocomplete
                  v-model="model"
                  :items="items"
                  :loading="isLoading"
                  :search-input.sync="search"
                  clearable
                  item-text="CompanyName"
                  item-value="CompanyTypeId"
                  :label="$t('labels.search')"
                  rounded
                  filled
                  append-icon="mdi-magnify"
                  prepend-icon="mdi-account-key"
                  solo
                  hide-details
                  hide-selected
                >
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="blue-grey"
                      class="white--text"
                      v-on="on"
                    >
                      <v-icon left> mdi-account </v-icon>
                      <span v-text="item.CompanyName"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar
                      color="indigo"
                      class="text-h5 font-weight-light white--text"
                    >
                      <v-img
                        :lazy-src="
                          item.PhotoUrl || require('../../assets/no-image.png')
                        "
                        max-height="50"
                        max-width="50"
                        :src="
                          item.PhotoUrl || require('../../assets/no-image.png')
                        "
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content
                      @click="changeActiveCompany(item, true)"
                    >
                      <!-- eslint-disable-next-line  --><v-list-item-title
                        v-text="item.CompanyName"
                      ></v-list-item-title>
                      <!-- eslint-disable-next-line  --><v-list-item-subtitle
                        v-text="item.Email"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider />

          <v-card-text class="pt-4">
            <v-data-table
              :headers="headers"
              :items="companies"
              :loading="loadings.table"
              :options.sync="options"
              :items-per-page="itemsPerPage"
              :hide-default-footer="true"
              :no-data-text="$t('description.noList')"
            >
              <!-- eslint-disable -->
              <template v-slot:item.PhotoUrl="{ item }">
                <v-row style="padding: 10px">
                  <v-col>
                    <v-img
                      :lazy-src="
                        item.PhotoUrl || require('../../assets/no-image.png')
                      "
                      max-height="50"
                      max-width="50"
                      :src="
                        item.PhotoUrl || require('../../assets/no-image.png')
                      "
                    ></v-img>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.CompanyName="{ item }">
                {{ item.CompanyName }}
              </template>
              <!-- This operation will log error in console -->
              <template v-slot:item.CompanyType="{ item }">
                {{
                  companyTypes.filter((x) => x.Id === item.CompanyTypeId)[0]
                    .Name
                }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="false" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      middle
                      class="mr-2"
                      color="green"
                      @click="changeActiveCompany(item, true)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-file-edit
                    </v-icon>
                  </template>
                  <span>{{ $t('labels.tooltipEdit') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <div class="text-xs-center pt-2 md4 lg4">
              <!-- Pagination select box start -->
              <v-row align="center">
                <v-col md="3" class="ml-md-auto">
                  <v-pagination
                    @input="paginationChangeHandler"
                    :value="pagination.page"
                    :length="pages"
                    :total-visible="15"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col md="2" class="ml-md-auto">
                  <v-select
                    v-model="itemsPerPage"
                    :items="$store.state.perPageValues"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    @change="changeItemsPerPage()"
                    outlined
                    dense
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
              <!-- Pagination select box end -->
            </div>
          </v-card-text>
        </v-card>
      </template>
    </TabScreen>
  </div>
</template>

<script>
import FileService from '../../services/FileService';
import CompanyServices from '../../services/CompanyServices';
import DeleteModal from '../../components/General/DeleteModal';
import TabScreen from '../../components/TabScreen.vue';
import FormElement from '../../components/FormElement.vue';
import uploadFile from '../../utils/uploadFile';

export default {
  data: () => ({
    // General Models
    isLoading: false,
    isUpdate: false,
    isUploading: false,
    file: null,
    base64: '',
    showPassword: false,
    cities: [
      {
        id: 'İzmir',
        value: 'İzmir',
      },
    ],
    countries: [
      {
        id: 'Türkiye',
        value: 'Türkiye',
      },
    ],

    districts: [
      {
        id: 'Konak',
        cityId: 1,
        value: 'KONAK',
      },
    ],
    company: {
      CompanyName: 'Test Şirketi',
      ProductCategory: 'string',
      TaxOffice: 'string',
      TaxNo: 'string',
      TradeRegisterNumber: 'string',
      IndustryRegisterNumber: 'string',
      Email: 'engin.ergen@digitus.com.tr',
      GsmNo: '905511623469',
      SectorId: 'string',
      Country: 'string',
      City: 'string',
      District: 'string',
      Postcode: 'string',
      Address1: 'string',
      Address2: 'string',
      Address3: 'string',
      OfficerName: 'string',
      OfficerSurname: 'string',
      CompanyTypeId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      PhotoUrl: 'string',
    },

    descriptionLimit: 60,
    model: null,
    search: null,
    companies: [],

    slots: [{ name: 'companyList', title: 'Şirket Listesi' }],

    deletedId: -1,

    companyTypes: [],

    districts: [
      {
        id: 'Konak',
        cityId: 1,
        value: 'KONAK',
      },
    ],

    // List options
    options: {
      itemsPerPage: 15,
    },
    top: 15,
    skip: 0,
    headers: [],
    //Loading states
    loadings: {
      table: false,
      send: false,
    },
    pagination: {},
    itemsPerPage: 10,
  }),

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    items() {
      return this.companies.map((entry) => {
        const CompanyName = entry.CompanyName;
        const CompanyId = entry.Id || 1;
        return Object.assign({}, entry, { CompanyId, CompanyName });
      });
    },
  },

  watch: {
    model(value) {},
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.getCompanies();
    },
  },
  mounted() {
    this.setup();
  },
  components: {
    FormElement,
    DeleteModal,
    TabScreen,
  },
  methods: {
    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getCompanies();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getCompanies();
    },

    //Page setup
    setup() {
      // Get Companies
      this.getCompanies();
      this.getCompanyTypes();
      this.pagination = {
        totalItems: 15,
        rowsPerPage: 15,
        page: 1,
      };
      this.headers = [
        {
          text: this.$t('labels.logo'),
          value: 'PhotoUrl',
          sortable: false,
        },
        {
          text: this.$t('labels.companyName'),
          value: 'CompanyName',
          sortable: false,
        },
        {
          text: this.$t('labels.companyType'),
          value: 'CompanyType',
          sortable: false,
        },
      ];
    },
    /**
     * Get Company Type List
     */
    getCompanyTypes() {
      CompanyServices.getCompanyTypes()
        .then((response) => {
          this.companyTypes = response.data.Result;
        })
        .catch((error) => {
          console.log('Role List Error => ', error);
        });
    },
    //Clears all modal's states and item ID
    clearModal() {
      setTimeout(() => {
        this.resetForm();
        this.isUpdate = false;
        this.company = {
          CompanyName: 'Test Şirketi',
          ProductCategory: 'string',
          TaxOffice: 'string',
          TaxNo: 'string',
          TradeRegisterNumber: 'string',
          IndustryRegisterNumber: 'string',
          Email: 'engin.ergen@digitus.com.tr',
          GsmNo: '905511623469',
          SectorId: 'string',
          Country: 'string',
          City: 'string',
          District: 'string',
          Postcode: 'string',
          Address1: 'string',
          Address2: 'string',
          Address3: 'string',
          OfficerName: 'string',
          OfficerSurname: 'string',
          CompanyTypeId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          PhotoUrl: 'string',
        };
      }, 500);
    },

    /**
     * Change active company of the page.
     * @param {*} item
     * @param {boolean} isUpdate
     */
    changeActiveCompany(item, isUpdate = false) {
      this.company = item;
      this.isUpdate = isUpdate;
    },

    /**
     * Get company list.
     */
    getCompanies() {
      this.loadings.table = true;
      this.$store.state.loadingStatus = true;

      CompanyServices.getList({
        Page: this.pagination.page,
        PageSize: this.pagination.rowsPerPage,
      })
        .then((res) => {
          this.companies = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * onChangeImage trigger.
     */
    onChangeImage() {
      if (this.file) {
        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.base64 = reader.result;
        };
        this.isUploading = true;
      } else {
        this.isUploading = false;
      }
    },

    /**
     * Delete a company.
     */
    deleteCompany() {
      this.loadings.table = true;
      this.$store.state.loadingStatus = true;

      CompanyServices.delete({ Id: this.deletedId })
        .then(() => {
          this.$refs.deleteModalRef.modalStatus = false;
          this.setup();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.loadings.table = false;
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Shows delete modal.
     * @param {*} item
     */
    openDeleteModal(item) {
      this.deletedId = item.Id;
      this.$refs.deleteModalRef.modalStatus = true;
    },

    /**
     * Resetting forms' validation.
     */
    async resetForm() {
      if (this.$refs.observer) {
        this.$refs.observer.reset();
      }
    },

    /**
     * Add a company.
     * FIXME: Şirket eklerken bütün verilerin gönderilmesi gerekiyor.
     * FIXME: Şirketi eklerken kime ait olduğuna dair bir veri gönderilmiyor.
     */
    async addCompany(uploadFinished = false) {
      this.isLoading = true;
      this.$store.state.loadingStatus = true;

      if (this.isUploading && !uploadFinished) {
        await uploadFile(this.file, (data) => {
          this.company.PhotoUrl = data;
          this.addCompany(true);
        });
      } else {
        CompanyServices.add(this.company)
          .then((response) => {
            window.showSuccess(
              this.$t('messages.successfulAdd', {
                value: 'Şirket ',
              })
            );
          })
          .catch((error) => {
            window.showError(error);
          })
          .finally(() => {
            this.isLoading = false;
            this.$store.state.loadingStatus = false;
          });
      }
    },
  },
};
</script>
