<template>
  <div>
    <div class="unauthenticatedPage">
      <v-layout wrap align-center justify-center fill-height>
        <v-container>
          <v-layout wrap align-center justify-center fill-height>
            <v-card style="padding: 3vh; border-radius: 20px">
              <v-row>
                <v-col md="12" xs="12">
                  <div>
                    <div
                      class="text-center"
                      style="
                        color: #fb3d6b;
                        font-weight: 700;
                        font-size: 24px;
                        margin-bottom: 22px;
                      "
                    >
                      {{ message }}
                    </div>
                    <v-row class="mt-4">
                      <v-col
                        cols="12"
                        sm="12"
                        xl="12"
                        style="padding: 0"
                        class="d-flex justify-center"
                      >
                        <v-btn
                          style="
                            width: 40%;
                            color: #fff;
                            text-transform: unset !important;
                            background-color: #0077d5;
                            padding: 2vh 3vh;
                            border-radius: 30px !important;
                            font-weight: bolder;
                          "
                          elevation="0"
                          class="darken-1"
                          type="submit"
                          @click="$router.push({ name: 'home' })"
                        >
                          {{ $t('buttons.back') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
        </v-container>
      </v-layout>
    </div>
  </div>
</template>
<script>
import NavMenu from '../components/NavMenu.vue';
import OrderService from '../services/OrderService';

export default {
  data() {
    return {
      orderId: this.$route.params.id,
      message: null,
    };
  },
  components: { NavMenu },
  mounted() {
    this.updatePayment();
  },
  methods: {
    updatePayment() {
      this.message = this.$t('description.invoiceLoading');

      let body = {
        OrderIds: [this.orderId],
        PaymentStatus: 0,
      };
      OrderService.updatePaymentStatus(body)
        .then((response) => {
          if (response.status === 200) {
            this.message = this.$t('description.invoiceSuccess');
          } else {
            this.message = this.$t('description.invoiceError');
          }
        })
        .catch((error) => {
          console.log(error);
          this.message = this.$t('description.invoiceError');
        });
    },
  },
};
</script>
