// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'additional-cost';

export default {
  /**
   * Creates a additional cost.
   * @param {{ LogisticType: number, AdditionalCostType: number, CostParameter: number, Value: number, MinValue: number }} body
   * @returns {Promise<any>}
   */
  add(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },

  /**
   * Get additional cost list.
   * @param {*} body
   * @returns {Promise<any>}
   */
  get(body) {
    return axios.get(`${RESOURCE_NAME}/get-all`, {
      params: body
    });
  },

  /**
   * Get additional cost by Id.
   * @param {string} additionalCostId
   * @returns {Promise<any>}
   */
  getById(additionalCostId) {
    return axios.get(`${RESOURCE_NAME}/get-id?Id=${additionalCostId}`);
  },

  /**
   * Updates a additional cost.
   * @param {{ LogisticType: number, AdditionalCostType: number, CostParameter: number, Value: number, MinValue: number }} body
   * @returns {Promise<any>}
   */
  update(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },

  /**
   * Delete a additional cost.
   * @param {string} additionalCostId
   * @returns {Promise<any>}
   */
  delete(additionalCostId) {
    return axios.delete(`${RESOURCE_NAME}/delete?Id=${additionalCostId}`);
  },
};
