// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/shipments';
const RESOURCE_NAME2 = '/shipment_boxes';

export default {
  getShipmentById(id) {
    return axios.get(`${RESOURCE_NAME}/GetById`, { params: { Id: id } });
  },
  updateShipment(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },
  getShipmentBoxes(id) {
    return axios.get(`shipment_boxes/GetList`, { params: { Id: id } });
  },
  createShipment(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },
  deleteWarning(ShipmentId) {
    return axios.put(
      `${RESOURCE_NAME2}/delete_warning?ShipmentId=${ShipmentId}`
    );
  },
};
