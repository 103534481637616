import store from "../store/store";
import axios from "../javascript/custom-axios";
import VueI18n from "vue-i18n";

const storedLanguage = localStorage.getItem('language') || 'tr';
const language = storedLanguage == 'tr' ? 'tr-TR' : 'en-US';

export default {
  // TODO: Burada seçilen dile göre Accept-Language headers gönderilecek
  ifAuthenticated(to, from, next) {
    let isUserType = to.meta.userType.indexOf(store.getters.getUserType);
    if (store.getters.isAuthenticated && isUserType >= 0) {
      axios.defaults.headers = { Authorization: "Bearer " + store.state.Token,'Accept-Language':language };
      next();
    } else {
      if (isUserType > -1) {
        //tüm yetkilerin girebildiği sayfa olmalı
        next("/home/dashboard");
      } else {
        next("/login");
      }
    }
  },

  ifNotAuthenticated(to, from, next) {
    if (!store.getters.isAuthenticated) {
      axios.defaults.headers = { Authorization: "" ,'Accept-Language':language};
      next();
      return;
    }
    next("/home");
  },
};
