import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'UserGuide';

export default {
  add(body) {
    return axios.post(`${RESOURCE_NAME}/add`,body);
  },
  getAll() {
    return axios.get(`${RESOURCE_NAME}/get`);
  },
  getAnswers(id) {
    return axios.get(`${RESOURCE_NAME}/answer/get`, { params: { UserGuideId: id } });
  },
};