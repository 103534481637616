<template>
  <fragment>
    <div style="background-color: transparent">
      <v-sheet style="background-color: transparent" v-if="!demand">
        <v-chip-group
          style="background-color: transparent"
          v-model="transferType"
          show-arrows
          mandatory
          outlined
          center-active
        >
          <div
            class="d-lg-flex flex-lg-row flex-column justify-lg-space-between"
          >
            <v-chip
              style="
                border-width: 1px;
                border-style: solid;
                border-color: #d4d4d4;
                border-radius: 10px 10px 0 0;
              "
              class="tab-like-chip"
              label
              active-class="activeTab"
              :key="'TRANSFER_TYPE_ ' + element.Id"
              :value="element.Id"
              :style="
                'margin: 0;' +
                (index === 0
                  ? 'border-right: 1px solid #DEDEDE; border-radius: 10px 0 0 0 !important;'
                  : 'border-left: 1px solid #DEDEDE; border-radius: 0 10px 0 0 !important;')
              "
              :disabled="demand"
              v-for="(element, index) in $root.transferTypes"
            >
              <span
                :style="{
                  color: transferType === element.Id ? '#1D2E5B' : '#808080',
                }"
              >
                {{ element.Name }}
              </span>
            </v-chip>
          </div>
        </v-chip-group>
      </v-sheet>
      <v-sheet class="offerFormSheetTemp">
        <div class="px-4 my-4 d-flex align-center justify-flex-start">
          <div class="mr-4">
            <input
              type="radio"
              id="one"
              value="1"
              style="
                width: 20px;
                height: 20px;
                accent-color: var(--mainColor);
                margin-right: 10px;
              "
              v-model="exportImportType"
              :disabled="demand"
            />
            <label
              style="
                display: inline-block;
                padding: 5px 10px;
                border-radius: 15px;
                background-color: var(--mainColor);
                color: white;
                cursor: pointer;
              "
              class="custom-label-form-element"
              for="one"
              >{{ $t('labels.export') }}</label
            >
          </div>
          <div>
            <input
              type="radio"
              id="two"
              value="0"
              style="
                width: 20px;
                height: 20px;
                accent-color: var(--mainColor);
                margin-right: 10px;
              "
              v-model="exportImportType"
              :disabled="demand"
            />
            <label
              style="
                display: inline-block;
                padding: 5px 10px;
                border-radius: 15px;

                background-color: var(--mainColor);
                color: white;
                cursor: pointer;
              "
              class="custom-label-form-element"
              for="two"
              >{{ $t('labels.import') }}</label
            >
          </div>
        </div>
        <!-- Transfer Type -->
        <v-chip-group
          v-if="transferType == 1"
          v-model="shipmentType"
          active-class="activeTransferType"
          :show-arrows="showArrows"
          mandatory
          center-active
          style="width: 100%"
        >
          <div style="width: 100%" class="pl-4">
            <v-chip
              color="#DFDFDF"
              class="icon-button-with-text mr-2"
              style="border: 1px solid #dfdfdf; border-radius: 10px"
              :key="'CHIP_SHIPMENT_TYPE_ ' + element.Id"
              :value="element.Id"
              :disabled="
                !element.Shipments.includes(transferType) ||
                (demand && shipmentType !== element.Id)
              "
              v-for="element in $root.shipmentTypes"
            >
              <v-icon
                class="icon-button-icon"
                :style="{
                  color: shipmentType === element.Id ? '#1D2E5B' : '#BFBFBF',
                }"
                medium
                left
              >
                {{ element.Icon }}
              </v-icon>
              <span
                class="icon-button-text"
                :style="{
                  color: shipmentType === element.Id ? '#1D2E5B' : '#BFBFBF',
                }"
              >
                {{ element.Name }}
              </span>
            </v-chip>
          </div>
        </v-chip-group>
        <!-- Transfer Type END -->
        <!-- Form Start -->
        <div class="px-6">
          <Seaway
            :key="$vnode.key + '_SEAWAY'"
            :creatingOffer="creatingOffer"
            :body="{
              ...body,
              fastShippingSearchId: params?.fastShippingSearchId,
              exportImportType,
               ...params
            }"
            :createOffer="createOffer"
            :shipmentType="shipmentType"
            :transferType="transferType"
            v-if="shipmentType === 3"
            :demand="demand"
            :demandOnFinally="demandOnFinally"
            :add="add"
            :showDemandOptions="showDemandOptions"
          />
          <Airline
            :key="$vnode.key + '_AIRLINE'"
            :creatingOffer="creatingOffer"
            :body="{
              ...body,
              fastShippingSearchId: params?.fastShippingSearchId,
              exportImportType,
              ...params,
              isIntegration: this.isIntegration
            }"
            :createOffer="createOffer"
            :shipmentType="shipmentType"
            :transferType="transferType"
            v-if="shipmentType === 1"
            :demand="demand"
            :demandOnFinally="demandOnFinally"
            :add="add"
            :showDemandOptions="showDemandOptions"
            :isFirstSearch="isFirstSearch || transferType === 2"
          />
          <Roadway
            :key="$vnode.key + '_ROADWAY'"
            :creatingOffer="creatingOffer"
            :body="{
              ...body,
              fastShippingSearchId: params?.fastShippingSearchId,
              exportImportType,
               ...params
            }"
            :createOffer="createOffer"
            :shipmentType="shipmentType"
            :transferType="transferType"
            v-if="shipmentType === 2"
            :demand="demand"
            :demandOnFinally="demandOnFinally"
            :add="add"
            :showDemandOptions="showDemandOptions"
          />
          <Railway
            :key="$vnode.key + '_RAILWAY'"
            :creatingOffer="creatingOffer"
            :body="{
              ...body,
              fastShippingSearchId: params?.fastShippingSearchId,
              exportImportType,
               ...params
            }"
            :createOffer="createOffer"
            :shipmentType="shipmentType"
            :transferType="transferType"
            v-if="shipmentType === 5"
            :demand="demand"
            :demandOnFinally="demandOnFinally"
            :add="add"
            :showDemandOptions="showDemandOptions"
          />
          <!-- Form END -->
        </div>
      </v-sheet>
    </div>
  </fragment>
</template>
<script>
import Seaway from '../../views/OfferScreens/Seaway.vue';
import Airline from '../../views/OfferScreens/Airline.vue';
import Roadway from '../../views/OfferScreens/Roadway.vue';
import Railway from '../../views/OfferScreens/Railway.vue';
import {
  triggerLoading,
  shipmentTypes,
  transferTypes,
getCountries,
getAirports,
} from '../../utils/constants';

export default {
  props: {
    searchAgain: {
      type: Function,
      default: () => {},
    },
    add: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
    demand: {
      type: Boolean,
      default: false,
    },
    showDemandOptions: {
      type: Boolean,
      default: true,
    },
    body: {
      type: Object,
      default: () => null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    demandOnFinally: {
      type: Function,
      default: () => {},
    },
    isFirstSearch: {
      type: Boolean,
      default: false
    },
    isIntegration: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // List
      transferTypes: transferTypes,
      shipmentTypes: shipmentTypes,
      // Data

      // States
      transferType: 1,
      shipmentType: -1,
      exportImportType: '1',
      pageLoading: false,
      creatingOffer: false,

      countries: [],
      airports: []
    };
  },
  components: { Seaway, Airline, Roadway, Railway },
  async mounted() {

    this.transferType = 2;

    if (this.body?.transferType) {
      this.transferType = this.body.transferType;
    }
    if (this.body?.shipmentType) {
      this.shipmentType = this.body.shipmentType;
    }

    if (this.body?.shipmentType) {
      this.exportImportType = this.body.exportImportType;
    }
  },
  updated() {
    if (!this.demand && !this.update) {
      if (this.body?.transferType) {
        this.transferType = this.body.transferType;
      }
      if (this.body?.shipmentType) {
        this.shipmentType = this.body.shipmentType;
      }

      if (this.body?.shipmentType) {
        this.exportImportType = this.body.exportImportType;
      }
    }
  },
  computed: {
    showArrows() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    transferType(newValue) {
      if (newValue === 2) {
        this.shipmentType = 1;
      } else {
        this.shipmentType = 3;
      }
    },
  },
  methods: {
    /**
     * We need create a rule on transfer types.
     */
    onTransferTypeChange() {
      if (this.transferType === 2) {
        this.shipmentType = 2;
      } else {
        this.shipmentType = 1;
      }
    },
    /**
     * Create an offer with form.
     */
    createOffer(isInvalid, body) {

      if (!isInvalid) {
        if (this.update) {
          this.searchAgain({
            transferType: this.transferType,
            shipmentType: this.shipmentType,
            exportImportType: this.exportImportType,
            item: body,
          });
        } else {
          this.creatingOffer = true;
          triggerLoading();
          this.creatingOffer = false;
          this.$router.push({
            name: 'offerResultsCustomer',
            params: {
              transferType: this.transferType,
              shipmentType: this.shipmentType,
              exportImportType: this.exportImportType,
              item: body,
            },
          });
        }
      }
    },

    setup() {},
    /**
     * Resetting forms' validation.
     */
    async resetForm() {
      this.item = {};
      this.$refs.observer.reset();
      this.setup();
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      this.resetForm();
    },
  },
};
</script>
<style>
#offerPage {
  background: url('../../images/1.jpg') center center fixed no-repeat;
}
.container {
  margin-top: 38px;
}
.offerFormSheetTemp {
  border: 1px solid #d4d4d4 !important;
  border-top-width: 0px !important;
  border-radius: 0 10px 10px 10px !important;
  padding-top: 20px;
}

.searchingSheet {
  border: 1px solid #d4d4d4 !important;
  border-radius: 20px !important;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.activeTransferType {
  color: #fff !important;
  background-color: #fff !important;
}
.activeTab {
  color: #fff !important;
  background-color: #fff !important;
  border-bottom-color: #fff !important;
}
.v-slide-group__content {
  padding: 0 !important;
}
</style>
