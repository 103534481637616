<template>
  <CreateOfferForm v-if="!integrationSearch" :isIntegration="isIntegrationSearch" :body="body" :key="'QUICK_SEARCH_CREATE_OFFER'" />
</template>
<script>
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';
import { convertBoxesToVolumes, transferTypes } from '../utils/constants';
import IntegrationServices from '../services/IntegrationServices'
export default {
  data() {
    return {
      // States
      pageLoading: false,
      body: null,

      integrationSearch: false,
      isIntegrationSearch: false
    };
  },
  components: { CreateOfferForm },
  mounted() {
    const id = this.$route.params.id;
    if(id){
      this.integrationSearch = true;
      this.isIntegrationSearch = true;
      this.search(id);
    }
  },
  computed: {},
  watch: {},
  methods: {
    search(id) {
      this.$store.state.loadingStatus = true;
      IntegrationServices.getOrderById(id)
        .then((response) => {
          const integrationSearch = response.data.Result;

          if (integrationSearch) {
            this.integrationSearch = integrationSearch;
            
            this.body = {
              transferType: 2,
              item: {
                city_toWhere: integrationSearch.DestinationCityId,
                postalCode_toWhere: integrationSearch.DestinationPostalCode,
                country_toWhere: integrationSearch.DestinationCountryId,
                city_fromWhere: integrationSearch.LoadingCityId,
                postalCode_fromWhere: integrationSearch.LoadingPostalCode,
                country_fromWhere: integrationSearch.LoadingCountryId,
                marketplaceOrderId: integrationSearch.Id,
                volumes: convertBoxesToVolumes(integrationSearch.Boxes)
              },
            };
          }
        })
        .catch(error => {
          this.$router.push('/home/quick-search')
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.integrationSearch = false;
        });
    },

    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {
      this.$store.state.loadingStatus = true;
      setTimeout(() => {
        this.page = newPage;
        this.$store.state.loadingStatus = false;
      }, 1000);
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      this.resetForm();
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
