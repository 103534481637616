// import { filter } from "core-js/core/array";
import Vue from "vue";
import tr from "../assets/i18n/languages/tr";
import moduleJson from "../module.json";
import { mapActions } from "vuex";

//Key değerinin sadece ingilizce karakter içermesinin kontrolünü yapar
const _controlKey = (key) => {
  let isValid = true;
  !/[^a-zA-Z_]/.test(key) ? (isValid = true) : (isValid = false);
  return isValid;
};

//Gelen karakterin ingilizce olup olmadığının kontrolü yapılıyor
const _controlKeyChar = (e) => {
  let char = String.fromCharCode(e.keyCode);
  if (/^[a-zA-Z0-9_.-]*$/.test(char)) return true;
  else e.preventDefault();
};
//Gelen karakterin sadece rakam olup olmadığını kontrol eder
const _controlIntChar = (e) => {
  let char = String.fromCharCode(e.keyCode);
  if (/^[0-9]+$/.test(char)) return true;
  else e.preventDefault();
};
//Gelen tarihin yerel saat şeklinde değiştirir
const _convertToLocal = (date) => {
  var localDate = new Date(date).toLocaleDateString("tr-TR");
  return localDate;
};
const _convertToLocalTime = (date) => {
  const tempDate = new Date(date);
  const hours = tempDate.getHours().toString().padStart(2, '0'); 
  const minutes = tempDate.getMinutes().toString().padStart(2, '0'); 
  return `${hours}:${minutes}`; // Return formatted time string
};
//Gelen tarihi date-picker a uygun hale getiriyor
const _convertToDatepicker = (date) => {
  var splitDate = date.split(".");
  var returnDate = "";
  for (let index = splitDate.length; index > 0; index--) {
    const element = splitDate[index - 1];
    if (index == splitDate.length) {
      returnDate += element;
      continue;
    }
    returnDate += "-" + element;
  }

  return returnDate;
};
//Gelen kategorinin dile göre geri döndürülmesi sağlar
const _getCategoriName = (categori) => {
  // if(!categori){
  //   return null;
  // }
  let language = "tr"; //TO DO: dil paketinden kullanılan dil neyse o buraya yazılmalı
  var categoriName = categori.filter((item) => item.Culture == language);
  return categoriName.length > 0 ? categoriName[0].Name : "";
};
//Gelen kategorinin  gelen dile göre geri döndürülmesi sağlar
const _getCategoriNameWithCulture = (categori, lang) => {
  var categoriName = categori.filter((item) => item.Culture == lang);
  return categoriName.length > 0 ? categoriName[0].Name : "";
};
//Gelen array içindeki boş array ve elemanları silip düzenlenmiş arrayi geri döndürür.
const _setGroupBodyArray = (array) => {
  const arr = [];
  array.forEach((element, index) => {
    let tempArr = [];
    if (element.length > 0) {
      element.forEach((element) => {
        if (element) tempArr.push(element);
      });
      arr.push(tempArr);
    }
  });
  return arr;
};

//Servisten gelen kategori datasını treeview için düzenler
const _setCategoryTreeviewData = (data, flag = false, newData = []) => {
  let selectedData = !flag ? data : newData;
  let array = selectedData.map((value, key) => {
    return {
      Id: value.Id,
      ParentId: value.ParentId,
      Culture: getCategoriName(value.Translation),
      ChildItems: data.filter((i) => i.ParentId === value.Id),
    };
  });

  let groupData = [];
  let filterData = array;

  if (!flag) {
    groupData = _.chain(array)
      .groupBy("ParentId")
      .map((value, key) => ({ Id: key, ChildItems: value }))
      .value();
    filterData = _.filter(groupData, (x) => {
      return x.Id != "null";
    });
    filterData.map((item) => {
      let cName = array.find((x) => {
        return x.Id === item.Id;
      }).Culture;
      item["Culture"] = cName;
      return item;
    });
  }
  filterData.map((item) => {
    if (item.ChildItems && item.ChildItems.length > 0) {
      item.ChildItems.map((child) => {
        child["Culture"] = !child.Culture
          ? child.Translation[0].Name
          : child.Culture;
        if (child.Children && child.Children.length > 0) {
          child["ChildItems"] = _setCategoryTreeviewData(
            data,
            true,
            child.Children
          );
        } else if (child.ChildItems && child.ChildItems.length > 0) {
          child["ChildItems"] = _setCategoryTreeviewData(
            data,
            true,
            child.ChildItems
          );
        }
        //Old version
        // if (child.ChildItems && child.ChildItems.length > 0) {
        //   child["ChildItems"] = _setCategoryTreeviewData(data, true, child.ChildItems)
        // } else {
        //   child['Culture'] = !child.Culture ? child.Translation[0].Name : child.Culture
        //   if (child.Children && child.Children.length > 0) {
        //     child["ChildItems"] = _setCategoryTreeviewData(data, true, child.Children)
        //   }
        // }

        return child;
      });
    }
    return item;
  });

  return filterData;
};

const _setFilterData = (data, pageKey, allData) => {
  let filterData = data;
  switch (pageKey) {
    case "indirimler":
      //indirimler
      filterData = filterData.filter((x) => x.Culture == "İndirimler");
      break;
    case "personnel_shuttle":
      //Güzergah
      filterData = filterData.filter((x) => x.Culture == "Güzergah");
      break;
    case "ik_el_kitabi":
      //IK el kitabı
      filterData = filterData.filter((x) => x.Culture == "IK EL Kitabı");
      break;
    case "phones":
      //Telefonlar
      filterData = filterData.filter((x) => x.Culture == "Telefonlar");
      break;
    case "job_notices":
      //Şirket, şehir, atama kategorisi
      filterData = filterData.filter(
        (x) =>
          x.Culture == "Şirket" ||
          x.Culture == "Şehir" ||
          x.Culture == "Fonksiyon"
      );
      break;
    default:
      filterData = null;
      break;
  }
  return filterData;
};

const _getPageRulesById = (id) => {
  let data = moduleJson.find((x) => x.moduleId == id);

  //If module is not in moduleJson file
  if (data === undefined) {
    data = moduleJson.find((x) => x.moduleId == -1);
  }
  return data;
};
const _getPageRulesByName = (name) => {
  let data = moduleJson.find((x) => x.adi == name);
  return data;
};

const _strippedContent = (string) => {
  let regex = /(<([^>]+)>)/gi;
  return string.replace(regex, "");
};

const _isLetter = (e) => {
  let char = String.fromCharCode(e.keyCode); // Get the character
  if (/^[a-zA-Z0-9ğüşöçİĞÜŞÖÇı]+$/.test(char)) return true;
  // Match with regex
  else e.preventDefault(); // If not match, don't add to input text
};

const _validateRegistryNumber = (numberString) => {
  while (numberString.length < 8) {
    numberString = "0" + numberString;
  }
  return numberString;
};

const _handleServiceError = (err) => {
  if (err.status == 401) {
    window.showInfo(
      this.$t("description.authorizationError"),
      "standart",
      "info"
    );
    this.logout();
  } else if (err.status == 500) {
    window.showInfo(this.$t("description.serverError"), "standart", "info");
  } else {
    window.showError(err);
  }
};

window.controlKey = Vue.prototype.controlKey = _controlKey;
window.controlKeyChar = Vue.prototype.controlKeyChar = _controlKeyChar;
window.controlIntChar = Vue.prototype.controlIntChar = _controlIntChar;
window.convertToLocal = Vue.prototype.convertToLocal = _convertToLocal;
window.convertToLocalTime = Vue.prototype.convertToLocalTime = _convertToLocalTime;
window.getCategoriName = Vue.prototype.getCategoriName = _getCategoriName;
window.getCategoriNameWithCulture = Vue.prototype.getCategoriNameWithCulture = _getCategoriNameWithCulture;
window.setGroupBodyArray = Vue.prototype.setGroupBodyArray = _setGroupBodyArray;
window.setCategoryTreeviewData = Vue.prototype.setCategoryTreeviewData = _setCategoryTreeviewData;
window.convertToDatepicker = Vue.prototype.convertToDatepicker = _convertToDatepicker;
window.setFilterData = Vue.prototype.setFilterData = _setFilterData;
window.getPageRulesById = Vue.prototype.getPageRulesById = _getPageRulesById;
window.getPageRulesByName = Vue.prototype.getPageRulesByName = _getPageRulesByName;
window.validateRegistryNumber = Vue.prototype.validateRegistryNumber = _validateRegistryNumber;
window.handleServiceError = Vue.prototype.handleServiceError = _handleServiceError;
window.strippedContent = Vue.prototype.strippedContent = _strippedContent;
window.isLetter = Vue.prototype.isLetter = _isLetter;
