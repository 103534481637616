import axios from '../javascript/custom-axios';

export default {
  /**
   * Get equipment data.
   * @param {object} params
   * @returns {Promise<any>} - A Promise that resolves with the response data.
   */
  getEquipmentTypes(params) {
    return axios.get(`equipment-type/get`, {
      params
    });
  },
};
